import http from '@/utils/httpV2'
import get from 'lodash/get'

export async function getClimaxSongList() {
  const res = await http.get('/stb/v2/chorus')
  return {
    data: get(res, 'data.data_list', [])
  }
}

export async function getSongToken() {
  // if (!songid) return {};
  const result = await http.get('')
  return get(result, 'data', {})
}