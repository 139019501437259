<template>
  <div class="side-order-list" v-show="pageViewName === 'orderTabPage'">
    <div class="header">
      <div class="tab">
        <div
          class="tab-item"
          v-for="(tab, index) in tabList"
          :key="index"
          :class="{ actived: curTab.name === tab.name }"
          @click="handleChangeTab(tab)"
        >
          {{ tab.text }}
        </div>
      </div>
    </div>
    <div class="close-side" @click="$emit('close')">
      <img src="https://qncweb.ktvsky.com/20231215/vadd/5965d997c5fb54308e509e10203cde56.png" alt="">
    </div>
    <OrderSongList v-show="curTab.name === 'ordered'" @singer-click="handleClickSinger" />
    <AlreadySongList v-show="curTab.name === 'already'" @singer-click="handleClickSinger" />
  </div>
  <SingerDetail v-if="pageViewName === 'singerDetail'" :singerData="singerData" @close="$emit('close')" @back="handlechangePageViewName" />
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineEmits, computed } from 'vue'
import { useStore } from 'vuex'
import AlreadySongList from '@/components/song-list/already/index.vue'
import OrderSongList from '@/components/song-list/order/index.vue'
import SingerDetail from './../singer-detail/index.vue'
import { sendLog } from '@/directives/v-log/log'

const emit = defineEmits(['close'])

const store = useStore()
const orderedListNum = computed(() => store.state.orderedList.length)
const alreadyListNum = computed(() => store.state.alreadyList.length)
const tabList = computed(() => {
  return [{
    name: 'ordered',
    text: `已点(${orderedListNum.value > 99 ? '99+' : orderedListNum.value})`,
  }, {
    name: 'already',
    text: `已唱(${alreadyListNum.value > 99 ? '99+' : alreadyListNum.value})`,
  }]
})
let curTab = ref(tabList.value[0])

let pageViewName = ref('orderTabPage')
let singerData = ref({
  singerid: '',
  name: '',
  image: '',
}) // 侧边栏 - 歌手详情 - 歌手数据

const handleChangeTab = (tab) => {
  curTab.value = tab
  if (tab.text === '已唱') {
    sendLog({
      event_type: '10000~50000',
      event_name: 10061,
      event_data: {
        str1: '已点',
        str2: '已唱',
        str3: '进入已唱',
        str4: 'click',
      },
    })
  }
}

const handleClickSinger = ({singer, singerhead, singerid}, type) => {
  // if (type === 'search') {
  //   sendLog({
  //     event_type: '10000~50000',
  //     event_name: 10103,
  //     event_data: {
  //       str1: '快速点歌',
  //       str2: '搜索结果',
  //       str3: '点击任意歌手',
  //       str4: 'click',
  //     },
  //   })
  // }
  singerData.value = {
    singerid,
    name: singer,
    image: singerhead,
  }
  pageViewName.value = 'singerDetail'
}

const handlechangePageViewName = () => {
  singerData.value = {
    singerid: '',
    name: '',
    image: '',
  }
  pageViewName.value = 'orderTabPage'
}

</script>
<style lang="stylus" scoped>
.side-order-list
  width 100%
  height 100vh
  padding 0
  position relative
  .empty
    margin-top 375px !important
    padding-top 0 !important
  ::v-deep .song-list
    height calc(100vh - 180px)
  .close-side
    position absolute
    top 53px
    right 48px
    width 32px
    height 32px
    img
      width 100%
      height 100%
  .header
    width 100%
    height 100px
    display flex
    justify-content space-between
    align-items center
    border-bottom 2px solid rgba(255,255,255,0.1)
    margin-top 22px
    margin-bottom 40px
    .tab
      width 100%
      height 100px
      display flex
      align-items center
      justify-content center
      &-item
        display flex
        justify-content center
        align-items center
        width 173px
        height 100px
        font-size 32px
        color rgba(255, 255, 255, 0.40)
        &.actived
          color #dbae6a
          border-bottom 2px solid #DBAE6A
        &:nth-child(1)
          margin-right 136px
  @media screen and (max-width 1200px) and (min-height 1200px)
    .header
      height 92px
      margin-top 20px
      margin-bottom 12px
      .tab
        height 92px
        &-item
          height 92px
          font-size 26px
          // &:nth-child(1)
          //   margin-right 340px
    .close-side
      top 48px
      right 48px
    ::v-deep .song-list
      height 948px
      padding 0 60px
</style>