<template>
  <!-- v-if="isShowBottomBar" -->
  <div class="bottom-bar"
    v-if="$route.name != 'home' && $route.name != 'album' && $route.name != 'search' && $route.name != 'singer' && $route.name != 'songList' && $route.name != 'mine' && $route.name != 'profile'"
    @click="handleClickDefaultBottombar"
    v-log="{
      event_type: 'show',
      event_name: 30019,
      event_data: {
        str1: '通用',
        str2: '歌词条',
        str3: '展示歌词条',
        str4: 'show',
      }
    }"
  >

    <div class="bottom-bar-btn" v-show="paused">
      <img
        class="bottom-bar-img margin_26"
        @click.stop="handleVideoPlay"
        src="https://qncweb.ktvsky.com/20231116/vadd/c419d053ec7e92cc17359bc0107fe4c7.png"
        alt=""
      />
    </div>
    <div class="bottom-bar-btn" v-show="!paused">
      <img
        class="bottom-bar-img"
        @click.stop="handleVideoPause"
        src="https://qncweb.ktvsky.com/20231116/vadd/c6d3fe6a17c085b38a8d567b735eab86.png"
        alt=""
      />
    </div>
    <div class="bottom-bar-btn">
      <img
        class="bottom-bar-img"
        src="https://qncweb.ktvsky.com/20231116/vadd/39821e842d8c522df9aaa425dc372240.png"
        @click.stop="handleVideoNext"
        alt=""
        />
    </div>
    <div
      class="bottom-bar-lyc theme-color"
    >
      <div class="bottom-bar-lyc-item">
        <template v-if="!lycListNum || currIrcIndex === -1">
          <p
            v-if="!isAlreadyEnterMv && paused && orderedListNum > 0"
            class="bottom-bar-lyc-item-p"
            style="color: #FFFFFFCC"
          >已点{{orderedListNum}}首歌，点我开始演唱</p>
          <p v-else-if="orderedListNum>0" class="bottom-bar-lyc-item-p">{{lycName}}</p>
          <p v-else class="bottom-bar-lyc-item-p" style="color: #FFFFFFCC">还没有要演唱的歌曲哦！快去点歌吧！</p>
        </template>
        <p
          v-else
          class="bottom-bar-lyc-item-p animate-fade-in"
        >
          <span
            v-for="(text, ind) in lycTxt.irc"
            :key="ind + lycTxt.t"
            :class="{
              'active-scan': true,
              'active-pause': paused,
            }"
            :style="{
              'animation-duration': `${lycTxt.textT[ind]}ms`,
              'animation-delay': `${lycTxt.delayTime[ind]}ms`,
            }"
          >
            {{ text.trim() }}
            {{ text.includes(' ') ? '&nbsp;' : '' }}
          </span>
        </p>
      </div>
      <div
        class="already-order"
        @click.stop="handleOpenOrderControl"
      >
        <span v-if="orderedListNum < 100">{{ orderedListNum }}</span>
        <span class="already-order-span" v-else>99+</span>
        <img
          src="https://qncweb.ktvsky.com/20231205/vadd/47998cdc7df85157af5fbe6b74b0728c.png"
        />
        <p>已点</p>
      </div>
    </div>
    <!-- <div class="bottom-bar-bg">
      <div class="bottom-bar-bg-filter"></div>
    </div> -->
  </div>
  <OperationSus :class="botPos" v-if="isShowOperationSus" @close="handleCloseOperationSus" />
</template>

<script>
import { computed, ref, onMounted, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import eventBus from '@/utils/event-bus'
// import useLogin from '@/composables/useLoginValid'
import useSongItem from '@/composables/useSongItem'
import OperationSus from '@/components/operation'
import Toast from '@/utils/toast'
import getComponentLrcData from '@/components/mv/utils'
import { sendLog } from '@/directives/v-log/log'
import useVip from '@/composables/useVip'
let isPlaying = false
export default {
  name: 'BottomBar',
  components: {
    OperationSus,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    // const { showLoginQrcode, isLogin } = useLogin()
    const { orderSong } = useSongItem()
    const mvIsHide = computed(() => store.state.mvIsHide)

    // 未进入过欢唱页，不显示歌词条
    const isAlreadyEnterMv = computed(() => store.state.videoInitAutoPlay)

    const paused = computed(() => store.state.videoPaused)
    const videoPlayerHistory = computed(() => store.state.videoPlayerHistory)
    const orderedList = computed(() => store.state.orderedList)
    const orderedListNum = computed(() => orderedList.value.length)
    const orderedSongIdMap = computed(() => store.state.orderedSongIdMap)
    const isShowBottomBar = computed(() => !!(mvIsHide.value && orderedListNum.value))
    const isShowOperationSus = ref(true)
    const freeVipNumber =computed(() => Number(store.state.freeVipNumber))
    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const botPos = computed(() => {
      if (isShowBottomBar.value) {
        if (route.name === 'search') return 'bot-pos-search'
        if (route.name === 'singer') return 'bot-pos-singer'
        return 'bot-pos'
      }
      return ''
    })

    const lycList = computed(() => {
      return getComponentLrcData(store.state.videoPlayerHistory.songItemLrc)
    })
    const lycListNum = computed(() => lycList.value.length)
    const currIrcIndex = computed(() => store.state.currIrcIndex)
    const lycTxt = computed(() => {
      if (lycListNum.value) {
        if (currIrcIndex.value) {
          return lycList.value[currIrcIndex.value]
        }
        return lycList.value[0]
      }
      return []
    })

    let lycName = computed(() => {
      if (orderedListNum.value) {
        if (currIrcIndex.value === -1 || !lycListNum.value) {
          return `${orderedList.value[0].music_name} - ${orderedList.value[0].singer}`
        }
      }
      return ''
    })

    const root = ref(null)
    const { showVipQrcode } = useVip()
    // 切歌校验
    const checkOrderedHasSong = () => {
      // if (!isLogin.value) {
      //   showLoginQrcode()
      //   return false
      // }
      // 切歌时当前未播放歌曲的情况
      if (!videoPlayerHistory.value.songItem.songid) {
        if (
          Object.keys(orderedSongIdMap.value).length
        ) {
          orderSong(orderedList.value[0], {
            position: 'recovery',
            isPushOrdered: false,
          })
          return false
        }
        if (route.name != 'climax') {
          Toast('请点播歌曲')
        }
        return false
      }
      return true
    }

    const checkMvIsPlaying = () => {
      // if (!isLogin.value) {
      //   showLoginQrcode()
      //   return
      // }
      if (!videoPlayerHistory.value.songItem.songid) {
        if (
          Object.keys(orderedSongIdMap.value).length
        ) {
          orderSong(orderedList.value[0], {
            position: 'recovery',
            isPushOrdered: false,
          })
          return
        }
        if (route.name != 'climax') {
          Toast('请点播歌曲')
        }
        return
      }
    }

    const handleVideoPlay = () => {
      checkMvIsPlaying()
      eventBus.emit('handle-video-play')
      store.commit('UPDATE_MV_INIT_AUTOPLAY', 1);
      store.commit('UPDATE_IS_SING_STATUS', true)
      sendLog({
        event_type: '10000~50000',
        event_name: 30020,
        event_data: {
          str1: '通用',
          str2: '歌词条',
          str3: '播放',
          str4: 'click',
        },
      })
    }

    const handleVideoPause = () => {
      checkMvIsPlaying()
      eventBus.emit('handle-video-pause')
      sendLog({
        event_type: '10000~50000',
        event_name: 30020,
        event_data: {
          str1: '通用',
          str2: '歌词条',
          str3: '暂停',
          str4: 'click',
        },
      })
    }

    const handleVideoNext = () => {
      if (!checkOrderedHasSong()) return
      eventBus.emit('handle-video-next')
      store.commit('UPDATE_MV_INIT_AUTOPLAY', 1);
      store.commit('UPDATE_IS_SING_STATUS', true)
      sendLog({
        event_type: '10000~50000',
        event_name: 30021,
        event_data: {
          str1: '通用',
          str2: '歌词条',
          str3: '切歌',
          str4: 'click',
        },
      })
    }

    const handleCloseOperationSus = () => {
      isShowOperationSus.value = false
    }

    const handleClickDefaultBottombar = () => {
      if(freeVipNumber.value && orderedListNum.value){
          orderSong(orderedList.value[0], {
            position: 'recovery',
            isPushOrdered: false,
            enabledMvShow: true,
            useFirstSongAutoPlay: true,
          })
          store.commit('UPDATE_IS_SING_STATUS', true)
          store.dispatch('searchTips/updateIsShowSingTips', false) // 关闭tips弹窗
          return;
      }
      if(!orderedListNum.value && !isVip.value) {
        showVipQrcode({})
        return;
      }
      if(!orderedListNum.value) return;
      if (
        Object.keys(orderedSongIdMap.value).length &&
        !isAlreadyEnterMv.value
      ) {
        orderSong(orderedList.value[0], {
          position: 'recovery',
          isPushOrdered: false,
          enabledMvShow: true,
          useFirstSongAutoPlay: true,
        })
        store.commit('UPDATE_IS_SING_STATUS', true)
        store.dispatch('searchTips/updateIsShowSingTips', false) // 关闭tips弹窗
        return
      }
      orderSong(videoPlayerHistory.value.songItem, {
        position: 'recovery',
        isPushOrdered: false,
        enabledMvShow: true,
        useFirstSongAutoPlay: true,
      })
      store.commit('UPDATE_IS_SING_STATUS', true)
      store.dispatch('searchTips/updateIsShowSingTips', false) // 关闭tips弹窗
      sendLog({
        event_type: '10000~50000',
        event_name: 30205,
        event_data: {
          str1: '通用',
          str2: '歌词条',
          str3: '点击交互区进入欢唱',
          str4: 'click',
        },
      })
    }
    watch(isShowBottomBar, (val) => {
      console.log(val)
      if (val) {
        sendLog({
          event_type: '10000~50000',
          event_name: 10095,
          event_data: {
            str1: '任意页',
            str2: '歌词条',
            str3: '展示',
            str4: 'show',
          },
        })
      }
    })

    watch(route, (newVal) => {
      if (newVal.name === 'climax') {
        isPlaying = !paused.value
        handleVideoPause()
      } else if (isPlaying) {
        handleVideoPlay()
        isPlaying = false
      }
    }, {
      immediate: true,
      deep: true,
    })
    const handleClickStartSing =()=>{
      handleClickDefaultBottombar()
    }

    const handleOpenOrderControl = () => {
      eventBus.emit('show-order-song-control-popup')
      sendLog({
        event_type: '10000~50000',
        event_name: 10005,
        event_data: {
          str1: '首页',
          str2: '已点',
          str3: '打开已点',
          str4: 'click',
        },
      })
    }

    onMounted(()=>{
      eventBus.on('handle-click-start-sing',handleClickStartSing)
    })
    const isShowMini = computed(()=> store.state.mvIsHide && store.state.orderedList.length == 0);
    return {
      root,
      isShowBottomBar,
      botPos,
      paused,
      lycTxt,
      lycName,
      lycListNum,
      currIrcIndex,
      handleVideoPlay,
      handleVideoPause,
      handleVideoNext,
      isShowOperationSus,
      handleCloseOperationSus,
      isAlreadyEnterMv,
      orderedListNum,
      handleClickDefaultBottombar,
      mvIsHide,
      route,
      handleOpenOrderControl,
      isShowMini
    }
  }
}
</script>

<style lang="stylus" scoped>
.bottom-bar
  width 100%
  height 110px
  display flex
  flex-direction row
  align-items center
  padding 0 286px
  position fixed
  bottom 0
  left 0
  z-index 5
  background: #20242b
  @media screen and (max-width 1200px)
    padding-right 230px
  &-btn
    width 110px
    height 110px
    display flex
    align-items center
    justify-content center
    position absolute
    bottom 0
    left 42px
    &:nth-child(3)
      left 176px
  &-img
    width 44px
    height 44px
  &-lyc
    flex 1
    height 100%
    display flex
    align-items center
    justify-content center
    font-size 40px
    opacity 0.8
    color rgba(255, 255, 255, 0.20)
    @media screen and (max-width 1200px)
      font-size 32px
    .active-scan
      background rgba(255, 255, 255, 0.4) -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)) no-repeat 0 0
      -webkit-text-fill-color transparent
      -webkit-background-clip text
      background-size 0 100%
      animation scan 2s linear
      animation-fill-mode forwards
    .active-load
      background-size 100% 100%
    .active-pause
      animation-play-state paused !important
    .animate-fade-in
      animation fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both
    p
      font-weight 400
    &-item
      width 100%
      height 100%
      display flex
      align-items center
      justify-content center
      &-p
        max-width 1348px
        height 100%
        line-height 110px
        display flex
        align-items center
        flex-wrap wrap
        @media screen and (max-width 1200px)
          max-width 850px
          font-size 32px
      span
        font-weight 400
        height 42px
        line-height 42px

  .margin_26
    margin 0 26px
  .margin_left_20
    margin-left 20px
  @keyframes scan
    0%
      background-size 0 100%
    100%
      background-size 100% 100%
  @keyframes fade-in
    0%
      opacity 0.2
    100%
      opacity 1

  .already-order
    width 228px
    height 90px
    border-radius 4px
    background #252b32
    margin-right 0px
    display flex
    align-items center
    justify-content center
    position absolute
    top 10px !important
    right 10px !important
    background rgba(255, 255, 255, 0.08)!important
    border-radius 10px!important
    z-index 10

    span
      position absolute
      top 10px
      right 32px
      display flex
      justify-content center
      align-items center
      width 32px
      height 32px
      background #E3AB5D
      border-radius 50%
      color #000
      font-size 20px
      font-weight 400
      z-index 1
    &-span
      width 54px !important
      border-radius 18px !important
    img
      width 40px
      height 40px
    p
      width auto
      height 92px
      line-height 92px
      opacity 0.8
      font-weight 400
      font-size 28px
      color #FFFFFF
      margin-left 12px
    @media screen and (max-width 1200px)
      margin 0 20px 0 20px
      p
        font-size 22px
      span
        width 28px
        height 28px
        font-size 18px
        top 7px
        right 10px
      img
        width 30px
        height 30px

.bot-pos
  bottom 118px !important
.bot-pos-search
  bottom 140px !important
.bot-pos-singer
  bottom 110px !important

</style>
