// 智能推荐 - top100
import { getRecommendTop100List } from "@/service/recommend";

const state = {
  recommendTop100: [],
  // chatList: [],
};

const getters = {};

const actions = {
  async initTop100List({ commit }) {
    let res = [];
    const list = await getRecommendTop100List();
    if (list.length) res = list;
    commit("SET_TOP100LIST", res);
  },
  // resetRecommendList({ commit }) {
  //   commit('RESET_RECOMMENDLIST')
  // },
};

const mutations = {
  SET_TOP100LIST(state, data) {
    state.recommendTop100 = data;
  },
  // RESET_RECOMMENDLIST(state) {
  //   state.recommendList = []
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
