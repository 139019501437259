<template>
  <div class="fast-search" :class="[isSearch && 'pos-ab']">
    <div class="fast-search-main" :class="[isSearch && 'width_480']" @click="handleToSearch">
      <img class="fast-search-icon" src="https://qncweb.ktvsky.com/20231215/vadd/5089ecfd61ea530e6ac3ab8546619c8b.png"/>
      <!-- 、歌手 -->
      <input
        type="text"
        placeholder="搜索歌曲、歌手"
        v-model="keyword"
        class="fast-search-input"
        :disabled="!isSearch"
        ref="searchInput"
        @keydown="handleSearchKeydown($event)"
      >
      <div v-show="!isSearch" class="fast-search-mask"></div>
    </div>
    <div v-if="isSearch" class="fast-search-btn" @click="handleSearch">
      搜索
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from 'vue'
// import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'SearchBar',
  props: {
    isSearch: Boolean,
  },
  setup(props, { emit }) {
    let keyword = ref('')
    const searchInput = ref(null)
    const { isSearch } = toRefs(props)

    const handleToSearch = () => {
      if (isSearch.value) return

      emit('go-search')
      setTimeout(() => {
        searchInput.value.focus()
      }, 0)
    }

    const handleSearch = () => {
      if (!keyword.value || !keyword.value.trim()) return
      emit('search', keyword.value)
    }

    const handleSearchKeydown = (e) => {
      if (e.keyCode == 13) {
        handleSearch()
      }
    }

    const changeInput = (e) => {
      keyword.value = e
      handleSearch()
    }
    
    return {
      keyword,
      searchInput,
      handleToSearch,
      handleSearch,
      handleSearchKeydown,
      changeInput,
    }
  }
}
</script>

<style lang="stylus" scoped>
  .fast-search
    display flex
    align-items center
    justify-content space-between
    width 704px
    margin-left 48px
    &-main
      display flex
      align-items center
      position relative
      width 704px
      height 88px
      border 2px solid rgba(255, 255, 255, 0.20)
      border-radius 4px
    &-icon
      width 36px
      height 36px
      margin 0 28px
    &-input
      width 482px
      height 40px
      display flex
      align-items center
      font-size 28px
      color rgba(255, 255, 255, 0.6)
    &-mask
      position absolute
      top 0
      right 0
      left 0
      bottom 0
      width 100%
      height 100%
    &-btn
      width 200px
      height 88px
      line-height 88px
      border-radius 4px
      backdrop-filter blur(100px)
      background: #E3AB5D;
      color: rgba(0, 0, 0, 0.80);
      text-align center
      font-size 28px
      font-weight 400
      margin-left 20px
    .width_480
      width 480px
    @media screen and (max-width 1200px)
      margin-left 0
      justify-content center
      &-main
        width 482px
      &-icon
        width 26px
        height auto
      &-input, &-btn
        font-size: 22px;
      .width_480
        width 482px
@media screen and (max-width 1200px)
  .pos-ab
    position absolute
    top 22px
    left 277px
</style>