import { sendLog } from "@/directives/v-log/log";
import Toast from '@/utils/toast'
import get from "lodash/get";

// 错误信息上报
export function reportError(error) {
  const errorJson = error.toJSON();
  const status = get(error, 'response.data.errcode', errorJson.status) || 'unknown';
  const msg = get(error, 'response.data.errmsg', error.message) || 'unknown';
  const url = error.config.baseURL + error.config.url;
  const route_page = location.href.split('?')[0] || 'unknown';
  let route_name = '';
  try {
    route_name = location.href.match(/#\/(\w*)/)[1] || 'home';
  } catch (error) {
    route_name = 'unknown'
  }
  sendLog({
    event_type: "custom",
    event_name: 271,
    event_data: {
      route_page,
      route_name,
      msg,
      status,
      url,
    },
  });
}

export function showErrorToast(msg = "网络异常, 请稍后重试") {
  Toast(msg);
}
