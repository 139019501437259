<template>
  <div class="header">
    <div class="header-back">
      <img
        @click="handleBack"
        src="https://qncweb.ktvsky.com/20231207/vadd/72ff0b114ee2cb3153ce901af19bc813.png"
      />
    </div>
    <span>
      {{ title }}
    </span>

    <div
      v-if="isShowTimesInfo"
      class="times-info"
      @click="handleClickTimes"
      >
        <template v-if="!isLogin"><span class="light">登录</span>即可享千元特权，好礼送不停！</template>
        <template v-else-if="isVip">尊敬的VIP用户，拉满状态开启您狂欢时刻！</template>
        <template v-else-if="isExpire">权益重磅升级，邀您回归！<span class="light">续费低至¥0.2/天</span></template>
        <template v-else>
          <p v-if="showFreeOrderInfo" class="free-order">
            VIP歌曲免费点唱次数: <span class="free-order-zero">{{remainingFreeOrders}}/3</span>
          </p>
          <p v-if="isShowClimax" class="climax-free">
            快唱高潮限免时长
            <CountDown
              class="climax-free-countdown"
              ref="userTime"
              :time="preferentialTime"
              @finish="handleTimeFinish"
              :auto-start="false"
            >
              <template #default="timeData">
                <span class="block">00</span>
                <span class="colon">:</span>
                <span class="block">{{ formattedMinutes(timeData.minutes) }}</span>
                <span class="colon">:</span>
                <span class="block">{{ formattedSeconds(timeData.seconds) }}</span>
              </template>
            </CountDown>
          </p>
        </template>
    </div>

    <div class="head-bar-right" v-if="isShowRight">
      <div
        class="head-bar-right-item"
        @click="handleGoSinging"
      >
        <img
          src="https://qncweb.ktvsky.com/20240913/vadd/8c1f67c944e27d70570a4b6827a3e5ae.png"
        />
        <p>K歌记录</p>
      </div>

      <div
        class="head-bar-right-item"
        @click="handleSetting"
      >
        <img
          src="https://qncweb.ktvsky.com/20240913/vadd/6c76aaccbf5210c8737fb6c32f52cbfe.png"
        />
        <p>设置</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { CountDown } from 'vant'
import useVip from '@/composables/useVip'
import useLoginValid from '@/composables/useLoginValid'
import { ref, computed } from 'vue'  // 移除未使用的 defineProps
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'HeadBar',
  components: { CountDown },
  props: {
    title: {
      type: String,
      default: '',
    },
    isShowTimesInfo: {
      type: Boolean,
      default: false,
    },
    isShowRight: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const store = useStore()
    const { showVipQrcode } = useVip()
    const { showLoginQrcode, isLogin } = useLoginValid()

    const handleBack = () => {
      store.dispatch('getCarplayInfo') // 返回上一页时更新下用户状态
      router.back()
      emit('back')
    }

    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const vipInfo = computed(() => store.state.vipInfo)
    const freeVipNumber =computed(() => Number(store.state.freeVipNumber))
    const isExpire = computed(() => vipInfo.value.expire);
    const isClimaxFree = computed(() => !store.state.climax.freeSing)
    const isShowClimax = computed(() => !isVip.value && isClimaxFree.value)
    // const isLogin = computed(() => !!store.state.userInfo.unionid)

    const showFreeOrderInfo = computed(() => !isExpire.value && !isShowClimax.value && freeVipNumber.value < 3)
    const remainingFreeOrders = computed(() => 3 - freeVipNumber.value)

    const preferentialTime = ref(30 * 60 * 1000)
    const userTime = ref(null)

    const handleTimeFinish = ()=>{
      // store.dispatch('climax/updateFreeSing', true)
    }

    const handleClickTimes = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 30256,
        event_data: {
          str1: '首页',
          str2: '右上角喇叭通知',
          str3: '点击',
          str4: 'click',
        },
      })
      if (!isVip.value) {
        if (isLogin.value) {
          showVipQrcode({  // 这里可能需要定义 showVipQrcode 函数
            isLogin: isLogin.value,
            fr: 1879,
            log: '首页右上角小喇叭运营位',
          })
        } else {
          showLoginQrcode({
            log: '首页右上角小喇叭运营位'
          });
        }
      }
    }

    const formattedMinutes = (minutes) => {
      return minutes < 10 ? `0${minutes}` : minutes;
    };

    const formattedSeconds = (seconds) => {
      return seconds < 10 ? `0${seconds}` : seconds;
    };

    const handleGoSinging = () => {
      store.dispatch('vipAddSong/resetSong')
      if (!isLogin.value) {
        sendLog({
          event_type: '10000~50000',
          event_name: 30182,
          event_data: {
            str1: '首页',
            str2: '唱过的歌',
            str3: '未登录二维码展示',
            str4: 'show',
          },
        })
        showLoginQrcode({
          log: '未登录-唱过的歌',
          from: 'singing'
        })
        return
      }
      router.push({
        name: 'singing'
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 30176,
        event_data: {
          str1: '唱过的歌页',
          str2: '唱过的歌页',
          str3: '进入唱过的歌页',
          str4: 'show',
        },
      })
    }

    const handleSetting = () => {
      router.push({
        name: 'setting',
      })
    }

    return {
      handleBack,
      handleClickTimes,
      isLogin,
      isVip,
      isExpire,
      isShowClimax,
      showFreeOrderInfo,
      remainingFreeOrders,
      preferentialTime,
      userTime,
      handleTimeFinish,
      formattedMinutes,
      formattedSeconds,
      handleGoSinging,
      handleSetting,
    }
  },
}
</script>

<style lang="stylus" scoped>
$white-color = #FFFFFF
$white-opacity-20 = rgba(255, 255, 255, 0.2)
$white-opacity-15 = rgba(255, 255, 255, 0.15)
$white-opacity-30 = rgba(255, 255, 255, 0.3)
$white-opacity-80 = rgba(255, 255, 255, 0.8)
$border-radius-24 = 24px
$border-radius-16 = 16px
$font-size-24 = 24px
$font-size-30 = 30px

.header
  width 100vw
  height 164px
  padding 0 20px
  background rgba(0, 0, 0, 1)
  display flex
  align-items center
  position fixed
  top 0
  left 0
  z-index 6
  &-back
    width fit-content
    height 164px
    display flex
    align-items center
    justify-content center
    img
      width 40px
      height 40px
      margin 0 60px
  span
    color rgba(255, 255, 255, 1)
    font-size 32px
    font-weight 300
  @media screen and (max-width 1200px)
    height 104px
    padding 0 58px
    &-back
      height 104px
      img
        width 32px
        height 32px
        margin 0 48px 0 0
    span
      font-size 26px
  .times-info
    display flex
    align-items center
    background-color rgba(0, 0, 0, 0.2)
    height 76px
    background-image url('https://qncweb.ktvsky.com/20240710/other/b8108ad12e7dcc159db670aa63f8daa6.png')
    background-size 40px auto
    background-repeat no-repeat
    background-position 34px center
    border-radius $border-radius-24
    color $white-opacity-80
    font-size 20px
    padding-left 84px
    padding-right 34px
    margin-left 32px

    @media screen and (max-width 1200px) and (min-height 1200px)
      height 60px
      margin-left 20px
      font-size 18px
      padding-left 58px
      padding-right 20px
      background-size 28px auto
      background-position 20px center
      border-radius $border-radius-16

    .light
      color #E5B875
      font-size 20px

    p
      color $white-opacity-80

    .free-order
      position relative
      padding-right 20px
      margin-right 20px
      &:after
        content ''
        position absolute
        top 50%
        margin-top -8px
        right 0
        width 2px
        height 20px
        background rgba(255, 255, 255, 0.2)
    .climax-free
      display flex

      &-countdown
        color rgba(0, 0, 0, 0.2)
        margin-left 10px
        .block
          display inline-block
          background rgba(0, 0, 0, 0.2)
          width 28px
          height 28px
          line-height 28px
          text-align center
          font-size 18px
          border-radius 4px
          color $white-opacity-80
        .colon
          margin 0 4px
  .head-bar-right
    flex 1
    position absolute
    right 70px
    top 42px
    display flex
    align-items center
    .head-bar-right-item
      // max-width 250px
      width auto
      height 92px
      padding 0 40px
      border-radius 4px
      border 2px solid rgba(255, 255, 255, 0.1)
      // border 2px solid rgba(255, 255, 255, 0.20)
      // backdrop-filter blur(100px)
      // background-color #FFFFFF1A
      // margin-right 32px
      display flex
      align-items center
      justify-content center
      font-size 28px
      color rgba(255, 255, 255, 0.6)
      img
        width 40px
        height 40px
        margin-right 20px
      &:nth-child(2)
        margin-left 24px
  @media screen and (max-width 1200px) and (min-height 1200px)
    .head-bar-right
      right 60px
      top 20px
      .head-bar-right-item
        height 60px
        padding 0 33px
        font-size 20px
        img
          width 32px
          height 32px
          margin-right 15px
</style>

