function getClientWidth() {
  return document.body ? document.body.clientWidth : 0
}

function getClientHeight() {
  const h1 = document.documentElement ? document.documentElement.clientHeight : 0
  const h2 = document.body ? document.body.clientHeight : 0
  if (h1 > 0 && h1 < h2) return h1
  return h2
}

function checkLandscapeOrPortrait() {
  const w = getClientWidth()
  const h = getClientHeight()
  // console.log(w,h)
  if (h < w) {
    return 'landscape'
  }
  return 'portrait'
}

export {
  getClientWidth,
  getClientHeight,
  checkLandscapeOrPortrait
}