// import { useStore } from 'vuex'

export default function useGlobalEvent() {

  // const store = useStore()

  // const controlFromType = computed(() => store.state.controlFromType)

  const globalClickHandler = () => {
    // TODO 这个设计不好 先放着
    // store.commit('UPDATE_CONTROL_FROM_TYPE', 1)
  }

  const installGlobalClickHandler = () => {
    window.addEventListener('click', globalClickHandler, true)
  }
  const uninstallGlobalClickHandler = () => {
    window.removeEventListener('click', globalClickHandler, true)
  }

  return {
    installGlobalClickHandler,
    uninstallGlobalClickHandler,
  }
}