<template>
  <div
    class="mv-mini"
    @click.stop="handleClickMini"
    ref="mvMiniRef"
  >
    <div class="default">
      <button @click.stop="handleClickDefaultButton">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.9" clip-path="url(#clip0_265_8443)">
          <circle cx="20.5777" cy="11.3922" r="7.2223" stroke="#84F8FF" style="stroke:#84F8FF;stroke:color(display-p3 0.5191 0.9711 1.0000);stroke-opacity:1;" stroke-width="2"/>
          <path d="M13.7258 13.562L5.48334 24.0776C4.84 24.8984 4.91075 26.0703 5.64816 26.8077V26.8077C6.38556 27.5451 7.55746 27.6158 8.37822 26.9725L18.8938 18.73" stroke="#84F8FF" style="stroke:#84F8FF;stroke:color(display-p3 0.5191 0.9711 1.0000);stroke-opacity:1;" stroke-width="2"/>
          </g>
          <defs>
          <clipPath id="clip0_265_8443">
          <rect width="32" height="32" fill="white" style="fill:white;fill-opacity:1;"/>
          </clipPath>
          </defs>
        </svg>
        就唱这首
      </button>

      <div
        class="default-drawer"
        :class="{ show: showDrawer }"
        @click.stop="toggleDrawer"
      >
        <div
          class="default-drawer-content"
        >
          <Carousel
            :itemsToShow="itemsToShow"
            :wrapAround="true"
            :transition="300"
            @slide-end="onSlideEnd"
            ref="myCarousel"
            @click.stop
            :breakpoints="carouselBreakpoints"
          >
            <Slide v-for="(slide, index) in defaultList" :key="slide">
              <div class="carousel__item" @click="handleItemClick(index)">
                <img
                  :src="slide.cover"
                />
                <img v-if="slide.song.is_vip" class="song-block-vip" src="https://qncweb.ktvsky.com/20231206/vadd/56dc0bc6477bf2c7a6c4fcdc8360804e.png" alt="">
                <i v-else class="free-icon">免费</i>
              </div>
            </Slide>
          </Carousel>
        </div>


        <div v-if="!showDrawer" class="default-drawer-paination">
          <span
            v-for="(i, index) in defaultList"
            :key="i"
            :class="{
              active: index === activeIndex
            }"
          ></span>
        </div>
      </div>

      <div
        v-if="showMvMiniGuide && !showDrawer"
        class="default-guide"
        @click="handleClickGuide"
      >
        <img src="https://qncweb.ktvsky.com/20240710/other/c2e4820b1a75524f58aa37af3b4abbaa.png" alt="">
      </div>
      <video-player
        v-if="defaultSong.video_url"
        v-touch:swipe="handleSwipe"
        from="indexDefault"
        ref="videoPlayerRef"
        :autoplay="true"
        :startPosition="0"
        :src="defaultSong.video_url"
        :token="''"
        :isSizeInherit="roundToNearestMinutes"
        :poster="require('./poster.png')"
        @ended="playNext"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import store2 from 'store2'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import VideoPlayer from '@/components/video-player/mp4.vue';
import eventBus from '@/utils/event-bus'
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes/index.js';
import useSongItem from '@/composables/useSongItem'

// import useOrder from '@/composables/useOrder'
import { sendLog } from '@/directives/v-log/log'

const store = useStore();
const route = useRoute();
const { orderSong } = useSongItem()

const showMvMiniGuide = ref(!store2.get('showMvMiniGuide'))
const showDrawer = ref(true)
const myCarousel = ref(null)
const activeIndex = ref(0)
const videoPlayerRef = ref(null);

const isLogin = computed(() => !!store.state.userInfo.unionid)
const userType = computed(() => store.state.userInfo.userType)

const defaultSong = computed(() => store.state.miniMv.defaultSong);
const defaultList = computed(() => store.state.miniMv.defaultList);
const isDefault = computed(() => !store.state.orderedList.length);
const mvMiniRef = ref(null)
const itemsToShow = ref(4.2)
const carouselBreakpoints = {
  900: {
    itemsToShow: 4.2
  }
}

let resizeObserver = null

const handleResize = (entries) => {
  for (let entry of entries) {
    const { width } = entry.contentRect
    if (width <= 900) {
      itemsToShow.value = 3.2
    } else {
      itemsToShow.value = 4.2
    }
    if (myCarousel.value) {
      myCarousel.value.updateSlideWidth()
    }
  }
}

onMounted(() => {
  if (typeof ResizeObserver !== 'undefined') {
    resizeObserver = new ResizeObserver(handleResize)
    if (mvMiniRef.value) {
      resizeObserver.observe(mvMiniRef.value)
    }
  }
})

onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect()
  }
})

const handleClickGuide = () => {
  store2.set('showMvMiniGuide', true)
  showMvMiniGuide.value = false
}

const toggleDrawer = () => {

  console.log('toggleDrawer', showDrawer.value)
  sendLog({
    event_type: '10000~50000',
    event_name: 30243,
    event_data: {
      str1: '首页',
      str2: `${showDrawer.value ? '下拉-隐藏' : '展开-显示'}定制歌单`,
      str3: '点击',
      str4: 'click',
    },
  })

  showDrawer.value = !showDrawer.value
  
}

const handleItemClick = (index) => {
  const carousel = myCarousel.value.data;
  const currentSlide = carousel.currentSlide.value;
  const totalSlides = defaultList.value.length;

  if(index === 0 && currentSlide === totalSlides - 1) {
    carousel.next()
  } else if(index === totalSlides - 1 && currentSlide === 0) {
    carousel.prev()
  } else {
    myCarousel.value.slideTo(index);
  }

  activeIndex.value = index;
  store.commit('miniMv/SET_DEFAULT_SONG', defaultList.value[activeIndex.value]);
};

const playNext = async () => {
  activeIndex.value = (activeIndex.value + 1) % defaultList.value.length;
  handleItemClick(activeIndex.value)
};

const playPrev = async () => {
  activeIndex.value = (activeIndex.value - 1 + defaultList.value.length) % defaultList.value.length;
  handleItemClick(activeIndex.value)
};

const handleClickMini = () => {
  eventBus.emit('mp4-control-play')
  showDrawer.value = false;
  // toggleDrawer()
}

const onSlideEnd = ({currentSlideIndex}) => {
  console.log(currentSlideIndex)
  handleItemClick(currentSlideIndex)
}

const handleSwipe = (payload) => {
  console.log(payload)
  if(payload === 'left') {
    playNext()
  } else {
    playPrev()
  }
}

const handleOrder = () => {
  orderSong(defaultSong.value.song, {
    immediate: true,
    log: '就唱这首',
    fr: 1876
  })
}

const handleClickDefaultButton = async () => {

  sendLog({
    event_type: '10000~50000',
    event_name: 30242,
    event_data: {
      str1: '首页',
      str2: '就唱这首',
      str3: '点击',
      str4: 'click',
      str5: isLogin.value ? '已登录' : '未登录',
      str7: userType.value,
    },
  })
  
  await Promise.all([
    handleOrder()
  ])
}

watch(route, (val) => {
  if (videoPlayerRef.value) {
    if (val.name === 'climax') {
      videoPlayerRef.value.handleControlVideoPause()
    } else {
      videoPlayerRef.value.handleControlVideoPlay()
    }
  }
}, {
  immediate: true,
  deep: true
})

</script>

<style lang="stylus" scoped>
.mv-mini
  width 1000px
  height 553px
  box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.1);
  border-radius 32px
  margin-bottom 31px
  position relative

  @media screen and (max-width 1200px) and (min-height 1200px)
    width 680px
    height 376px
    margin-bottom 0px

  & > div
    width 100%
    height 100%
    position absolute
    top 0
    left 0

  .video-player
    width 100%
    height 100%

    #video-player, video
      width 100% !important
      height 100% !important

  .default
    border-radius 32px
    overflow hidden
    button
      width 202px
      height 72px
      background rgba(156, 174, 184, 0.6)
      backdrop-filter: blur(20px);
      color #3DFFFF
      position absolute
      right 12px
      top 12px
      display flex
      align-items center
      justify-content center
      border-radius 20px
      overflow hidden
      z-index 10
      font-size 28px

      @media screen and (max-width 1200px) and (min-height 1200px)
        transform scale(calc(137 / 202))
        transform-origin right

      svg
        margin-right 10px

    &-guide
      position absolute
      z-index 10
      left 0
      top 0
      width 100%
      height 100%
      background rgba(0, 0, 0, 0.8)
      display flex
      align-items center
      justify-content center
      z-index 9999
      img
        width 388px
        height auto

    &-drawer
      position absolute
      z-index 20
      bottom 0
      left 0
      width 100%
      height 185px
      background-color rgba(27, 27, 27, 0.66)
      transition transform 0.3s ease-in-out
      transform translateY(145px)

      @media screen and (max-width 1200px) and (min-height 1200px)
        height 128px
        transform translateY(88px)

      &-paination
        display flex
        justify-content center
        align-items center
        margin-top 16px
        height auto
        position absolute
        width 100%
        top 0
        left 0

        span
          width 8px
          height 8px
          border-radius 50%
          background #FFFFFF33
          margin 0 5px
          &.active
            background #FFFFFFE5
            width 32px
            border-radius 10px

      &.show
        transform translateY(0)
        border-top-left-radius 0px
        border-top-right-radius 0px
        &:after
          content ''
          position absolute
          top 9px
          left 50%
          margin-left -24px
          width 48px
          height 6px
          background #D9D9D9
          border-radius 10px

          @media screen and (max-width 1200px) and (min-height 1200px)
            width 33px
            height 4px
            margin-left -16px

        .carousel
          position relative
          margin-top 25px

          @media screen and (max-width 1200px) and (min-height 1200px)
            margin-top 20px

      &-content
        ::v-deep .carousel
          height 134px
          margin-top 200px

          @media screen and (max-width 1200px) and (min-height 1200px)
            height 90px !important

          &__viewport
            height 100%

          &__track
            height 100%

          &__item
            width 240px
            height 100%
            border-radius 20px
            overflow hidden
            transform scale(calc(154 / 240)) translateX(60px)
            position relative

            p
              width 100%
              height 40px
              line-height 40px
              background #00000033
              font-size 20px
              color #FFFFFF
              text-align center
              position absolute
              bottom 0
              left 0

            .free-icon
              width 48px
              height 24px
              display flex
              align-items center
              justify-content center
              background: linear-gradient(90deg, #4ADBEF -0.89%, #1CA8C7 99.11%);
              color #fff
              font-size 16px
              border-radius 12px
              position absolute
              right 5px
              top 5px
            .song-block-vip
              width 48px
              height 24px
              position absolute
              right 5px
              top 5px
              @media screen and (max-width 1200px) and (min-height 1200px)
                width 35px
                height auto

          &__slide--next ~ .carousel__slide
            .carousel__item
              transform scale(calc(154 / 240)) translateX(-60px)

          &__slide--prev, &__slide--next
            .carousel__item
              transform scale(calc(192 / 240))

          &__slide--active
            .carousel__item
              transform scale(1)
</style>
