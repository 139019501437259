import { computed } from 'vue'
// import { useStore } from 'vuex'
import store from '@/store'
import { sendLog } from '@/directives/v-log/log'
import { get } from 'lodash'

export default function useAIOrder() {
  // const store = useStore()

  const aiFaceSong = computed(() => store.state.aiFaceSong)

  // 已点列表中点歌
  const orderAISong = (songItem, index) => {
    console.log('已点列表中点歌', index)
    if (index > 0) {
      // const shiftSong = get(aiFaceSong, 'value.0', null)
      // if (shiftSong) {
      //   store.commit('PUSH_SONG_TO_ALREADY_LIST', shiftSong)
      // }
      // 去掉第一首
      store.commit('SHIFT_AND_OR_AI_ORDERED_LIST', index)
    }
  }

  // 置顶
  const stickSongToTop = (index) => {
    const _index = typeof index === 'undefined'
      ? aiFaceSong.value.length - 1
      : index
    store.commit('STICK_SONG_TO_TOP_AI_ORDERED_LIST', _index)
  }

  // 删除
  const deleteSong = (index) => {
    store.commit('DELETE_AI_ORDER_LIST', index)
  }

  return {
    orderAISong,
    stickSongToTop,
    deleteSong,
  }
}