import { NatsClient } from "./client";

let clientInstance = null

export async function initNatsClient(natsConfig, messageHander) {
  const { eip, port, subs, mac, reconnectdelay, PingInterval } = natsConfig
  const subs_full = `${mac}_${subs}`

  if (clientInstance) {
    await clientInstance.close()
  }
  console.log('initNatsClient', natsConfig)
  console.log('initNatsClient', eip, port, subs_full)
  clientInstance = new NatsClient({
    servers: `wss://${eip}:${port}`,
    subs: subs_full,
    token: 'dYT6j7#kd&ld586',
    pingInterval: PingInterval * 1000,
    reconnectTimeWait: reconnectdelay * 1000,
    messageHander
  });
  await clientInstance.connect()
  // await clientInstance.createConnect();
  // clientInstance.subscribe();
  // clientInstance.receiveMsg(callback);
}