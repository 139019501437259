import { onUnmounted, ref } from 'vue'

export default function useBrowserSize() {
  const clientWidth = ref(1920)
  const clientHeight = ref(1080)
  const browserType = ref('landscape')

  function getClientWidth() {
    return document.body ? document.body.clientWidth : 0
  }
  
  function getClientHeight() {
    return document.body ? document.body.clientHeight : 0
  }
  
  function getBrowserSize() {
    clientWidth.value = getClientWidth()
    clientHeight.value = getClientHeight()
    // 竖屏
    if (clientHeight.value > clientWidth.value) {
      browserType.value = 'portrait'
      return
    }
    // 方屏
    if (Math.abs(clientHeight.value - clientWidth.value) < 209) {
      browserType.value = 'square'
      return
    }
    // 横屏
    browserType.value = 'landscape'
  }

  function addEventListenerOnsize() {
    if (window) {
      window.addEventListener('resize', getBrowserSize) 
    }
  }

  function removeEventListenerOnsize() {
    if (window) {
      window.removeEventListener('resize', getBrowserSize)
    }
  }

  onUnmounted(() => {
    removeEventListenerOnsize()
  })
  
  getBrowserSize()
  addEventListenerOnsize()

  return {
    clientWidth,
    clientHeight,
    browserType,
  }
}