
import httpV2 from '@/utils/httpV2'
import store2 from 'store2'

export async function getBaseInfo() {
  const res = await httpV2.get('/stb/v2/info/base', {
    params: {
      session: store2('_boot_id'),
    }
  })
  return res
}

export async function reportSession() {
  // const res = await httpV2.post('/stb/v2/info', {
  //   session: store2('_boot_id')
  // })
  // return res
}
export async function getConfig() {
  const res = await httpV2.get('/stb/v2/config')
  return res
}