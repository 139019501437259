import httpV2 from '@/utils/httpV2'
import get from 'lodash/get'

export async function postSongCollect({ unionid, songid }) {
  const res = await httpV2.post('/stb/v2/user/like-collect', {
    unionid,
    ssid: String(songid),
    tp: '0',
  })
  // console.log(res)
  return res;
}

export async function deleteSongCollect({ unionid, songid }) {
  const res = await httpV2.delete(`/stb/v2/user/like-collect?unionid=${unionid}&tp=0&ssid=${songid}`)
  // console.log(res)
  return get(res, 'errcode') == 200 ? get(res, 'data', {}) : {};
}

export async function getSongCollectList({ unionid }) {
  const res = await httpV2.get(`/stb/v2/user/like-collect?unionid=${unionid}&tp=0`)
  // console.log(res)
  return get(res, 'data', []);
}