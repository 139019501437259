import { getSingTaskStatus } from '@/service/singTask'
import get from 'lodash/get'

const state = {
  isShowSingTaskEnter: false,
  singTaskTime: 0,
  singTaskGetStatus: [false, false, false, false, false, false]
}

const getters = {}

const actions = {
  async initSingTaskTime({ commit }, unionid) {
    const res = await getSingTaskStatus({ unionid })
    if (res.time) {
      commit('INIT_SINGTASK_TIME', get(res, 'time', 0))
      let task_info = get(res, 'task_info', {
        1: false,
        3: false,
        5: false,
        7: false,
        9: false,
        11: false,
      })
      commit('UPDATE_SINGTASK_STATUS', [task_info[1], task_info[3], task_info[5], task_info[7], task_info[9], task_info[11]])
    }
  },
  async updateSingTaskStatus({ commit }, unionid) {
    const res = await getSingTaskStatus({ unionid })
    if (res.task_info) {
      let task_info = get(res, 'task_info', {
        1: false,
        3: false,
        5: false,
        7: false,
        9: false,
        11: false,
      })
      commit('UPDATE_SINGTASK_STATUS', [task_info[1], task_info[3], task_info[5], task_info[7], task_info[9], task_info[11]])
    }
    console.log("test:", res, Math.floor(Number(get(res, 'time', 0)) / 60))
    return Math.floor(Number(get(res, 'time', 0)) / 60)
  },
  addSingTaskTime({ commit }) {
    commit('ADD_SINGTASK_TIME')
  },
  resetSingTimeData({ commit }) {
    commit('RESET_SINGTASK_DATA')
  },
  setIsShowSingTaskEnter({ commit }) {
    commit('SET_ISSHOW_SINGTASK_ENTER', true)
  },
}

const mutations = {
  ADD_SINGTASK_TIME(state) {
    state.singTaskTime = Number(state.singTaskTime) + 1 // 每1s累加一次
    console.log(state.singTaskTime)
  },
  INIT_SINGTASK_TIME(state, data) {
    state.singTaskTime = data
    console.log('state.singTaskTime', state.singTaskTime)
  },
  UPDATE_SINGTASK_STATUS(state, data) {
    state.singTaskGetStatus = data
    console.log('state.singTaskGetStatus', state.singTaskGetStatus)
  },
  RESET_SINGTASK_DATA(state) {
    state.singTaskTime = 0
    state.singTaskGetStatus = [false, false, false]
  },
  SET_ISSHOW_SINGTASK_ENTER(state, data) {
    state.isShowSingTaskEnter = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
