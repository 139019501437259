<template>
  <CommonModal ref="root">
    <div class="activity-modal-signin">
      <img class="activity-modal-signin-top" src="https://qncweb.ktvsky.com/20230720/vadd/21380917c7573f3a984f3827dd3b46c8.png"/>
      <img class="activity-modal-signin-close" @click="handleCloseModal" src="https://qncweb.ktvsky.com/20230720/vadd/24ad8d105270a6319c1b1a3b91b5f770.png" alt="">
      <span class="activity-modal-signin-enter" @click="handleGoSignIn">详细规则</span>
      <div class="activity-modal-signin-title">
        签到送会员
      </div>
      <p class="activity-modal-signin-p">
        <span>累计签到第</span>
        <span>3、5、7</span>
        <span>天可领取VIP会员喔！</span>
      </p>
      <div class="activity-modal-signin-content">
        <StepComponent />
      </div>
      <div class="activity-modal-signin-bot">
        <div @click="handleSignIn" class="activity-modal-signin-bot-btn"  v-if="signInNowDay?.sign_status !== 1">{{ signInNowDay?.isVipDay ? '签到并领取' : '签到' }}</div>
        <div v-else class="activity-modal-signin-bot-btn activity-modal-signin-bot-btn-gray">已签到</div>
        <p v-if="signInNowDay?.sign_status == 1" class="activity-modal-signin-bot-p">{{ signInNowDay?.isVipDay ? `领取成功！会员有效期至${vipTime}！` : '签到成功！明天继续加油吧！'}}</p>
        <p v-else class="activity-modal-signin-bot-p">{{ signInNowDay?.isVipDay ? `今日签到可领取${signInNowDay?.showText.split('日')[0]}日会员喔！` : `再签到${botText[0]}天即可领取${botText[1]}日会员！`}}</p>
      </div>
    </div>
  </CommonModal>
</template>
<script>
import { computed, ref, toRefs, watch } from 'vue'
import store from '@/store'
import CommonModal from '@/components/modal/common/component.vue'
import StepComponent from '@/pages/signin/components/step'
import useVipQrcode from '@/components/modal/global/vip-qrcode/create.js'
import { sendLog } from '@/directives/v-log/log'
import { debounce } from 'lodash'

export default {
  name: 'ActivitySignInModal',
  components: {
    CommonModal,
    StepComponent,
  },
  props: {
    closeEvent: {
      type: Function,
      default: () => {
      }
    },
    router: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const root = ref(null)
    const userInfo = computed(() => store.state.userInfo)
    const isLogin = computed(() => !!userInfo.value.unionid)
    const vipTime = computed(() => store.state.vipInfo.end_time)
    const { router } = toRefs(props)
    const $useVipQrcode = useVipQrcode()
    const signInData = computed(() => store.state.signin.signInData)
    const nowActivityDay = computed(() => store.state.signin.nowActivityDay)
    const mac_id = computed(() => store.state.macAddress)
    const sign_days = computed(() => store.state.signin.sign_days)
    const signInNowDay = computed(() => signInData.value[nowActivityDay.value])
    // ['还需签到天数', '领取的vip天数']
    const botText = computed(() => {
      if (sign_days.value < 3) {
        return [3 - sign_days.value, 1]
      } else if (sign_days.value < 5) {
        return [5 - sign_days.value, 3]
      } else if (sign_days.value < 7) {
        return [7 - sign_days.value, 7]
      }
      return [3, 1]
    })

    let vipQrcodeInstance = ref(null)
    const clickEvent = ['signin', 'gopage']
    let currClickEvent = ref(null)

    const handleCloseModal = () => {
      // const now = Date.now()
      props.closeEvent.call()
      root.value.hide()
    }

    const checkLogin = (eventN) => {
      if (!isLogin.value) {
        currClickEvent.value = eventN
        vipQrcodeInstance.value = $useVipQrcode.show({
          onHide: () => {
            vipQrcodeInstance.value = null
          }
        })
        store.dispatch('startCheckLoginStatus')
        return false
      }
      return true
    }

    const handleGoSignIn = () => {
      sendLog({
        event_type: 'click',
        event_name: 1729,
        event_data: {
          str1: '1'
        }
      })
      if (!checkLogin(clickEvent[1])) return
      goSignPage()
    }

    const goSignPage = () => {
      handleCloseModal()
      router.value.push({
        name: 'signin'
      })
    }

    const handleSignIn = debounce(() => {
      if (!checkLogin(clickEvent[0])) return

      // 签到
      store.dispatch('signin/signInToday', {
        unionid: userInfo.value.unionid, 
        mac_id: mac_id.value
      })
    }, 800)

    watch(isLogin, (val) => {
      if (val && vipQrcodeInstance.value) {
        vipQrcodeInstance.value.hide()
        if (currClickEvent.value === clickEvent[0]) {
          // handleSignIn()
        } else if (currClickEvent.value === clickEvent[1]) {
          goSignPage()
        }
      }
    })

    return {
      root,
      signInData,
      nowActivityDay,
      vipTime,
      botText,
      signInNowDay,
      handleCloseModal,
      handleGoSignIn,
      handleSignIn,
    }
  }
}
</script>
<style lang="stylus" scoped>
.activity-modal-signin
  padding 130px 80px 0
  position relative
  width 1000px
  height 730px
  background linear-gradient(158.72deg, #585450 -44.7%, #1E1F21 91.57%)
  // background url(https://qncweb.ktvsky.com/20220826/vadd/f204d895e76caab9fe2540ddc41188b5.png) no-repeat
  // background-size 100% 100%
  // background-position center
  border-radius 20px
  color rgba(255, 255, 255, .8)
  display flex
  flex-direction column
  align-items center
  &-top
    width 322px
    height 322px
    position absolute
    top -150px
    left 339px
  &-title
    margin-bottom 20px
    font-size 50px
    font-weight 700
    line-height 1
    color rgba(240, 210, 144, 1)
    letter-spacing 1px
    background linear-gradient(90deg, #F0D290 0%, #F9EBC3 100%)
    background-clip text
    -webkit-background-clip text
    -webkit-text-fill-color transparent
  &-enter
    position absolute
    top 60px
    right 30px
    line-height 1
    text-decoration-line underline
    font-size 28px
    color #fff
  &-p
    span
      text-align center
      font-size 28px
      font-style normal
      font-weight 400
      line-height normal
      letter-spacing 6px
      &:nth-child(1)
      &:nth-child(3)
        color rgba(255, 255, 255, 0.50)
      &:nth-child(2)
        color #F5DFAA
  &-content
    width 932px 
    height 260px
    display flex
    align-items center
  &-bot
    width 100%
    display flex
    flex-direction column
    align-items center
    &-btn
      width 300px
      height 90px
      border-radius 14px
      color rgba(0, 0, 0, 0.80)
      text-align center
      font-size 32px
      font-style normal
      font-weight 400
      line-height 90px
      margin-bottom 30px
      background linear-gradient(90deg, #FBD7AF 0%, #F1BE73 100%)
    &-btn-gray
      color rgba(255, 255, 255, 0.3)
      background #383A3E
    &-p
      color rgba(255, 255, 255, 0.50)
      text-align center
      font-size 28px
  &-close
    width 90px
    height 90px
    position absolute
    top 30px
    left 30px
</style>
