import EffectAdjustModalComponent from './index.vue'
import useEffectAdjustModal from './create';

const Plugin = (app, props = {}, slots = {}) => {
  const effectAdjustModal = useEffectAdjustModal(props, slots);
  app.config.globalProperties.$effectAdjustModal = effectAdjustModal;
  app.provide('$effectAdjustModal', effectAdjustModal)
};

EffectAdjustModalComponent.install = Plugin;

export default EffectAdjustModalComponent;
