import { ref, inject, computed, watch } from 'vue'
// import { useStore } from 'vuex'
import store from '@/store'
import { sendLog } from '@/directives/v-log/log'
import get from "lodash/get"

let fromType = ''

export default function useVip() {
  // const store = useStore()
  const $vipQrcode = inject('$vipQrcode')
  const vipQrcodeInstance = ref(null)

  const isLogin = computed(() => !!store.state.userInfo.unionid)
  const userType = computed(() => store.state.userInfo.userType)

  const isVipUser = computed(() => {
    if (store.state.vipInfo?.end_time) {
      const vipEndDate = new Date(store.state.vipInfo.end_time).getTime()
      const now = Date.now()
      return now <= vipEndDate
    }
    return false
  })

  const showVipQrcode = (obj) => {
    if (vipQrcodeInstance.value) {
      return
    }
    fromType = obj?.fromType
    let songData = get(obj, 'songid', false) ? {
      song_id: obj.songid
    } : {}
    sendLog({
      event_type: 'show',
      event_name: 256,
      event_data: {
        source: get(obj, 'src', ''), // 新增来源场景字段
        ...songData,
      }
    })
    if (obj?.fromType === 'vipHighQuality') {
      sendLog({
        event_type: '10000~50000',
        event_name: 30179,
        event_data: {
          str1: '欢唱页',
          str2: '画质选择',
          str3: '1080P按钮',
          str4: 'show',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        },
      })
    }
    console.log("showVipQrcode 1",$vipQrcode)
    vipQrcodeInstance.value = $vipQrcode.show({
      ...obj,
      onHide: () => {
        vipQrcodeInstance.value = null
        obj && obj.onHide && obj?.onHide();
      }
    })
    store.dispatch('startCheckLoginStatus')
  }

  const handleIsLoginChange = (val) => {
    if (val && vipQrcodeInstance.value && fromType != 'climax') {
      console.log('handleIsLoginChange', vipQrcodeInstance.value, fromType)
      vipQrcodeInstance.value.hide()
    }
  }

  const handleIsVipChange = (val) => {
    if (val && vipQrcodeInstance.value) {
      console.log('handleIsVipChange', vipQrcodeInstance.value)
      vipQrcodeInstance.value.hide()
    }
  }

  watch(isLogin, handleIsLoginChange)

  watch(isVipUser, handleIsVipChange)

  return {
    isLogin,
    isVipUser,
    showVipQrcode,
    vipQrcodeInstance
  }
}
