import axios from "axios";
import getHttpConfig from "./request/config";
import getRequestCommonParams from "./request/request-common-params";
import { showErrorToast, reportError } from "./request/error-handler";

const http = axios.create(
  getHttpConfig({
    urls: {
      pro: "//r.ktvsky.com",
      pre: "//xpre.ktvsky.com",
      test: "//r.stage.ktvsky.com",
    },
  })
);

http.interceptors.request.use((request) => {
  if (request.method === "POST") {
    if (!request.url.includes("/stb")) {
      request.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
  }
  if (request.url.includes("/stb") || request.url.includes("carplay")) {
    const commonParams = getRequestCommonParams();
    request.params = {
      ...request.params,
      ...commonParams,
    };
  }
  return request;
});

http.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.errcode === 200 || res.errcode === 21001 || res.code === 200) {
      return res;
    }
    showErrorToast(res.errmsg);
    return res;
  },
  (error) => {
    reportError(error);
    if (error.toJSON().message.includes("timeout of 15000ms")) {
      showErrorToast("页面加载超时，请退出后重试");
      return;
    }
    showErrorToast("网络异常, 请稍后重试");
  }
);

export default http;
