<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div class="vip-modal-content">
      <img @click="handleCloseModal" class="close" src="https://qncweb.ktvsky.com/20211109/vadd/8c10127469ec6e740a3de5c76dee9f66.png"/>
      <div class="title1">
        <img src="https://qncweb.ktvsky.com/20230823/vadd/aa55ad29405db22e3f725999518b6a4e.png" alt="">
        <span>开通会员参加活动</span>
      </div>
      <div class="title2">30天会员：<span>29元</span></div>
      <div class="qrcode">
        <img :src="qrCodeURL" />
      </div>
      <div class="bot-tip">支付成功且完成开通后，即成功领取任务</div>
    </div>
  </CommonModal>
</template>
<script>
import { onBeforeMount, ref, toRefs, computed } from 'vue'
import store from '@/store'
import CommonModal from '@/components/modal/common/component.vue'
import { getCarplayInfo } from '@/service/carplay-info'
import useQRCode from '@/composables/useQRCode'
import { Toast } from 'vant'
import get from 'lodash/get'

export default {
  name: 'VipModalQRcode',
  components: {
    CommonModal,
  },
  setup(props) {
    const { songid } = toRefs(props)
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')

    const getVipQrcode = async () => {
      // console.log('vipQRcode:', songid.value)
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(`${data.pay_qr}&tp=zerobuy`) // 携带这个参数时，扫码跳转服务端重定向到新的0元购活动支付页面
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const handleCloseModal = () => {
      // 关闭时查取当前任务状态（是否参与）
      store.dispatch('getCarplayInfo')
      root.value.hide()
    }

    const handleCancel = () => {
      // 关闭时查取当前任务状态（是否参与）
      store.dispatch('getCarplayInfo')
    }

    onBeforeMount(getVipQrcode)

    return {
      qrCodeURL,
      root,
      isLogin,
      handleCancel,
      handleCloseModal,
    }
  }
}
</script>
<style lang="stylus" scoped>
.vip-modal
  &-content
    padding-top 52px
    position relative
    width 1000px
    height 700px
    background #1E1F21
    border-radius 20px
    color rgba(255, 255, 255, .8)
    display flex
    flex-direction column
    align-items center
    .close
      position absolute
      top 30px
      left 30px
      width 90px
      height 90px
    .title1
      height 46px
      line-height 46px
      margin-bottom 52px
      font-size 40px
      color rgba(255, 255, 255, 0.8)
      display flex
      align-items center
      img
        width 44px
        height 36px
        margin-right 10px
    .title2
      height 43px
      line-height 43px
      margin-bottom 42px
      font-size 32px
      color rgba(255, 255, 255, 0.8)
      display flex
      align-items center
      span
        color rgba(240, 210, 144, 1)
        font-size 36px
        font-weight 600
    .qrcode
      width 320px
      height 320px
      margin-bottom 52px
      display flex
      justify-content center
      align-items center
      background #ffffff
      border-radius 10px
      img
        width 300px
        height 300px
    .bot-tip
      font-size 32px
      text-align center
      color rgba(255, 255, 255, 0.4)
</style>
