<template>
  <transition>
    <div class="thunder-teleport-overlay">
      <div class="thunder-teleport-background" @click="$emit('cancel')"></div>
      <div class="thunder-teleport-icon">
        <slot name="default"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'commonTeleportModal',
}
</script>

<style lang="stylus" scoped>
.thunder-teleport-overlay
  position fixed
  bottom 0
  left 0
  right 0
  top 0
  display flex
  align-items center
  justify-content center
  overflow hidden
  z-index 9999
  .thunder-teleport-background
    position absolute
    bottom 0
    left 0
    right 0
    top 0
    background rgba(0, 0, 0, 0.7)
  .thunder-teleport-content
    position relative
</style>