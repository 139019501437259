import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import get from 'lodash/get'
import useSetting from './useSetting'
import { resolutionRatio } from '@/service/user'
import { sendLog } from '@/directives/v-log/log'
import use1080pModal from '@/components/modal/global/1080-modal/create'

export default function useQuality() {
  const store = useStore()
  const { setting } = useSetting()
  const playingSongItem = computed(() => store.state.videoPlayerHistory.songItem)
  const unionid = computed(() => store.state.userInfo.unionid)
  
  const isVipUser = computed(() => {
    if (store.state.vipInfo?.end_time) {
      const vipEndDate = new Date(store.state.vipInfo.end_time).getTime()
      const now = Date.now()
      return now <= vipEndDate
    }
    return false
  })

  const $1080pModal = use1080pModal()
  const modal1080pInstance = ref(null)

  // 及时选择正播放歌曲的画质
  const useMvQuality = (val) => {
    // 关闭无1080画质副作用操作
    store.commit('UPDATE_LOWERBEFOREQ', '')

    // 同步当前正播放歌曲的画质选项
    store.commit('CHANGE_PLAYING_MV_QUALITY', val)

    let hls =  get(playingSongItem.value, `m3u8.${val}`, '')
    if (hls) {
      store.commit('CHANGE_PLAYING_MV_QUALITY_RESOURCE', hls)
    }

    // 切换画质时同步本地数据
    store.dispatch('saveSetting', {
      ...setting.value,
      quality: val
    })
    // 同步服务器云端画质
    if (unionid.value) {
      console.log('同步服务器云端画质:', unionid.value, val)
      resolutionRatio({ unionid: unionid.value, quality: val})
    }
  }

  const show1080pModal = (payload) => {
    if (modal1080pInstance.value) {
      return
    }
    modal1080pInstance.value = $1080pModal.show({
      ...payload,
      onHide: () => {
        modal1080pInstance.value = null
      },
      cancelEvent: () => {
        typeof payload.cancelEvent === 'function' && payload.cancelEvent()
        modal1080pInstance.value = null
      }
    })
  }

  const handleIsVipChange = (val) => {
    if (val && modal1080pInstance.value) {
      modal1080pInstance.value.hide()
    }
  }

  watch(isVipUser, handleIsVipChange)

  return {
    useMvQuality,
    show1080pModal,
  }
}