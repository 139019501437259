import { createComponent } from '@/components/modal/utils/index.js';
import SingNotify from './index.vue';

export default function useSingNotify(globalProps = {}, globalSlots = {}) {

  return {
    show(props = globalProps, slots = globalSlots) {
      const forceProps = {
        active: true,
      }

      const propsData = Object.assign({}, globalProps, props, forceProps);
      const container = document.body;

      const mergedSlots = Object.assign({}, globalSlots, slots);
      const instance = createComponent(SingNotify, propsData, container, mergedSlots);
      return {
        hide: instance.refs.root.hide
      }
    },
  }
}
