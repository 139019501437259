<template>
  <!-- 强制登录弹窗 -->
  <CommonModal ref="root" class="force-login-modal">
    <div class="force-login-modal-content">
      <!-- <img @click="handleCloseLogin" class="close" src="https://qncweb.ktvsky.com/20211109/vadd/8c10127469ec6e740a3de5c76dee9f66.png"/> -->
      <div class="title">
        <img src="https://qncweb.ktvsky.com/20231009/vadd/aa55ad29405db22e3f725999518b6a4e.png" alt="">
        <span>扫码登录 立即演唱</span>
      </div>
      <div class="qrcode">
        <img :src="qrCodeURL" />
      </div>
      <div class="tip">通过微信扫码即可登录演唱</div>
    </div>
  </CommonModal>
</template>
<script>
import { onBeforeMount, ref } from 'vue'
import CommonModal from '@/components/modal/common/component.vue'
import { getCarplayInfo } from '@/service/carplay-info'
import useQRCode from '@/composables/useQRCode'
import { Toast } from 'vant'
import get from 'lodash/get'
import { vipLogFrom } from '@/constants/index'

export default {
  name: 'ForceLoginModal',
  components: {
    CommonModal,
  },
  props: {

  },
  setup(props) {
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')

    const getLoginQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'login_qr', '')) {
        const loginUrl = `${data.pay_qr}&log=${vipLogFrom.get('强制登录')}`
        const qrCodeData = await getQRCodeURL(loginUrl)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const handleCloseLogin = () => {
      root.value.hide()
    }

    onBeforeMount(getLoginQrcode)

    return {
      qrCodeURL,
      root,
      handleCloseLogin,
    }
  }
}
</script>
<style lang="stylus" scoped>
.force-login-modal
  z-index 99999 !important
  &-content
    padding-top 52px
    position relative
    width 1000px
    height 700px
    background #1E1F21
    border-radius 20px
    color rgba(255, 255, 255, .8)
    display flex
    flex-direction column
    align-items center
    // .close
    //   position absolute
    //   top 30px
    //   left 30px
    //   width 90px
    //   height 90px
    .title
      margin-bottom 82px
      font-size 40px
      display flex
      align-items center
      justify-content center
      img
        width 44px
        height auto
        margin-right 10px
    .qrcode
      width 320px
      height 320px
      margin-bottom 70px
      display flex
      justify-content center
      align-items center
      background #ffffff
      border-radius 10px
      img
        width 300px
        height 300px
    .tip
      font-size 32px
</style>
