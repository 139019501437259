<template>
  <div class="collect-song-item">
    <div class="info" @click="handleOrder">
      <p>
        <span class="name">{{ songItem.music_name }}</span>
        <span class="vip" v-if="songItem.is_vip">VIP</span>
      </p>
      <p>
        <span class="singer">{{ songItem.singer }}</span>
        <span class="divide"></span>
        <span class="flag">{{ songItem.flag && songItem.flag.toString() }}</span>
      </p>
    </div>
    <div class="control">
      <img v-if="!songItem.noCollect" @click="handleUnLiked" src="https://qncweb.ktvsky.com/20230717/vadd/a3826d976f35f4794a4fa74ea8b6d09c.png"/>
      <img v-else @click="handleLiked" src="https://qncweb.ktvsky.com/20230718/vadd/64a23b873deec37e5eeccd4239af4e10.png"/>
      <img @click="handleStickSongToTop" src="https://qncweb.ktvsky.com/20211011/vadd/b1792221860c7c32cbedc46f1f637d42.png"/>
    </div>
  </div>
</template>
<script>
import { toRefs } from 'vue'

export default {
  name: 'collectSongItem',
  props: {
    index: Number,
    songItem: {
      type: Object,
      default() {
        return {
          acc: '1',
          org: '2',
          flag: [],
          m3u8: {
            480: '',
            720: '',
            1080: '',
          },
          music_name: '',
          played: 0,
          singer: '',
          songid: 0,
        }
      }
    }
  },
  setup(props, { emit }) {
    const { songItem } = toRefs(props)
    
    const handleLiked = () => {
      emit('collect', songItem.value)
    }

    const handleUnLiked = () => {
      emit('uncollect', songItem.value)
    }

    const handleStickSongToTop = () => {
      emit('stick-top', songItem.value)
    }

    const handleOrder = () => {
      emit('order', songItem.value)
    }

    return {
      handleLiked,
      handleUnLiked,
      handleStickSongToTop,
      handleOrder,
    }
  },
}
</script>
<style lang="stylus" scoped>
.collect-song-item
  padding 0 50px 0 50px
  width 100%
  height 130px
  display flex
  justify-content space-between
  @media screen and (max-width: 1200px)
    padding 25px
  .info
    display flex
    flex-direction column
    color rgba(255, 255, 255, .4)
    font-size 28px
    flex 1
    .name
      white-space nowrap
      overflow hidden
      text-overflow ellipsis
      max-width 690px
      color rgba(255, 255, 255, .8)
      margin-bottom 0 !important
      @media screen and (max-width: 1200px)
        max-width 620px
    .vip
      display flex
      justify-content center
      align-items center
      width 64px
      height 36px
      font-size 22px
      border 3px solid #F0D290
      color #F0D290
      border-radius 8px
      margin-left 16px
    .divide
      width 2px
      height 30px
      margin 0 16px
      background rgba(255, 255, 255, .2)
    p
      &:first-child
        display flex
        align-items center
        font-size 32px
        margin-bottom 12px
        img
          width 32px
          height 32px
          margin-left 16px
      &:last-child
        display flex
        align-items center
  .control
    display flex
    align-items center
    img
      width 36px
      height 36px
      &:last-child
        margin-left 70px
</style>