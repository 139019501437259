// import http from '@/utils/http'
import httpV2 from '@/utils/httpV2'
import get from 'lodash/get'

export async function getPlaylist() {
  // '/stb/carplay/top/playlist'
  const res = await httpV2.get('/stb/v2/top/songlist')
  return get(res, 'data', []);
}

export async function getPlaylistDetail({p, type, version}) {
  // '/stb/carplay/detail/rank'
  let params = {
    p,
    size: 10,
    type,
  }
  if (version && p > 1) {
    params = {
      ...params,
      ver: version
    }
  }
  const res = await httpV2.get('/stb/v2/detail/songlist', { params })
  if (p <= 1) {
    return {
      data: [...get(res, 'data', [])],
      version: get(res, 'version', {})
    }
  }
  return {
    data: get(res, 'data', []),
    version: get(res, 'version', {})
  }
}

export async function getClasslistDetail({p, type, version}) {
  // '/stb/carplay/detail/rank'
  let params = {
    p,
    size: 10,
    type,
  }
  if (version && p > 1) {
    params = {
      ...params,
      ver: version
    }
  }
  const res = await httpV2.get('/stb/v2/detail/classlist', { params })
  if (p <= 1) {
    return {
      data: [...get(res, 'data', [])],
      version: get(res, 'version', {})
    }
  }
  return {
    data: get(res, 'data', []),
    version: get(res, 'version', {})
  }
}

export async function getMiniDefaultList() {
  const res = await httpV2.get('/stb/v2/smv')
  return get(res, 'data', []);
}
