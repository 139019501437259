import { sendLog } from './log';

export default class Listener {
    el = null;

    value = null;

    isDone = false;

    io = null;

    constructor({ el, value }) {
      this.el = el;
      this.value = value;
      this.io = this.createIntersectionObserver(el);
    }

    createIntersectionObserver(el) {
      if (this.io) {
        // for update
        this.io.disconnect();
        this.io = null;
      }

      const io = new IntersectionObserver(
        (entries) => {
          if (!entries || !entries.length) {
            return;
          }
          if (entries.some((e) => e.isIntersecting)) {
            this.do();
            if (io) {
              this.destroy();
            }
          }
          if (entries[0].isIntersecting) {
            this.do();
          }
        },
        { threshold: 0 },
      );

      io.observe(el);
      return io;
    }

    // 执行
    do() {
      if (this.isDone) {
        return;
      }
      sendLog(this.value);
      this.isDone = true;
    }

    // 更新
    update(el, value) {
      this.io = this.createIntersectionObserver(el);
      this.el = el;
      this.value = value;
      this.isDone = false;
    }

    destroy() {
      if (this.io) {
        this.io.disconnect();
      }
      this.el = null;
      this.value = null;
    }
}
