<template>
  <div class="order-song-item" :class="{ playing: isPlaying }">
    <div class="info" @click="handleOrder">
      <p>
        <span class="name">{{ songItem.name }}{{ songItem.nickname ? `-` : '' }}{{ songItem.nickname }}</span>
        <img v-if="isPlaying" src="https://qncweb.ktvsky.com/20231212/vadd/81cfbbbf4c7c514648c15f07f34a75b1.webp" />
      </p>
      <p>
        <!-- <template v-if="songItem.singer">
          <span
            class="singer"
            :class="singerCanClick && 'clickable'"
            @click="handleClickSinger"
          >{{ songItem.singer }}</span>
          <span class="divide"></span>
        </template> -->
        <span class="singer">{{ songItem.singer }}</span>
        <!-- <span class="divide"></span> -->
        <!-- <span class="flag">{{ songItem.flag && songItem.flag.toString() }}</span> -->
        <!-- <img v-if="songItem.is_vip" class="song-block-vip" src="https://qncweb.ktvsky.com/20231206/vadd/56dc0bc6477bf2c7a6c4fcdc8360804e.png" alt=""> -->
      </p>
    </div>
    <!-- <div class="control">
      <img v-if="index > 1" @click="handleStickSongToTop" src="https://qncweb.ktvsky.com/20231208/vadd/74853feba54ba0556dcf4148d1b78bdf.png"/>
      <img v-if="!isPlaying" @click="handleDelete" src="https://qncweb.ktvsky.com/20231208/vadd/55bd912a711c13ebe9bf8b369d72c1fa.png"/>
    </div> -->
  </div>
</template>
<script>
import { toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { sendLog } from '@/directives/v-log/log'
import split from 'lodash/split'

export default {
  name: 'OrderedSongItem',
  props: {
    index: Number,
    songItem: {
      type: Object,
      default() {
        return {
          acc: '1',
          org: '2',
          flag: [],
          m3u8: {
            480: '',
            720: '',
            1080: '',
          },
          music_name: '',
          played: 0,
          singer: '',
          songid: 0,
        }
      }
    },
    isLandscape: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const { songItem, index, isLandscape } = toRefs(props)

    const store = useStore()
    // const singerCanClick = computed(() => (!store.state.mvIsHide && isLandscape.value) || store.state.mvIsHide)
    // const videoPaused = computed(() => store.state.videoPaused)
    
    const isPlaying = computed(() => index.value === 0)

    const handleDelete = () => {
      emit('delete', index.value)
    }

    const handleStickSongToTop = () => {
      emit('stick-top', index.value)
    }

    const handleOrder = () => {
      emit('order', songItem.value, index.value)
    }

    // const handleClickSinger = (e) => {
    //   if (!singerCanClick.value) return
    //   e.stopPropagation();
    //   sendLog({
    //     event_type: '10000~50000',
    //     event_name: 10109,
    //     event_data: {
    //       str1: '任意点歌页',
    //       str2: '歌曲列表',
    //       str3: '点击任意歌手',
    //       str4: 'click',
    //     },
    //   })
    //   emit('singer-click', {
    //     singer: split(songItem.value.singer, ',')[0],
    //     singerhead: songItem.value.singer_head,
    //     singerid: songItem.value.singerid,
    //   })
    // }

    return {
      isPlaying,
      // singerCanClick,
      handleDelete,
      handleStickSongToTop,
      handleOrder,
      // handleClickSinger,
      // videoPaused,
    }
  },
}
</script>
<style lang="stylus" scoped>
.order-song-item
  padding 30px 0
  width 100%
  height 137px
  display flex
  justify-content space-between
  border-bottom 1px solid rgba(255, 255, 255, 0.08)
  @media screen and (max-width 1200px)
    height 130px
    padding 30px 20px
  &.playing
    .info
      color rgba(219, 174, 106, 1)
      .divide
        background rgba(219, 174, 106, 1)
      .name
        color rgba(219, 174, 106, 1)
      .clickable::after
        content ""
        background-image url('https://qncweb.ktvsky.com/20231212/vadd/4a5a96b4a7e0b0ae7f364679f5f69417.png')
  .info
    display flex
    flex-direction column
    color rgba(255, 255, 255, .4)
    font-size 28px
    flex 1
    .name
      white-space nowrap
      overflow hidden
      text-overflow ellipsis
      max-width 690px
      color rgba(255, 255, 255, .8)
      margin-bottom 0 !important
      @media screen and (max-width: 1200px)
        max-width 620px
    .vip
      display flex
      justify-content center
      align-items center
      width 64px
      height 36px
      font-size 22px
      border 3px solid #F0D290
      color #F0D290
      border-radius 8px
      margin-left 16px
    .divide
      width 2px
      height 30px
      margin 0 16px
      background rgba(255, 255, 255, .2)
    p
      &:first-child
        height 38px
        line-height 38px
        display flex
        align-items center
        font-size 32px
        margin-bottom 10px
        img
          width 38px
          height 38px
          margin-left 8px
      &:last-child
        height 28px
        line-height 38px
        display flex
        align-items center
    .clickable
      position relative
      padding-right 26px
      &::after
        content ""
        position absolute
        right 0
        top 50%
        margin-top -10px
        width 22px
        height 22px
        background url('https://qncweb.ktvsky.com/20231212/vadd/70dbe52816b882ae1f6871b3a509f375.png') no-repeat
        background-size 100% 100%
  .song-block-vip
     width 50px
     height 26px
     margin-left 13px
   @media screen and (max-width 1200px)
     .info p
       &:nth-child(1)
         font-size 26px
       &:nth-child(2)
         font-size 20px
  .control
    display flex
    align-items center
    img
      width 40px
      height 40px
      &:last-child
        margin-left 72px
</style>