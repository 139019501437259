import http from '@/utils/httpV2'

export async function loginout({ mac_id, unionid }) {
  // /stb/carplay/user/offline
  const res = await http.post('/stb/v2/user/logout', {
    mac_id,
    unionid,
  })
  return res
}

export async function partakeSendVipActivity() {
  const res = await http.post('/stb/v2/vip/free')
  return res
}

// 仅测试时使用
export async function deleteActivity() {
  const res = await http.delete('/stb/v2/vip/free')
  return res
}

// 画质云控
export async function resolutionRatio({ unionid, quality }) {
  const res = await http.put(`/stb/v2/user?tp=resolution&unionid=${unionid}&resolution=${quality}`)
  return res
}

// ai mv 用户是否过期
export async function getAiMvUserIsexpire({ unionid }) {
  const res = await http.get(`/stb/v2/aiface/check?unionid=${unionid}`)
  return res
}

// 用户免费体验上报接口
export async function getFreeVip({ unionid }) {
  const res = await http.get(`/stb/v2/free_vip?unionid=${unionid}`)
  return res
}