<template>
  <div class="mv-core" :class="[
    $route.name == 'search' && 'mv-core-search',
    $route.name == 'singer' && 'mv-core-singer',
    $route.name == 'songList' && 'mv-core-songlist',
    $route.name == 'mine' && 'mv-core-mine',
    $route.name == 'profile' && 'mv-core-profile'
  ]">
    <MvMini v-if="isShowMini && isShowMiniByRouteName" />
    <MvPageComponent v-show="!isShowMini" ref="mvPageComponentRef" />
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import MvMini from "@/components/mv/mini.vue";
import MvPageComponent from "@/components/mv/index.vue";
import eventBus from "@/utils/event-bus";

export default ({
  name: 'MvCore',
  components: {
    MvMini,
    MvPageComponent,
  },
  props: {
    isShowMiniByRouteName: {
      type: Boolean,
      default: true
    },
    // title: {
    //   type: String,
    //   default: 'MV Core 组件'
    // }
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const isShowMini = ref(true);
    const orderedList = computed(() => store.state.orderedList);
    const videoPaused = computed(() => store.state.videoPaused);
    const isVip = computed(() => !!store.state.vipInfo.end_time);
    const mvIsHide = computed(() => store.state.mvIsHide);
    const mvPageComponentRef = ref(null);


    // const limitRouteName = ['home', 'search', 'singer', 'songList'];
    // const isShowMiniByRouteName = computed(() => {
    //   console.log('route.name', route.name)
    //   return limitRouteName.includes(route.name)
    // })

    // const isShowMiniByRouteName = ref(true);
    // watch(() => route, (newRoute) => {
    //   console.log('route.name 变化:', newRoute.name, limitRouteName.includes(newRoute.name))
    //   isShowMiniByRouteName.value = limitRouteName.includes(newRoute.name)
    //   console.log('isShowMiniByRouteName', isShowMiniByRouteName.value)
    // }, { deep: true })

    // watch(isShowMiniByRouteName, (newVal) => {
    //   console.log('isShowMiniByRouteName 变化:', newVal)
    // }, { deep: true })

    watch(
      [mvIsHide, orderedList, isVip],
      () => {
        if (!videoPaused.value && orderedList.value.length) {
          isShowMini.value = false;
          return;
        }

        if (document.querySelector(".vip-modal-content")) {
          return;
        }

        const shouldShowMini =
          mvIsHide.value &&
          (orderedList.value.length === 0 ||
            (orderedList.value[0].is_vip && !isVip.value));

        isShowMini.value = shouldShowMini;

        if (isShowMini.value) {
          // && mvPageComponentRef.value
          // mvPageComponentRef.value.handleVideoControlPause();
          eventBus.emit("handle-video-pause");
        }
      },
      {
        deep: true,
      }
    );

    const onCloseVipQrcode = () => {
      isShowMini.value = true;
    };

    onMounted(() => {
      console.log('mv-core onMounted');
      eventBus.on("handle-close-vip-qrcode", onCloseVipQrcode);
    });

    onUnmounted(() => {
      console.log('mv-core onUnmounted');
      eventBus.off("handle-close-vip-qrcode", onCloseVipQrcode);
    });

    return {
      isShowMini,
      mvPageComponentRef,
      // isShowMiniByRouteName,
    }
  }
})
</script>

<style lang="stylus" scoped>
.mv-core 
  position: fixed;
  top: 132px;
  left: 40px;
  // width: 100%;
  // height: 100%;
  z-index: 2;
  @media screen and (max-width 1200px) and (min-height 1200px)
    top 112px
.mv-core-search
  top 164px
  left 80px
  width 854px
  height 480px
  .mv-mini
    left 0px
    width 854px
    height 480px
  // transform scale(0.854)
  @media screen and (max-width 1200px) and (min-height 1200px)
    top 104px
    left 60px
    .mv-mini
      // left 80px
      width 1080px
      height 631px
.mv-core-singer
  top 164px
  left 80px
  width 854px
  height 480px
  .mv-mini
    left 0px
    width 854px
    height 480px
  @media screen and (max-width 1200px) and (min-height 1200px)
    top 104px
    left 60px
    .mv-mini
      // left 80px
      width 1080px
      height 631px
.mv-core-songlist
  top 164px
  left 80px
  width 854px
  height 480px
  .mv-mini
    left 0px
    width 854px
    height 480px
  @media screen and (max-width 1200px) and (min-height 1200px)
    top 104px
    left 60px
    .mv-mini
      // left 80px
      width 1080px
      height 631px
.mv-core-mine
  top 164px
  left 80px
  width 854px
  height 480px
  .mv-mini
    left 0px
    width 854px
    height 480px
  @media screen and (max-width 1200px) and (min-height 1200px)
    top 104px
    left 60px
    .mv-mini
      // left 80px
      width 1080px
      height 631px
.mv-core-profile
  top 164px
  left 80px
  width 854px
  height 480px
  .mv-mini
    left 0px
    width 854px
    height 480px
  @media screen and (max-width 1200px) and (min-height 1200px)
    top 104px
    left 60px
    .mv-mini
      // left 80px
      width 1080px
      height 631px
</style>
