import store2 from 'store2'
import { customAlphabet } from 'nanoid'

const nanoid = customAlphabet('1234567890abcdef', 10)

export function getMacAddress() {
  if (store2('mac_address')) {
    return store2('mac_address')
  }
  const mac = nanoid()
  store2('mac_address', mac)
  return mac
}