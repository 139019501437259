<template>
  <div class="section-container">
    <div class="section-container-header" v-if="hasTitle">
      <div class="section-container-header-title">{{ title }}</div>
      <div class="section-container-header-more" v-if="moreDetail" @click.stop="goDetail">
        <span>更多</span>
        <img src="https://qncweb.ktvsky.com/20230809/vadd/e072c19f2b00e9e5308378195ea44e27.png" alt="">
      </div>
      <slot name="header"></slot>
    </div>
    <div class="section-container-list">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { computed, toRefs } from 'vue'
export default {
  name: 'SecContainer',
  props: {
    title: {
      type: String,
      default: ''
    },
    moreDetail: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { title, moreDetail } = toRefs(props)
    const hasTitle = computed(() => !!title.value)
    const goDetail = () => {
      if (moreDetail.value) emit("enter-more")
    }
    return {
      hasTitle,
      goDetail,
    }
  }
}
</script>
<style lang="stylus" scoped>
.section-container
  width 100%
  min-height 40px
  &-header
    width 100%
    padding  0 0 40px 0
    display flex
    align-items center
    justify-content space-between
    &-title
      font-size 32px
      color rgba(255, 255, 255, 0.6)
    &-more
      font-size 28px
      color rgba(255, 255, 255, 0.6)
      display flex
      align-items center
      img
        width 18px
        height 32px
        margin-left 10px
  &-list
    width 100%
</style>