import LoginQrcodeComponent from './index.vue'
import useLoginQrcode from './create.js';

const Plugin = (app, props = {}, slots = {}) => {
  const loginQrcode = useLoginQrcode(props, slots);
  app.config.globalProperties.$loginQrcode = loginQrcode;
  app.provide('$loginQrcode', loginQrcode)
};

LoginQrcodeComponent.install = Plugin;

export default LoginQrcodeComponent;
