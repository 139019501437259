import ActivityModalComponent from './index.vue'
import useActivity from './create.js'

const Plugin = (app, props = {}, slots = {}) => {
  const ActivityModal = useActivity(props, slots);
  app.provide('$activityModal', ActivityModal)
};

ActivityModalComponent.install = Plugin;

export default ActivityModalComponent;
