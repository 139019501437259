<template>
  <CommonModal ref="root">
    <div class="activity-modal-recommend-song">
      <img @click="handleCloseModal" class="activity-modal-recommend-song-close" src="https://qncweb.ktvsky.com/20230628/vadd/4dd152dda18dd2e94c957d7dc8eef978.png">
      <p class="activity-modal-recommend-song-title">专属推荐歌曲</p>
      <div class="activity-modal-recommend-song-list">
        <div class="activity-modal-recommend-song-list-item" v-for="(item, index) in songList" :key="index" @click="handleOrder(item)">
          <p class="activity-modal-recommend-song-list-item-name">{{ item.music_name }}</p>
          <div>
            <span class="activity-modal-recommend-song-list-item-singer">{{ item.singer }}</span>
            <span v-if="item.is_vip" class="activity-modal-recommend-song-list-item-vip">VIP</span>
          </div>
        </div>
      </div>
      <div class="activity-modal-recommend-song-button" @click="handleAdd">
        我都要唱
      </div>
      <div class="activity-modal-recommend-song-hint" @click="handlecheck">
        <img v-if="setRecommendSongRule" src="https://qncweb.ktvsky.com/20230628/vadd/952f90dcd7fc1c9eea1c66e99092f341.png" alt="">
        <img v-else src="https://qncweb.ktvsky.com/20230628/vadd/48d432b44e2e8c1580079e1178a6247f.png" alt="">
        <span>7天内不再弹出</span>
      </div>
      <p class="activity-modal-recommend-song-bot">以上是您的专属推荐歌曲，尽情欢唱吧</p>
    </div>
  </CommonModal>
</template>
<script>
import { computed, onMounted, ref, toRefs } from 'vue'
import store from '@/store'
import CommonModal from '@/components/modal/common/component.vue'
// import useSongItem from '@/composables/useSongItem'
// import useVip from '@/composables/useVip'
import useVipQrcode from '@/components/modal/global/vip-qrcode/create.js'
import { sendLog } from '@/directives/v-log/log'
import store2 from 'store2'
import get from 'lodash/get'
import { Toast } from 'vant'
import { addDays, format } from 'date-fns'
import { nanoid } from 'nanoid'
// import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'ActivityRecommendSongModal',
  components: {
    CommonModal,
  },
  props: {
    closeEvent: {
      type: Function,
      default: () => {
      }
    },
    songList: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const root = ref(null)
    const $useVipQrcode = useVipQrcode()
    const appStartTime = computed(() => store.state.appStartTime)
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const vipInfo = computed(() => store.state.vipInfo)

    const setRecommendSongRule = ref(false) // 数据后期有时间再优化
    //  const { orderSong } = useSongItem()
    // const { showVipQrcode } = useVip()
    const { songList } = toRefs(props)

    const isVipUser = computed(() => {
      if (!isLogin.value) return false
      if (vipInfo.value.end_time) {
        const vipEndDate = new Date(vipInfo.value.end_time).getTime()
        const now = Date.now()
        return now <= vipEndDate
      }
      return false
    })

    onMounted(() => {
      const currDate = format(Date.now(), 'yyyy-MM-dd')
      store2('recommendSongRule', {
        showTime: currDate,
        noShowTime: null
      })
    })

    const handleCloseModal = () => {
      // const now = Date.now()
      props.closeEvent.call()
      sendLog({
        event_type: 'click',
        event_name: 3002,
        event_data: {
          str1: '1'
        }
      })
      root.value.hide()
    }

    const showVipQrcode = (obj) => {
      let songData = obj.songid ? {
        song_id: obj.songid
      } : {}
      sendLog({
        event_type: 'show',
        event_name: 256,
        event_data: {
          source: get(obj, 'src', ''), // 新增来源场景字段
          ...songData,
        }
      })
      $useVipQrcode.show({ ...obj })
      // store.dispatch('startCheckLoginStatus')
    }

    const handleOrder = (songItem) => {
      const isShowVipModal = songItem.is_vip && !isVipUser.value
      sendLog({
        event_type: 'click',
        event_name: 3003,
        event_data: {
          str1: songItem.music_name,
          str2: String(songItem.songid),
          str3: String(songItem.singer),
          str4: songItem.is_vip === 1 ? '1' : '2',
          str5: isShowVipModal ? '1' : '2'
        }
      })

      // 这块和统一的核心点歌逻辑有冲突 这里先单独处理 后期优化
      if (isShowVipModal) {
        showVipQrcode({ songid: songItem.songid })
        // showVipQrcode({ songid, src: 'vip_song_click' })
        return
      }
      console.log('app: order: song', songItem)
      Toast({
        message: '歌曲已加入已点列表',
        position: 'center',
        className: 'toast-zoom-max'
      })
      store.commit('PUSH_SONG_TO_ORDERED_LIST', { song: songItem })
      sendLog({
        event_type: 'click',
        event_name: 3005,
        event_data: {
          str1: songItem.music_name,
          str2: String(songItem.songid),
          str3: String(songItem.singer),
          str4: songItem.is_vip === 1 ? '1' : '2',
          str5: '2'
        }
      })
    }

    const handleAdd = () => {
      console.log(songList.value)
      const is_vip = songList.value.some((val) => val.is_vip)
      const isShowVipModal = is_vip && !isVipUser.value
      // let logStrObj = {
      //   str1: '',
      //   str2: '',
      //   str3: '',
      // }
      // for(let song of songList.value) {
      //   logStrObj = {
      //     str1: logStrObj.str1 === '' ? song.music_name : `,${song.music_name}`,
      //     str2: logStrObj.str1 === '' ? String(songItem.songid) : `,${song.songid}`,
      //     str3: logStrObj.str1 === '' ? String(songItem.singer) : `,${song.singer}`,
      //   }
      // }
      // sendLog({
      //   event_type: 'click',
      //   event_name: 3004,
      //   event_data: {
      //     str1: logStrObj.str1,
      //     str2: logStrObj.str2,
      //     str3: logStrObj.str3,
      //     str4: songItem.is_vip === 1 ? '1' : '2',
      //     str5: isShowVipModal ? '1' : '2'
      //   }
      // })

      const nanoLog = nanoid(32).toUpperCase()
      for(let songItem of songList.value) {
        sendLog({
          event_type: 'click',
          event_name: 3004,
          event_data: {
            str1: songItem.music_name,
            str2: String(songItem.songid),
            str3: String(songItem.singer),
            str4: songItem.is_vip === 1 ? '1' : '2',
            str5: isShowVipModal ? '1' : '2',
            str6: nanoLog
          }
        })
      }

      if (isShowVipModal) {
        showVipQrcode({})
        // showVipQrcode({ songid, src: 'vip_song_click' })
        return
      }
      Toast({
        message: '歌曲已加入已点列表',
        position: 'center',
        className: 'toast-zoom-max'
      })
      for(let songItem of songList.value) {
        store.commit('PUSH_SONG_TO_ORDERED_LIST', { song: songItem })
        sendLog({
          event_type: 'click',
          event_name: 3005,
          event_data: {
            str1: songItem.music_name,
            str2: String(songItem.songid),
            str3: String(songItem.singer),
            str4: songItem.is_vip === 1 ? '1' : '2',
            str5: '1',
            str6: nanoLog
          }
        })
      }
    }

    // 数据处理后期记得抽象
    const handlecheck = () => {
      sendLog({
        event_type: 'click',
        event_name: 3006,
        event_data: {
          str1: setRecommendSongRule.value ? '2' : '1'
        }
      })
      const currDate = format(Date.now(), 'yyyy-MM-dd')
      if (setRecommendSongRule.value) {
        store2('recommendSongRule', {
          showTime: currDate,
          noShowTime: null
        })
        setRecommendSongRule.value = false
        return
      }
      const endDate = addDays(new Date(), 6)
      store2('recommendSongRule', {
        showTime: currDate,
        noShowTime: format(endDate, 'yyyy-MM-dd')
      })
      setRecommendSongRule.value = true

      // const currDate = new Date()
      // let currRule = store2('recommendSongRule')
      // console.log(currRule)
      // if (!!currRule && differenceInDays(currDate, parseISO(currRule)) < 7) {
         
      // }
    }

    return {
      root,
      setRecommendSongRule,
      handleCloseModal,
      handleAdd,
      handlecheck,
      handleOrder,
    }
  }
}
</script>
<style lang="stylus" scoped>
.activity-modal-recommend-song
  padding 52px 90px 0 90px
  position relative
  width 1060px
  height 730px
  border-radius 20px
  background rgba(30, 31, 33, 1)
  color rgba(255, 255, 255, .8)
  display flex
  flex-direction column
  align-items center
  &-close
    width 90px
    height 90px
    position absolute
    top 30px
    left 30px
  &-title
    height 46px
    line-height 46px
    font-size 40px
    margin-bottom 52px
  &-list
    width 880px
    height 261px
    margin-bottom 61px
    display flex
    flex-wrap wrap
    &-item
      width 391px
      height 67px
      margin-right 98px
      margin-bottom 30px
      &:nth-child(2n)
        margin-right 0
      &-name
        font-size 28px
        margin-bottom 10px
        height 32px
        line-height 32px
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
      &-singer
        font-size 22px
        color rgba(255, 255, 255, .4)
      &-vip
        color #DBAE6A
        font-size 22px
        margin-left 10px
  &-button
    width 300px
    height 90px
    border-radius 10px
    opacity 0.800000011920929
    background #FFF
    display flex
    align-items center
    justify-content center
    color #000
    font-size 32px
  &-hint
    width 226px
    height 32px
    margin-top 30px
    color rgba(255, 255, 255, 0.40)
    font-size 28px
    line-height 32px
    img
      width 30px
      height 30px
      margin-right 10px
      float left
  &-bot
    width 100%
    text-align center
    color rgba(255, 255, 255, 0.40)
    font-size 24px
    position absolute
    bottom 38px
    left 0
</style>
