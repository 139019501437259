<template>
  <div class="already-song-item">
    <div class="info" :class="{ ordered: isOrdered }" @click="handleOrder">
      <p>
        <span class="name">{{ songItem.music_name }}</span>
      </p>
      <p>
        <template v-if="songItem.singer">
          <span
            class="singer"
            :class="singerCanClick && 'clickable'"
            @click="handleClickSinger"
          >{{ songItem.singer }}</span>
          <!-- <span class="divide"></span> -->
        </template>
        <!-- <span class="singer">{{ songItem.singer }}</span> -->
        <!-- <span class="divide"></span> -->
        <!-- <span class="flag">{{ songItem.flag && songItem.flag.toString() }}</span> -->
        <img v-if="songItem.is_vip" class="song-block-vip" src="https://qncweb.ktvsky.com/20231206/vadd/56dc0bc6477bf2c7a6c4fcdc8360804e.png" alt="">
      </p>
    </div>
    <div class="control">
        <svg @click.stop="handlePlay('order')" width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.9" clip-path="url(#clip0_31_907)">
            <circle cx="25.7222" cy="14.2403" r="9.27787" stroke="white" style="stroke:white;stroke-opacity:1;" stroke-width="2"/>
            <path d="M17.1572 16.9525L5.44516 31.8946L8.67518 35.1246L23.6173 23.4126" stroke="white" style="stroke:white;stroke-opacity:1;" stroke-width="2"/>
          </g>
          <defs>
            <clipPath id="clip0_31_907">
              <rect width="40" height="40" fill="white" style="fill:white;fill-opacity:1;"/>
            </clipPath>
          </defs>
        </svg>
      <img @click="handleStickSongToTop" src="https://qncweb.ktvsky.com/20231208/vadd/74853feba54ba0556dcf4148d1b78bdf.png"/>
      <img @click="handleDelete" src="https://qncweb.ktvsky.com/20231208/vadd/55bd912a711c13ebe9bf8b369d72c1fa.png"/>
    </div>
  </div>
</template>
<script>
import { toRefs, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { sendLog } from '@/directives/v-log/log'
import split from 'lodash/split'
import useOrder from "@/composables/useOrder";

export default {
  name: 'AlreadySongItem',
  props: {
    index: Number,
    songItem: {
      type: Object,
      default() {
        return {
          acc: '1',
          org: '2',
          flag: [],
          m3u8: {
            480: '',
            720: '',
            1080: '',
          },
          music_name: '',
          played: 0,
          singer: '',
          songid: 0,
        }
      }
    },
    isLandscape: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const { songItem, index, isLandscape } = toRefs(props)
    const orderedSongIdMap = inject('orderedSongIdMap')
    const store = useStore()
    const isOrdered = computed(() => orderedSongIdMap.value[songItem.value.songid])
    const singerCanClick = computed(() => (!store.state.mvIsHide && isLandscape.value) || store.state.mvIsHide)
    const { orderSong: immediatePlay, orderedList } = useOrder()
    const orderedListNumber = computed(() => orderedList.value.length)

    const handleDelete = () => {
      emit('delete', index.value)
    }

    const handleStickSongToTop = () => {
      emit('stick-top', songItem.value, index.value)
    }

    const handleOrder = () => {
      emit(
        'order',
        songItem.value,
        index.value,
        {
          isNotSingImmediately: true,
        }
      )
    }

    const handleClickSinger = (e) => {
      if (!singerCanClick.value) return
      e.stopPropagation();
      sendLog({
        event_type: '10000~50000',
        event_name: 10109,
        event_data: {
          str1: '任意点歌页',
          str2: '歌曲列表',
          str3: '点击任意歌手',
          str4: 'click',
        },
      })
      emit('singer-click', {
        singer: split(songItem.value.singer, ',')[0],
        singerhead: songItem.value.singer_head,
        singerid: songItem.value.singerid,
      })
    }

    const handlePlay = async (type) => {
      await Promise.all([
        emit(
          'order',
          songItem.value,
          index.value,
          {
            isNotSingImmediately: false,
          }
        )
      ])
      if (type === 'order') {
        sendLog({
          event_type: '10000~50000',
          event_name: 30239,
          event_data: {
            str1: '通用',
            str2: '立即演唱',
            str3: '点击',
            str4: 'click',
          },
        })
        immediatePlay(songItem.value, orderedListNumber.value - 1)
      }
    }

    return {
      isOrdered,
      singerCanClick,
      handleDelete,
      handleStickSongToTop,
      handleOrder,
      handleClickSinger,
      handlePlay,
    }
  },
}
</script>
<style lang="stylus" scoped>
.already-song-item
  padding 30px 0
  width 100%
  height 137px
  border-bottom 1px solid rgba(255, 255, 255, 0.08)
  display flex
  justify-content space-between
  @media screen and (max-width 1200px)
    height 130px
    padding 30px 20px
  &.playing
    .info
      color rgba(219, 174, 106, 1)
      .divide
        background rgba(219, 174, 106, 1)
      .name
        color rgba(219, 174, 106, 1)
  .info
    display flex
    flex-direction column
    color rgba(255, 255, 255, .4)
    font-size 28px
    flex 1
    .name
      margin 0
      color rgba(255, 255, 255, .8)
      white-space nowrap
      overflow hidden
      text-overflow ellipsis
      max-width 690px
      @media screen and (max-width 1200px)
        max-width 620px
    .vip
      display flex
      justify-content center
      align-items center
      width 64px
      height 36px
      font-size 22px
      border 3px solid #F0D290
      color #F0D290
      border-radius 8px
      margin-left 16px
    .divide
      width 2px
      height 30px
      margin 0 16px
      background rgba(255, 255, 255, .2)
    p
      &:nth-child(1)
        height 38px
        line-height 38px
        display flex
        align-items center
        font-size 32px
        margin-bottom 10px
        img
          width 32px
          height 32px
          margin-left 16px
      &:nth-child(2)
        height 28px
        line-height 38px
        display flex
        align-items center
    .clickable
      position relative
      padding-right 26px
      &::after
        content ""
        position absolute
        right 0
        top 50%
        margin-top -10px
        width 22px
        height 22px
        background url('https://qncweb.ktvsky.com/20231212/vadd/70dbe52816b882ae1f6871b3a509f375.png') no-repeat
        background-size 100% 100%
    .song-block-vip
      width 50px
      height 26px
      margin-left 13px
    @media screen and (max-width 1200px)
      p
        &:nth-child(1)
          font-size 26px
        &:nth-child(2)
          font-size 20px
  .ordered
    .name, .singer, .divide, .flag
      color rgba(219, 174, 106, 1) !important
    .divide
      background rgba(219, 174, 106, 1) !important
    .clickable::after
      content ""
      background-image url('https://qncweb.ktvsky.com/20231212/vadd/4a5a96b4a7e0b0ae7f364679f5f69417.png')
  .control
    display flex
    align-items center
    img
      width 40px
      height 40px
      margin-left 72px
</style>