<template>
  <CommonModal ref="root">
    <div class="user-collect-modal">
      <div class="user-collect-modal-title">我的收藏</div>
      <div
        class="user-collect-modal-list"
        v-if="collectList.length"
      >
        <SongItem
          v-for="(songItem, index) in collectList"
          :key="index"
          :index="index"
          :songItem="songItem"
          @order="handleOrder"
          @stickTop="handleStickTop"
          @collect="handleLiked"
          @uncollect="handleUnLiked"
        ></SongItem>
      </div>
      <div class="user-collect-modal-empty" v-else>
        <p v-if="isLogin">你还没有收藏歌曲</p>
        <p v-else>您还未登录，请您登录后查看</p>
      </div>
    </div>
  </CommonModal>
</template>
<script>
import { ref, computed } from 'vue'
import store from '@/store'
import CommonModal from '@/components/modal/common/component.vue'
import SongItem from './item.vue'
import useUserCollect from '@/composables/useCollect';

export default {
  name: 'UserCollectModal',
  components: {
    CommonModal,
    SongItem,
  },
  props: {
    // collectList: {
    //   type: Array,
    //   default: () => [],
    // }
  },
  setup() {
    const root = ref(null)
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const collectList = computed(() => store.state.collect.collectList)

    const close = () => {
      root.value.hide()
    }

    const { orderSong, stickSongToTop, handleCollect, handleUnCollect } = useUserCollect()

    const handleOrder = (songItem) => {
      orderSong(songItem)
    }

    const handleStickTop = (songItem) => {
      stickSongToTop(songItem)
    }

    const handleLiked = (songItem) => {
      handleCollect(songItem)
    }

    const handleUnLiked = (songItem) => {
      handleUnCollect(songItem)
    }

    return {
      root,
      isLogin,
      collectList,
      handleOrder,
      handleStickTop,
      handleLiked,
      handleUnLiked,
      close,
    }
  }
}
</script>
<style lang="stylus" scoped>
.user-collect-modal
  width 1200px
  height 800px
  position relative
  padding-top 50px
  background #1e1f21
  border-radius 20px
  color rgba(255, 255, 255, .8)
  display flex
  flex-direction column
  align-items center
  &-title
    width 100%
    text-align left
    font-size 40px
    padding-left 80px
    margin-bottom 53px
    line-height 1
    color rgba(255, 255, 255, 0.8)
  &-list
    width 1140px
    height 650px
    overflow-x hidden
    overflow-y scroll
    &::-webkit-scrollbar
      width 8px
    &::-webkit-scrollbar-thumb
      width 8px
      border-radius 4px
      background #303030
    &::-webkit-scrollbar-track
      background #1e1f21
  &-empty
    padding-top 200px
    p
      color rgba(255, 255, 255, 0.5)
      font-size 28px
      text-align center
</style>
