// header 进入欢唱tips云控显示
const state = {
  isShowSingTips: false,
  canShow: true, // 进入应用使用期间，只在加入已点第一次达到云控歌曲数量时去显示（且未演唱）
}

const getters = {}

const actions = {
  updateIsShowSingTips({ commit }, data) {
    commit('UPDATE_ISSHOW_SINGTIPS', data)
  },
}

const mutations = {
  UPDATE_ISSHOW_SINGTIPS(state, data) {
    state.isShowSingTips = data
    if (!data) {
      state.canShow = false // 显示过就禁止不会再显示
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
