import httpV2 from '@/utils/httpV2'
import get from 'lodash/get'

// 开屏弹窗运营活动
export async function getActivityInfo() {
  const res = await httpV2.get('/stb/v2/operation')
  return get(res, 'data.openscreen_vip', {});
}

/**
 * 活动中心页
 */
export async function getActCenterList() {
  const res = await httpV2.get('/stb/v2/task_center/list')
  return {
    data: get(res, 'data.list', []),
    online: get(res, 'data.online', 0),
  };
}