import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import useNewSongModal from '@/components/modal/global/newsong-modal/create'
import { sendLog } from '@/directives/v-log/log'

export default function useNewSong() {
  const store = useStore()
  
  const isLogin = computed(() => !!store.state.userInfo.unionid)

  const $newSongModal = useNewSongModal()
  const newSongModalInstance = ref(null)

  const showNewSongModal = (payload) => {
    if (newSongModalInstance.value) {
      return
    }
    newSongModalInstance.value = $newSongModal.show({
      ...payload,
      onHide: () => {
        newSongModalInstance.value = null
      },
      cancelEvent: () => {
        typeof payload.cancelEvent === 'function' && payload.cancelEvent()
        newSongModalInstance.value = null
      }
    })
  }

  const handleIsLoginChange = (val) => {
    if (val && newSongModalInstance.value) {
      newSongModalInstance.value.hide()
      sendLog({
        event_type: '10000~50000',
        event_name: 30201,
        event_data: {
          str1: '首页',
          str2: '新歌榜',
          str3: '点歌弹窗扫码',
          str5: '未登录',
          str6: 1,
        },
      })
    }
  }

  watch(isLogin, handleIsLoginChange)

  return {
    showNewSongModal,
  }
}