<template>
  <Notify ref="root" :active="active">
    <div class="notify-item">
      <span class="notify-item-title">{{ title }}</span>
      <div class="notify-item-btn" v-if="buttonText" @click="handleButtonClick">{{ buttonText }}</div>
    </div>
  </Notify>
</template>

<script>
import { onMounted, ref, toRefs } from 'vue'
import Notify from './base.vue'

export default {
  name: 'SingNotify',
  components: {
    Notify,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    duration: {
      type: Number,
      default: 6000
    }
  },
  setup(props, { emit }) {
    const root = ref(null)
    const { duration } = toRefs(props)
    let active = ref(true)

    const closeNotify = () => {
      root.value.hide()
    }

    const handleButtonClick = () => {
      emit('submit')
      closeNotify()
    }

    onMounted(() => {
      setTimeout(() => {
        closeNotify()
      }, duration.value)
    })

    return {
      root,
      active,
      handleButtonClick,
    }
  }
}
</script>

<style lang="stylus" scoped>
.notify-item
  width 670px
  height 80px
  display flex
  align-items center
  justify-content space-around
  padding-left 30px
  &-title
    color #000000
    font-size 28px
  &-btn
    width 160px
    height 60px
    font-size 24px
    color #F0D290
    margin-right 10px
    background #1E1F21
    text-align center
    line-height 60px
    border-radius 10px
</style>