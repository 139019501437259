
import { getMiniDefaultList } from '@/service/playlist'

const state = {
  defaultList: [],
  defaultSong: {},
}

const getters = {}

const actions = {
  async fetchDefaultList({ commit }) {
    const { data_list = [] } = await getMiniDefaultList();
    await commit('SET_DEFAULT_LIST', data_list);
    commit('SET_DEFAULT_SONG', data_list[0])
  },
  setCurrentDefaultSong({ commit }, songData) {
    commit('SET_DEFAULT_SONG', songData);
  },
}

const mutations = {
  SET_DEFAULT_LIST(state, defaultList) {
    state.defaultList = defaultList
  },
  SET_DEFAULT_SONG(state, defaultSong) {
    state.defaultSong = defaultSong;
    console.log('SET_DEFAULT_SONG', defaultSong)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
