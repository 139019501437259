import UserCollectModalComponent from './index.vue'
import useUserCollectModal from './create.js';

const Plugin = (app, props = {}, slots = {}) => {
  const userCollectModal = useUserCollectModal(props, slots);
  app.config.globalProperties.$userCollectModal = userCollectModal;
  app.provide('$userCollectModal', userCollectModal)
};

UserCollectModalComponent.install = Plugin;

export default UserCollectModalComponent;
