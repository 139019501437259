import { Toast } from 'vant'
// 统一toast引用 方便升级重构 - 强制顶部显示
export default function toast(val) {
  if (!val) return
  if (typeof val === 'string') {
    Toast({
      message: val,
      position: 'top',
      duration: 2000,
      className: 'toast-defalut'
    })
    return
  }
  Toast({
    duration: 2000, // 默认显示时间
    ...val,
    className: `${val.className || ''} toast-defalut`,
    position: 'top',
  })
}