import Listener from './listener';

export default class Show {
    listenerQueue = [];

    options = { throttleTime: 0, primaryKey: '' };

    constructor({ throttleTime, primaryKey } = {}) {
      this.options = {
        throttleTime: throttleTime || 200,
        // 主键, 方便去重
        primaryKey,
      };
    }

    add(el, value) {
      const curItem = this.listenerQueue.find((item) => item.el === el);

      if (curItem) {
        curItem.update(el, value);
        return;
      }

      const newListener = new Listener({
        el,
        value,
      });

      this.listenerQueue.push(newListener);
    }

    update(el, value, oldValue) {
      if (!this.listenerQueue.length) {
        return;
      }
      if (this.options.primaryKey && value[this.options.primaryKey] === oldValue[this.options.primaryKey]) {
        return;
      }

      const curItem = this.listenerQueue.find((item) => item.el === el);

      if (curItem) {
        curItem.update(el, value);
      }
    }

    remove(el) {
      const curItemIndex = this.listenerQueue.findIndex((item) => item.el === el);

      if (curItemIndex > -1) {
        const spliceArr = this.listenerQueue.splice(curItemIndex, 1);
        spliceArr[0].destroy();
      }
    }
}
