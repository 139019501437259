<template>
  <div :class="className">
    <img :class="`${className}-cover`" :src="singerHead" v-img-fallback="imgFallback" />
    <p>{{ singerName }}</p>
  </div>
</template>
<script>
import { computed, toRefs } from 'vue'

export default {
  props: {
    singer: {
      type: Object,
      default() {
        return {
          musiccount: 0,
          singerheader: '',
          singerhead: '',
          singerid: 0,
          singerjp: '',
          singer: '',
          singername: '',
          singerqp: '',
          singertypename: '',
        }
      }
    },
    className: {
      type: String,
      default: 'singer-item'
    }
  },
  name: 'SingerItem',
  setup(props) {
    const { singer } = toRefs(props)

    const imgFallback = {
      loading: 'https://qncweb.ktvsky.com/20231226/vadd/a3056a76c9626aa4d68318625ae87a35.png',
      error: 'https://qncweb.ktvsky.com/20231226/vadd/a3056a76c9626aa4d68318625ae87a35.png'
    }

    const singerHead = computed(() => singer.value.singerheader || singer.value.singerhead)
    const singerName = computed(() => singer.value.singername || singer.value.singer)

    return {
      singerName,
      singerHead,
      imgFallback,
    }
  },
}
</script>
<style lang="stylus" scoped>
.singer-item
  width 216px
  margin-bottom 60px
  &-cover
    width 216px
    height 216px
    border-radius 50%
    margin-bottom 20px
  p
    width 216px
    font-size 28px
    color rgba(255, 255, 255, 0.8)
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
    text-align center
  @media screen and (max-width 1200px) and (min-height 1200px)
    width 200px
    p
      width 200px
      font-size 22px
    .singer-item-cover
      width 200px
      height 200px
</style>