<template>
  <CommonModal ref="root">
    <div class="activity-modal-ksong">
      <div @click="handleCloseModal" class="activity-modal-ksong-close"></div>
      <div class="activity-modal-ksong-code">
        <img src="https://qncweb.ktvsky.com/20240102/other/8faaf764e5da73d4ead43b27aedf66ac.png" />
      </div>
    </div>
  </CommonModal>
</template>
<script>
import { ref, onBeforeMount } from 'vue'
import CommonModal from '@/components/modal/common/component.vue'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'ActivityMicModal',
  components: {
    CommonModal,
  },
  props: {
    closeEvent: {
      type: Function,
      default: () => {
      }
    },
  },
  setup() {
    const root = ref(null)

    const handleCloseModal = () => {
      root.value.hide()
    }

    onBeforeMount(() => {
      sendLog({
        event_type: '10000~50000',
        event_name: 30117,
        event_data: {
          str1: 'k歌人生运营位',
          str2: '入口弹窗',
          str3: '弹窗展示',
          str4: 'show',
        },
      })
    })

    return {
      root,
      handleCloseModal,
    }
  }
}
</script>
<style lang="stylus" scoped>
.activity-modal-ksong
  position relative
  width 800px
  height 540px
  background url(https://qncweb.ktvsky.com/20240102/other/48847a454388ff766cb17e53e61c0da9.png) no-repeat
  background-size 100% 100%
  background-position center
  border-radius 20px
  color rgba(255, 255, 255, .8)
  display flex
  flex-direction column
  align-items center
  &-code
    width: 184px;
    height: 184px;
    border-radius: 16px;
    background: #FFF;
    margin 179px auto 0
    img
      width: 174px;
      height: 174px;
      margin 5px
  &-close
    width 90px
    height 90px
    position absolute
    top 24px
    right 24px
</style>
