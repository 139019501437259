import httpV2 from '@/utils/httpV2'
import get from 'lodash/get'

// 活动可领取时间、任务状态、已完成得打卡天数、打卡周期内剩余可打卡时间、已完成30天打卡剩余提交时间

// 获取0元购任务状态
// {
//   "errcode": 200,
//   "errmsg": "服务正常",
//   "data": {
//   "15": {
//   "state": 4, 任务状态
//   "pkg_id": 15, 套餐id
//   "update_time": "2023-08-21 13:25:39",
//   "sign_count": 3, 签到次数
//   "remaining_days": 25, 参与活动剩余签到天数
//   "submit_limit": 0 提交剩余天数
//   }
//   }
//   }
export async function getActZeroBuyStatus({ unionid }) {
  const res = await httpV2.get(`/stb/v2/user/task_sign_status?unionid=${unionid}`)
  return get(res, 'data', {});
}

// 0元购任务签到状态 - sign_daily - 1 已签到 2 漏签 3未签到
export async function getZeroBuySigninStatus({ unionid }) {
  const res = await httpV2.get(`/stb/v2/user/task_sign_log?unionid=${unionid}&pkg_id=15`)
  // , {
  //   params: {
  //     unionid,
  //   }
  return get(res, 'data', {});
}

// 0元购任务签到 - 领取任务后播放mv就自动签到
export async function actZeroBuySignin({ unionid }) {
  const res = await httpV2.post(`/stb/v2/user/task_sign_log?unionid=${unionid}&pkg_id=15`)
  // , {
  //   params: {
  //     unionid,
  //   }
  return get(res, 'errmsg', '');
}

// 0元购任务 - 完成任务提交
export async function actZeroBuySubmit({ unionid }) {
  const res = await httpV2.post(`/stb/v2/user/task_sign_status?unionid=${unionid}&pkg_id=15`)
  return get(res, 'data', {});
}

// 0元购任务签到状态 - sign_daily - 1 已签到 2 漏签 3未签到
export async function updateZeroBuyIsPay({ unionid }) {
  const res = await httpV2.get(`/stb/v2/user/task_sign_order?unionid=${unionid}`)
  return get(res, 'data.返利任务', {});
}

// TASK_START = 0  # 未领取
// TASK_START = 1  # 已领取
// TASK_SUCCESS = 2  # 已完成
// TASK_FAILD = 3  # 已失败 签到中断
// TASK_CHECK = 4  # 审核中 提交审核
// TASK_CHECK_END = 5  # 审核/退款 成功
// TASK_END = 6 # 已结束 超过15天结束
// TASK_GET_TIMEOUT= 7 # 领取超时 5天
// TASK_SUBMIT_FAILD = 8 # 提交失败
// TASK_REFUNDING = 9  # 退款中