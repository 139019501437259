import { computed } from 'vue'
// import { useStore } from 'vuex'
import store from '@/store'
import useSongItem from './useSongItem'
import useOrder from './useOrder'
import { setOrderedList } from '@/utils/historyCache'
import { Toast } from 'vant'
import useUserCollectModal from '@/components/modal/global/user-collect/create'
import useVipQrcode from '@/components/modal/global/vip-qrcode/create.js'
import { postSongCollect, deleteSongCollect } from '@/service/collect'
import get from 'lodash/get'
import { sendLog } from '@/directives/v-log/log'

export default function useUserCollect() {
  // const store = useStore()

  // const $userCollectModal = inject('$userCollectModal')

  // const userCollectInstance = ref(null)
  const unionid = computed(() => store.state.userInfo.unionid)
  const collectList = computed(() => store.state.collect.collectList)

  const showUserCollectModal = async () => {
    if (unionid.value) {
      store.dispatch('collect/getUserCollectList', unionid.value)
    }
    const $useUserCollect = useUserCollectModal()
    $useUserCollect.show()
    // userCollectInstance.value = $userCollectModal.show()
  }

  const { orderSong: normalOrderSong } = useSongItem()
  const { stickSongToTop: stickSongToOrderedTop, orderedListNumber } = useOrder()
  const $useVipQrcode = useVipQrcode()
  const isLogin = computed(() => !!store.state.userInfo.unionid)
  const vipInfo = computed(() => store.state.vipInfo)
  
  const isVipUser = computed(() => {
    if (!isLogin.value) return false
    if (vipInfo.value.end_time) {
      const vipEndDate = new Date(vipInfo.value.end_time).getTime()
      const now = Date.now()
      return now <= vipEndDate
    }
    return false
  })

  const showVipQrcode = (obj = {}) => {
    let songData = obj.songid ? {
      song_id: obj.songid
    } : {}
    sendLog({
      event_type: 'show',
      event_name: 256,
      event_data: {
        source: get(obj, 'src', ''), // 新增来源场景字段
        ...songData,
      }
    })
    $useVipQrcode.show({ ...obj })
    // store.dispatch('startCheckLoginStatus')
  }

  // 收藏列表点歌
  const orderSong = (songItem) => {
    const isShowVipModal = songItem.is_vip && !isVipUser.value
    if (isShowVipModal) {
      showVipQrcode({ songid: songItem.songid })
      return
    }
    let isImmediate = false
    if (orderedListNumber.value === 0) {
      isImmediate = true
    }
    normalOrderSong(songItem, {
      immediate: isImmediate,
      from: {
        song_list_source: 8
      }
    })
    setOrderedList()
  }

  // 置顶
  const stickSongToTop = (songItem) => {
    const isShowVipModal = songItem.is_vip && !isVipUser.value
    if (isShowVipModal) {
      showVipQrcode({ songid: songItem.songid })
      return
    }
    orderSong(songItem)
    stickSongToOrderedTop()
  }

  const handleCollect = async (songItem) => {
    if (!unionid.value) {
      showVipQrcode()
      return
    }
    const res = await postSongCollect({
      unionid: unionid.value,
      songid: songItem.songid,
    })
    if (res && res.errcode === 200) {
      Toast({
        message: '歌曲已加入收藏',
        position: 'center',
        className: 'toast-zoom-max'
      })
      const collect = collectList.value.map((v) => {
        if (v.songid !== songItem.songid) return v
        return {
          ...v,
          noCollect: false
        }
      })
      console.log("handleCollect", collect)
      store.dispatch('collect/updateCollectList', collect)
    } else {
      Toast({
        message: '收藏失败',
        position: 'center',
        className: 'toast-zoom-max'
      })
    }
  }

  const handleUnCollect = async (songItem) => {
    if (!unionid.value) {
      showVipQrcode()
      return
    }
    const res = await deleteSongCollect({
      unionid: unionid.value,
      songid: songItem.songid,
    })
    if (res.errcode === 0) {
      Toast({
        message: '歌曲已取消收藏',
        position: 'center',
        className: 'toast-zoom-max'
      }) // res.errmsg
      const collect = collectList.value.map((v) => {
        if (v.songid !== songItem.songid) return v
        return {
          ...v,
          noCollect: true
        }
      })
      console.log("handleUnCollect", collect)
      store.dispatch('collect/updateCollectList', collect)
    } else {
      Toast({
        message: '取消收藏失败',
        position: 'center',
        className: 'toast-zoom-max'
      })
    }
  }


  return {
    showUserCollectModal,
    orderSong,
    stickSongToTop,
    handleCollect,
    handleUnCollect,
  }
}