import { computed } from 'vue'
import { useStore } from 'vuex'
import get from 'lodash/get'
import useVip from './useVip'
import useSetting from './useSetting'
import { useShareNetwork } from './sharedComposable'
import Toast from '@/utils/toast'
import { differenceInMilliseconds } from 'date-fns'
import { getSongM3U8, getSongToken, getSongLrc } from '@/service/song'
import { sendLog } from '@/directives/v-log/log'

export default function useM3u8() {
  const store = useStore()
  const { showVipQrcode } = useVip()
  const { setting } = useSetting()
  const { isOnline } = useShareNetwork()
  const lowerBeforeQ = computed(() => store.state.lowerBeforeQ)
  const oldServerTime = computed(
    () => store.state.videoPlayerHistory.songItem.serverTime
  )
  const tokenExp = computed(
    () => store.state.videoPlayerHistory.songItem.tokenExp
  )
  const currPlaySong = computed(() => store.state.videoPlayerHistory.songItem)
  // 从接口m3u8字段中解析出存在值的m3u8 - 优先展示全局画质之下的高画质
  const getDefaultQualityM3U8 = (m3u8) => {
    // 保存当下无资源的画质档位
    if (setting.value.quality === '1080') {
      store.commit('UPDATE_LOWERBEFOREQ', '1080')
    }
    const qualitySupportList = ['480', '720', '1080']
    const existQuality = qualitySupportList.find((qualitySupport) => {
      return get(m3u8, qualitySupport, '')
    })
    if (setting.value.quality === '1080' && get(m3u8, '720', '')) {
      return get(m3u8, '720', '')
    }
    return get(m3u8, existQuality, '')
  }
  // 校验用户播放歌曲权限
  const checkM3u8Auth = (songData, is_vip, error) => {
    // 歌曲是vip歌曲时，双重验证，再次通过后端验证用户登录状态以及vip并提示
    if (!songData.m3u8) {
      // 这里有处坑 - 后端返的is_user_login、is_user_vip两个字段只在m3u8无数据时有效
      if (is_vip && (!songData.is_user_login || !songData.is_user_vip)) {
        // showVipQrcode()
      } else {
        sendLog({
          event_type: 'click',
          event_name: 255,
          event_data: {
            msg: songData.msg,
            song_id: currPlaySong.value.songid,
            song_name: currPlaySong.value.music_name,
            singer: currPlaySong.value.singer
          }
        })
        if (isOnline.value && !error) {
          Toast('资源上架中，暂时无法播放')
        } else {
          Toast('点歌失败，请您检查网络后重试')
        }
      }
      // 暂时做hide处理的优化
      if(!store.state.mvIsHide) store.commit('UPDATE_MV_ISHIDE', true)
    }
  }
  // 添加当前歌曲的m3u8资源
  const addCurrSongM3u8 = async (song) => {
    const songLrc = await getSongLrc(song.songid, song.lrc)

    if (song.isAIMV) {
      store.commit('UPDATE_CURR_SONG_LRC', {
        lrc: get(songLrc, 'lrc.json', []),
      })
      if (!song.hls) {
        store.commit('CHANGE_PLAYING_MV_QUALITY_RESOURCE', get(song, 'm3u8.480', ''))
      }
      return
    }
    const songData = await getSongM3U8(song.songid)
    let m3u8Data = {
      is_user_login: songData.is_user_login,
      is_user_vip: songData.is_user_vip,
      m3u8: get(songData, 'm3u8', {}),
      token: get(songData, 'token', ''),
      tokenExp: get(songData, 'tokenExp', 5*60),
      serverTime: get(songData, 'serverTime', 0),
      msg: songData.msg
    }

    m3u8Data.m3u8 = get(m3u8Data.m3u8, lowerBeforeQ.value ? lowerBeforeQ.value : setting.value.quality, '') || getDefaultQualityM3U8(m3u8Data.m3u8)
    if (m3u8Data.m3u8) { // 为空时不进行数据更新
      store.commit(
        'UPDATE_CURR_SONG_HLS',
        {
          hls: m3u8Data.m3u8,
          token: m3u8Data.token,
          tokenExp: m3u8Data.tokenExp,
          m3u8: get(songData, 'm3u8', {}),
          serverTime: m3u8Data.serverTime,
          lrc: {
            down_url: get(songData, 'lrc.down_url', ''),
            is_utf8: get(songData, 'lrc.is_utf8', 1),
          },
        }
      )
      store.commit('UPDATE_CURR_SONG_LRC', {
        lrc: get(songLrc, 'lrc.json', []),
      })
    }
    checkM3u8Auth(m3u8Data, song.is_vip, get(songData, 'error', undefined))

    // console.log(m3u8Data)
  }

  const setCurrSongToken = async () => {
    const songData = await getSongToken()
    const currServerTime = get(songData, 'serverTime', 0)
    // 与exp对比前后服务器时间的差值
    const ts = differenceInMilliseconds(new Date(currServerTime), new Date(oldServerTime.value)) < 1000 * tokenExp.value
    // console.log(
    //   new Date(currServerTime),
    //   new Date(oldServerTime.value),
    //   differenceInMilliseconds(new Date(currServerTime), new Date(oldServerTime.value)),
    //   ts
    // )
    store.commit(
      'UPDATE_CURR_SONG_TOKEN',
      {
        token: get(songData, 'token', ''),
        tokenExp: get(songData, 'tokenExp', 5*60),
        serverTime: currServerTime
      }
    )
    return ts
  }

  return {
    addCurrSongM3u8,
    setCurrSongToken,
  }
}
