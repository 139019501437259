import { computed, ref } from 'vue'
// import { useStore } from 'vuex'
import store from '@/store'
import Toast from '@/utils/toast'
import { get } from 'lodash'
import useLoginValid from './useLoginValid'
import useVip from './useVip'
import useQuality from './useQuality'
import useNewSong from './useNewSong'
import useActivity from './useActivity'
import { setOrderedList, setAlreadyList, setFreeSongNoTimeShow, getFreeSongNoTimeShow } from '@/utils/historyCache'
import { sendLog } from '@/directives/v-log/log'
import eventBus from '@/utils/event-bus'
import { vipLogFrom } from '@/constants/index'

export default function useSongItem() {
  // const store = useStore()
  let FNTInstance = ref(null)
  const { showLoginQrcode, isLogin } = useLoginValid()
  const { showVipQrcode, isVipUser } = useVip()
  const { show1080pModal } = useQuality()
  const { showNewSongModal } = useNewSong()
  const { showActivityFreeSongNoTime } = useActivity()

  const setting = computed(() => store.state.setting)
  const qualitySupportList = ['480', '720']
  const orderedListNumber = computed(() => store.state.orderedList.length)
  const userType = computed(() => store.state.userInfo.userType)
  const freeVipNumber =computed(() => Number(store.state.freeVipNumber))
  const orderedListVipNumber = computed(() => store.state.orderedList.filter(v => v.is_vip == 1).length)

  const actLogData = {
    30152: ['歌手详情页', '歌曲列表'],
    30153: ['搜索页', '歌曲列表'],
    30154: ['通用', '常唱列表'],
    30156: ['通用', '猜你会唱'],
    30157: ['首页', '歌单'],
    30159: ['通用', '已唱'],
    30200: ['首页', '新歌榜']
  }

  // 从接口m3u8字段中解析出存在值的m3u8
  const getDefaultQualityM3U8 = (m3u8) => {
    const existQuality = qualitySupportList.find((qualitySupport) => {
      return get(m3u8, qualitySupport, '')
    })
    return get(m3u8, existQuality, '')
  }

  // 获取m3u8地址
  const getMediaSoruce = (song) => {
    // 具有m3u8地址 or {} or {string: number}
    let m3u8 = get(song, 'm3u8', {})
    if (typeof m3u8 === 'object') {
      m3u8 = get(m3u8, setting.value.quality, '') || getDefaultQualityM3U8(m3u8)
    }
    return m3u8
  }

  const validSong = (song, _option={}) => {
    const { hls, is_vip, songid } = song
    // 改为播放歌曲时再即时的调取歌曲资源 这里不做判断了
    // if (!hls ) {
    //   Toast('点歌失败，请检查网络后重试')
    //   return false
    // }

    if (get(_option, 'from.fr1', '')) {
      _option.fr = get(_option, 'from.fr1', '')
    }
    if (get(_option, 'from.fr2', '')) {
      _option.fr2 = get(_option, 'from.fr2', '')
    }
    //new valid start
    if (is_vip) {
      if (isLogin.value) {
        if (!isVipUser.value && !freeVipNumber.value) {
          showVipQrcode({
            fromType: 'vip-expired',
            isLogin: true,
            fr: _option.fr || 1873,
            onHide: () => {
              if (isLogin.value && isVipUser.value) {
                orderSong(song, _option);
              }
            }
          });
          return false;
        }
      } else {
        // showLoginQrcode({
        //   log: _option.log,
        //   onHide: () => {
        //     if (isLogin.value && isVipUser.value) {
        //       orderSong(song, _option);
        //     }
        //   }
        // });

        showVipQrcode({
          fromType: 'vip-expired',
          isLogin: false,
          fr: 1756,
          log: _option.fr2 || '',
          onHide: () => {
            if (isLogin.value && isVipUser.value) {
              orderSong(song, _option);
            }
          }
        });
        return false;
      }
    }
    //new valid end

    if (!isVipUser.value && is_vip) {
      store.dispatch('vipAddSong/addSong', song)
    }

    // log数据 - 传递log打点到移动端支付服务
    let logData = {}
    let actLogName = ''
    switch (get(_option, 'from.song_list_source', '')) {
      case 2:
      case 10:
        logData.log = '搜索-会员歌曲'
        actLogName = 30153
        break;
      case 3:
        logData.log = '歌单-歌单名称'
        actLogName = 30157
        if (get(_option, 'from.song_list_id', '') === '“特”别定制，会员专享歌单') {
          logData.log = '特别定制'
        }
        break;
      case 4:
        logData.log = '已唱列表'
        actLogName = 30159
        break;
      case 5:
      case 11:
        logData.logInfo = {
          event_name: 30156,
          event_data: {
            str1: '通用',
            str2: '猜你会唱',
            str3: '会员歌曲弹窗展示',
            str4: 'show',
            str5: isLogin.value ? '已登录' : '未登录',
            str6: userType.value,
          },
        }
        logData.log = '常唱列表'
        actLogName = 30154
        break;
      case 6:
      case 12:
        logData.log = '歌星-会员歌曲'
        actLogName = 30152
        break;
      case 9:
        logData.log = '歌名-会员歌曲'
        actLogName = 30156
        break;
      case 13:
        logData.log = '首页-画质歌单-会员歌曲弹窗'
        actLogName = 30157
        break;
      case 15:
        logData.log = '首页-新歌歌单-vip权益弹窗'
        actLogName = 30200
        break;
      case 1000:
        logData.log = '就唱这首'
        break;
      case 1001:
        logData.log = '首页右上角小喇叭运营位'
        break;
      default:
        break;
    }
    const is1080p = _option.isValid1080p
    if (is1080p) {
      sendLog({
        event_type: '10000~50000',
        event_name: 30186,
        event_data: {
          str1: '首页',
          str2: '画质歌单',
          str3: '歌曲点击',
          str4: 'click',
          str5: isLogin.value ? '已登录' : '未登录',
        },
      })
      if (!isLogin.value || !isVipUser.value) {
        show1080pModal({
          songid,
          cancelEvent: () => {
            orderSong(song, {
              ..._option,
              isValid1080p: false,
              from: {
                song_list_source: 13
              }
            })
            if (isLogin.value && !isVipUser.value && is_vip) {
              sendLog({
                event_type: '10000~50000',
                event_name: 30185,
                event_data: {
                  str1: '首页',
                  str2: '画质歌单',
                  str3: '会员歌曲弹窗展示',
                  str4: 'show',
                  str5: '已登录',
                  str6: userType.value,
                },
              })
            }
          }
        })
        return false
      }
    }
    const isNewSong = _option.isValidNewSong
    if (isNewSong) {
      sendLog({
        event_type: '10000~50000',
        event_name: 30202,
        event_data: {
          str1: '新歌榜',
          str2: '歌曲列表',
          str3: '歌曲点击',
          str4: 'show',
          // str5: isLogin.value ? '已登录' : '未登录',
        },
      })
      if (!isLogin.value) { //  || !isVipUser.value
        showNewSongModal({
          songid,
          cancelEvent: () => {
            // orderSong(song, {
            //   ..._option,
            //   isValidNewSong: false,
            //   from: {
            //     song_list_source: 15
            //   }
            // })
            if (isLogin.value && !isVipUser.value && is_vip) {
              sendLog({
                event_type: '10000~50000',
                event_name: 30201,
                event_data: {
                  str1: '首页',
                  str2: '新歌榜',
                  str3: '点歌弹窗扫码',
                  // str6: userType.value,
                },
              })
            }
          }
        })
        return false
      }
    }

    // vip歌曲
    if (is_vip) {
      if ((isLogin.value && !isVipUser.value)) {
        // if (freeVipNumber.value && orderedListVipNumber.value > 3) {
        //   Toast('您的已点vip歌曲数量超过4首，请先去已点列表中取消后再点歌')
        //   return
        // }
        if (freeVipNumber.value) {
          // eventBus.emit('ordered-list-vip-number')
          return true
        }else{
          console.log('210showVipQrcode');
          // 弹窗
          showVipQrcode({
            songid,
            ...logData,
            isLogin: isLogin.value,
            fr: vipLogFrom.get(logData.log ? logData.log : '其他'),
          })
          return false
        }
      }
      if (!isLogin.value) {
        showLoginQrcode({
          songid,
          ...logData,
        })
        if (actLogName) { 
          sendLog({
            event_type: '10000~50000',
            event_name: actLogName,
            event_data: {
              str1: actLogData[actLogName][0],
              str2: actLogData[actLogName][1],
              str3: '会员歌曲弹窗展示',
              str4: 'show',
              str5: '未登录',
              str6: 1,
            },
          })
        }
        return false
      }
      if (!isVipUser.value) {
        showVipQrcode({
          songid,
          ...logData,
          isLogin: isLogin.value,
          fr: vipLogFrom.get(logData.log ? logData.log : '其他'),
        })
        if (actLogName) {
          sendLog({
            event_type: '10000~50000',
            event_name: actLogName,
            event_data: {
              str1: actLogData[actLogName][0],
              str2: actLogData[actLogName][1],
              str3: '会员歌曲弹窗展示',
              str4: 'show',
              str5: '已登录',
              str6: userType.value,
            },
          })
        }
        return false
      }
    }
    return true
  }

  /**
   * 延时加入已点
   * 【特殊场景：当用户点歌，未登录或不是vip时，记录当前点的歌曲，
   * 轮询检测当前用户是否开通了vip，开通后把刚才点的歌曲加入已点】
   */
  const delayAddSong = (song, option) => {
    let t = 0
    const timer = setInterval(() => {
      console.log('轮询次数：', t)
      if (t > 5 * 60) {
        console.log('已轮询到最大次数！关闭轮询！')
        clearInterval(timer)
        return
      }
      // 查看当前用户是否是vip
      if (isVipUser.value) {
        console.log('已是vip用户！关闭轮询！')
        clearInterval(timer)
        // 将之前不是vip时点击的歌曲加入到已点列表
        return orderSong(song, option) // 参数记得处理
      }
      t++
    }, 1000)
  }

  const orderOption = {
    position: '',
    isPushOrdered: true,
    immediate: true,
    from: {}, // 点歌来源 - 打点使用
    ponitActionLog: {}, // 点歌的点位信息 - 10000 ~ 50000 打点使用
    enabledMvShow: false, // 启用MV页，默认关闭 - 当开启时自动打开mv页；immediate同为true时才生效
    useFirstSongAutoPlay: false, // 点播的第一首歌曲是否启用自动播放，默认关闭
    isValid1080p: false,
    isNotSingImmediately: true, // is sing immediately
    beforePushOrdered: () => {
      console.log('beforePushOrdered')
    },
    beforeImmediate: () => {
      console.log('beforeImmediate')
    },
    afterOrder: () => {
      console.log('afterOrder')
    },
    afterSelfOrder: (
      _song,
      _option,
    ) => {
      console.log('afterOrder')
      setOrderedList()
      setAlreadyList()

      const vipNoTimeShow = getFreeSongNoTimeShow()
      if (!vipNoTimeShow && _song.is_vip && !isVipUser.value) {
        // 不是立即演唱
        console.log('isNotSingImmediately:', _option.isNotSingImmediately)
        // console.log(_option)
        // 这块等调回之前的点歌流逻辑后释放
        // if (!_option.enabledMvShow && !_option.useFirstSongAutoPlay.value) {
        //   if (freeVipNumber.value === 3) {
        //     FNTInstance.value = showActivityFreeSongNoTime()
        //     setFreeSongNoTimeShow(true)
        //   }
        // }
        // 需求逻辑变更 - 不再这里处理了
        // if (_option.isNotSingImmediately) {
        //   if (freeVipNumber.value === 1) {
        //     FNTInstance.value = showActivityFreeSongNoTime()
        //     setFreeSongNoTimeShow(true)
        //   }
        // }
      }
    }
  }

  const orderSong = (song, option) => {
    const _song = {...song}
    const _option = {...orderOption, ...option}
    // _song.hls = getMediaSoruce(_song) // 不在这里获取了
    console.log('media source: ', _song.hls)
    if (!validSong(_song, _option)) {
      // 此功能暂不上线
      // delayAddSong(song, option)
      return
    }
    console.log('app: order: song', _song, _option)
    if (_option.isPushOrdered) {
      _option.beforePushOrdered.call()
      // VEC-873
      // orderedListNumber.value !== 0
      Toast({
        message: '歌曲已加入已点列表',
        position: 'center',
        className: 'toast-zoom-max'
      })
      store.commit('PUSH_SONG_TO_ORDERED_LIST', { song: _song, from: _option.from })
      // 10000~50000 的数据上报
      if (get(_option, 'ponitActionLog.event_type') === '10000~50000') {
        sendLog(_option.ponitActionLog)
      }
    }
    if (_option.immediate) {
      _option.beforeImmediate.call()
      store.commit('SAVE_VIDEO_PLAYER_HISTORY_SONG_ITEM', _song)
      if (_option.position !== 'recovery') {
        setTimeout(() => {
          store.commit('UPDATE_VIDEO_PLAYER_RECOVERY', true)
        }, 200)
      }
      // 需求策略调整 - 点播第一首时不进入mv页，且不立即播放；进入mv后才开始播放
      if(store.state.mvIsHide && _option.enabledMvShow) store.commit('UPDATE_MV_ISHIDE', false)
    }
    _option.afterOrder.call(this)
    _option.afterSelfOrder.call(this, _song, _option)

    // 特殊的业务逻辑 - 点播的第一首歌不直接播放
    if (_option.useFirstSongAutoPlay) {
      eventBus.emit('handle-video-play')
    }
    // else {
    //   if (orderedListNumber.value === 1) {
    //     setTimeout(() => {
    //       eventBus.emit('handle-video-pause')
    //     }, 500)
    //   }
    // }
  }

  return {
    validSong,
    orderSong,
  }
}
