<template>
  <div class="mobile-order" v-if="isShowQRcode">
    <div class="mobile-order-modal">
      <div class="qrcode">
        <img :src="qrCodeURL" />
      </div>
      <div class="title" @click="handleClose">
        微信扫码点歌
        <img
          src="https://qncweb.ktvsky.com/20231027/vadd/34a27f5bf5e20eb04915dd081edf29b0.png"
        />
      </div>
    </div>
  </div>
  <div class="mobile-order-icon" v-else>
    <img
      @click="handleOpen"
      src="https://qncweb.ktvsky.com/20231027/vadd/bd2b5666419511daafaae33bc88518b8.png"
    />
  </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue'
import { getBaseInfo } from '@/service/base'
import useQRCode from '@/composables/useQRCode'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'MobileOrderModal',
  setup() {
    const { getQRCodeURL } = useQRCode();
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    );
    let isShowQRcode = ref(true)

    const init = async () => {
      const { data } = await getBaseInfo();
      const url = get(data, 'phone_control_qr', '');
      if (url) {
        const qrCodeData = await getQRCodeURL(url);
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData;
        }
        return;
      }
      Toast('未获取到手机点歌二维码');
    };

    const handleClose = () => {
      isShowQRcode.value = false
      sendLog({
        event_type: '10000~50000',
        event_name: 10068,
        event_data: {
          str1: '欢唱页',
          str2: '手机点歌',
          str3: '收起二维码',
          str4: 'click',
        },
      })
    };

    const handleOpen = () => {
      init()
      isShowQRcode.value = true
      sendLog({
        event_type: '10000~50000',
        event_name: 10069,
        event_data: {
          str1: '欢唱页',
          str2: '手机点歌',
          str3: '展开二维码',
          str4: 'click',
        },
      })
    }

    onBeforeMount(init);

    return {
      qrCodeURL,
      isShowQRcode,
      handleOpen,
      handleClose,
    };
  },
}
</script>

<style lang="stylus" scoped>
.mobile-order
  position absolute
  top 30px
  right 30px
  z-index 110
  &-modal
    width 226px
    height 276px
    border-radius 14px
    background rgba(30, 31, 33, 0.8)
    display flex
    flex-direction column
    align-items center
    justify-content center
    .qrcode
      width 186px
      height 186px
      display flex
      justify-content center
      align-items center
      background #ffffff
      border-radius 10px
      img
        width 166px
        height 166px
    .title
      width 186px
      height 30px
      display flex
      align-items center
      justify-content space-between
      color rgba(255, 255, 255, 0.80)
      font-size 24px
      margin-top 21px
      img
        width 30px
        height 30px
  @media screen and (max-width 1200px) and (min-height 1200px)
    top 196px
    right 24px
.mobile-order-icon
  width 156px
  height 130px
  position absolute
  top 40px
  right 0
  z-index 11
  img
    width 100%
    height 100%
  @media screen and (max-width 1200px) and (min-height 1200px)
    top 196px
</style>
