import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useForceLoginModal } from '@/components/modal/global/login-qrcode/create'
import { sendLog } from '@/directives/v-log/log'

export default function useForceLogin() {
  const store = useStore()
  const $loginQrcode = useForceLoginModal()
  const loginQrcodeInstance = ref(null)

  const isLogin = computed(() => !!store.state.userInfo.unionid)
  const isForceLogin = computed(() => store.state.isForceLogin);

  const showForceLogin = () => {
    if (loginQrcodeInstance.value) {
      loginQrcodeInstance.value.hide()
      loginQrcodeInstance.value = null
    }
    if (isForceLogin.value && !isLogin.value) {
      loginQrcodeInstance.value = $loginQrcode.show()
      store.dispatch('startCheckLoginStatus')
    }
  }

  watch(isLogin, (val) => {
    if (val && loginQrcodeInstance.value) {
      loginQrcodeInstance.value.hide()
      sendLog({
        event_type: '10000~50000',
        event_name: 10002,
        event_data: {
          str1: '首页',
          str2: '强制登录',
          str3: '登录成功',
        },
      })
    }
  })

  watch(isForceLogin, (val) => {
    if (!val && loginQrcodeInstance.value) {
      loginQrcodeInstance.value.hide()
    }
  })

  return {
    isLogin,
    showForceLogin,
  }
}