<template>
  <div
    ref="root"
    class="loadmore"
    :style="{
      height: calcHeight,
    }"
    @scroll="handleScroll"
  >
    <slot name="default"></slot>
    <div ref="triggerTarget" class="loadmore-trigger"></div>
  </div>
</template>
<script>
import {
  ref,
  onBeforeUnmount,
  onMounted,
  computed,
  toRefs,
  nextTick,
} from "vue";
// import { useRoute } from 'vue-router'
// import store from '@/store'
import _ from "lodash";

export default {
  name: "LoadMore",
  props: {
    safeAreaHeight: {
      type: String,
      default: "13.6991vw",
    },
  },
  emits: ["watch-scroll"],
  setup(props, { emit }) {
    // const route = useRoute()
    const { safeAreaHeight } = toRefs(props);

    let root = ref(null);
    let triggerTarget = ref(null);
    let observer = ref({});

    const calcHeight = computed(() => {
      return `calc(100vh - ${safeAreaHeight.value})`;
    });

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    const init = () => {
      options.root = root.value;
      observer.value = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log("load-more");
            emit("load-more");
          }
        });
      }, options);
      observer.value.observe(triggerTarget.value);
    };

    const handleScroll = _.debounce((e) => {
      emit("watch-scroll", e.target.scrollTop);
      // store.commit(
      //   'UPDATE_PAGE_CACHEDATA',
      //   {
      //     data: {
      //       position: e.target.scrollTop || 0
      //     },
      //     type: route.name
      //   }
      // )
    }, 1000);

    const destory = () => {
      observer.value = null;
      root.value.removeEventListener("scroll", handleScroll);
    };

    onMounted(async () => {
      await nextTick();
      root.value.addEventListener("scroll", handleScroll);
      init();
    });

    onBeforeUnmount(destory);

    return {
      root,
      triggerTarget,
      calcHeight,
      handleScroll,
    };
  },
};
</script>
<style lang="stylus" scoped>
#triggerTarget
  height 10px
.loadmore
  overflow-y: scroll
  padding-bottom 110px!important
  &::-webkit-scrollbar
    width 8px
  &::-webkit-scrollbar-thumb
    width 8px
    border-radius: 4px
    background: #303030
  &::-webkit-scrollbar-track
    background: #000000
  &-trigger
    height 10px
</style>
