export default {
  beforeMount(el, binding) {
    const options = binding.value
    const originSrc = el.src

    let loading = ''
    let error = ''

    const img = new Image()

    if (typeof options === 'string') {
      loading = options
      error = options
    }

    if (typeof options === 'object') {
      loading = options.loading
      error = options.error
    }
    
    img.src = originSrc
    el.src = loading

    img.onload = () => {
      el.src = originSrc
    }
    img.onerror = () => {
      el.src = error
    }
  }
}