import SingNotifyComponent from './index.vue'
import useSingNotify from './create.js';

const Plugin = (app, props = {}, slots = {}) => {
  const singNotify = useSingNotify(props, slots);
  app.config.globalProperties.$singNotify = singNotify;
  app.provide('$singNotify', singNotify)
};

SingNotifyComponent.install = Plugin;

export default SingNotifyComponent;
