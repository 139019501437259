<template>
  <transition ref="root" :name="transition">
    <!-- tabindex="0" 暂时去掉 -->
    <div
      class="thunder-modal-overlay is-active"
      :class="[{ 'is-full-page': isFullPage, 'is-reset-style': isResetStyle }, className]"
      v-show="isActive"
      :aria-busy="isActive"
      aria-label="Loading"
      :style="{ zIndex }"
    >
      <div class="thunder-modal-background"
        @click.prevent="cancel"
        :style="bgStyle"
      ></div>
      <div class="thunder-modal-icon" @click.stop="stopEvent">
        <slot name="default">
        </slot>
        
      </div>
    </div>
  </transition>
</template>

<script>
import { render } from 'vue'; 
import { MayBeHTMLElement, removeElement } from '@/components/modal/utils/index.js';

export default {
  name: 'ModalGlobalCommon',
  props: {
    active: Boolean,
    programmatic: Boolean,
    container: [Object, Function, MayBeHTMLElement],
    isFullPage: {
      type: Boolean,
      default: true
    },
    enforceFocus: {
      type: Boolean,
      default: true
    },
    lockScroll: {
      type: Boolean,
      default: true
    },
    transition: {
      type: String,
      default: 'fade'
    },
    /**
     * Allow user to hide the loader
     * default setting true， allow user to cancel
     */
    canCancel: {
      type: Boolean,
      default: true
    },
    /**
     * Do something on cancel
     */
    onCancel: {
      type: Function,
      default: () => {
      }
    },
    color: String,
    backgroundColor: String,
    // blur: {
    //   type: String,
    //   default: '2px'
    // },
    opacity: Number,
    width: Number,
    height: Number,
    zIndex: Number,
    loader: {
      type: String,
      default: 'QRcode'
    },
    isResetStyle: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: ''
    },
  },
  emits: ['hide', 'update:active'],
  data() {
    return {
      // Don't mutate the prop
      isActive: this.active
    }
  },
  methods: {
    /**
     * Proxy to hide() method.
     * Gets called by ESC button or when click outside
     */
    cancel() {
      if (!this.canCancel || !this.isActive) return;
      this.hide();
      this.onCancel.apply(null, arguments);
    },
    stopEvent() {
      console.log('阻止向上传播事件')
    },
    /**
     * Hide and destroy component if it's programmatic.
     */
    hide() {
      this.$emit('hide');
      this.$emit('update:active', false);

      if (this.programmatic) {
        this.isActive = false;
        // Timeout for the animation complete before destroying
        setTimeout(() => {
          if (this.$refs.root) {
            const parent = this.$refs.root.parentElement;
            // unmount the component
            render(null, parent);
            removeElement(parent)
          }
        }, 150)
      }
    },
    disableScroll() {
      if (this.isFullPage && this.lockScroll) {
        document.body.classList.add('thunder-modal-shown');
      }
    },
    enableScroll() {
      if (this.isFullPage && this.lockScroll) {
        document.body.classList.remove('thunder-modal-shown');
      }
    },
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    isActive(value) {
      if (value) {
        this.disableScroll();
      } else {
        this.enableScroll()
      }
    }
  },
  computed: {
    bgStyle() {
      return {
        background: this.backgroundColor,
        opacity: this.opacity,
        // backdropFilter: `blur(${this.blur})`
      }
    }
  },
}
</script>
<style lang="stylus" scoped>
.thunder-modal-shown
  overflow: hidden
.thunder-modal-overlay
  bottom: 0
  left: 0
  position: absolute
  right: 0
  top: 0
  align-items: center
  display: none
  justify-content: center;
  overflow: hidden
  z-index: 9999
.thunder-modal-overlay
  &.is-active
    display: flex

.thunder-modal-overlay
  &.is-full-page
    z-index: 9999
    position: fixed
  &.is-reset-style
    position initial

.thunder-modal-overlay, .thunder-modal-background
  bottom: 0
  left: 0
  position: absolute
  right: 0
  top: 0
  background rgba(0, 0, 0, 0.7)

.thunder-modal-overlay
  &.thunder-modal-icon, .thunder-modal-parent
    position: relative
</style>