import { getABTestTag } from '@/utils/historyCache';

const state = {
  abTestVersion: '',
}

const getters = {}

const actions = {
  setABTestVersion({ commit }) {
    const abTestVer = getABTestTag()
    commit('SAVE_ABTEST_VERSION', abTestVer)
  },
}

const mutations = {
  SAVE_ABTEST_VERSION(state, data) {
    state.abTestVersion = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
