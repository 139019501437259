<template>
  <ControlPopup ref="root">
    <template #control-popup-plugin>
      <div class="ai-order-song-control-panel">
        <div class="header">
          <div class="tab">
            <div
              class="tab-item"
              v-for="(tab, index) in tabList"
              :key="index"
              :class="{ actived: curTab.name === tab.name }"
              @click="handleChangeTab(tab)"
            >
              {{ tab.text }}
            </div>
          </div>
          <img
            src="https://qncweb.ktvsky.com/20231208/vadd/c66c6ee46a87137d8cde1ec7fa8c9718.png"
            @click="handleClose"
            alt=""
          />
        </div>
        <OrderSongList
          v-show="curTab.name === 'ordered'"
        ></OrderSongList>
      </div>
    </template>
  </ControlPopup>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import eventBus from '@/utils/event-bus'
import ControlPopup from '@/components/control-popup/index.vue'
import OrderSongList from '@/components/song-list/ai-order/index.vue'

const store = useStore()
const router = useRouter()

const aiFaceSong = computed(() => store.state.aiFaceSong.length)
const tabList = computed(() => {
  return [
    {
      name: 'ordered',
      text: `AI换脸(${aiFaceSong.value > 99 ? '99+' : aiFaceSong.value})`,
    },
  ]
})


let curTab = ref(tabList.value[0])

const handleChangeTab = (tab) => {
  curTab.value = tab
}

const root = ref(null)

const handleShow = () => {
  root.value.show()
}

const handleClose = () => {
  root.value.close()
}

const handleClickSinger = ({ singer, singerhead, singerid }) => {
  router.push({
    name: 'songList',
    query: {
      name: singer,
      image: singerhead,
      singerid: singerid,
    },
  })
  root.value.close()
}

onMounted(() => {
  eventBus.on('show-ai-order-song-control-popup', handleShow)
})

onBeforeUnmount(() => {
  eventBus.off('show-ai-order-song-control-popup', handleShow)
})
</script>
<style lang="stylus" scoped>
.ai-order-song-control-panel
  padding 30px
  width 1000px
  height 800px
  background #1E1F21
  // box-shadow 0px 20px 80px 0px rgba(0, 0, 0, 0.5)
  border-radius 14px
  padding 0 !important
  @media screen and (max-width 1200px)
    zoom 0.8
  .header
    width 100%
    height 101px
    border-bottom 2px solid rgba(255, 255, 255, 0.10)
    position relative
    margin-bottom 16px
    img
      width 40px
      height 40px
      position absolute
      top 30px
      right 30px
    .tab
      width 100%
      height 100px
      display flex
      align-items center
      justify-content center
      &-item
        display flex
        justify-content center
        align-items center
        width 173px
        height 100px
        font-size 32px
        color rgba(255, 255, 255, 0.40)
        &.actived
          color #dbae6a
          border-bottom 2px solid #DBAE6A
        &:nth-child(1)
          margin-right 0 !important
</style>
