// import http from '@/utils/http'
import httpV2 from '@/utils/httpV2'
import store from '@/store'
import get from 'lodash/get'

// 没有使用到此接口 不做变更 先注释掉
// export async function searchSong(k) {
//   if (!k) return [];
//   const result = await http.get('/stb/carplay/search/song', {
//     params: {
//       k,
//     }
//   })
//   return get(result, 'data', []);
// }

export async function search(k, p, mode) {
  // '/stb/carplay/search/key'
  if (!k) return []
  let m = {}
  if (mode) m.mode = mode
  const res = await httpV2.get('/stb/v2/search/key', {
    params: {
      k,
      p,
      size: '20',
      ...m,
      mac_id: store.state.macAddress,
    }
  })
  return {
    ...res,
    singer: get(res, 'data.singer', []),
    song: get(res, 'data.song', [])
  }
}

export async function searchSinger(singerid, p, size = 20) {
  // '/stb/carplay/detail/singer'
  if (!singerid) return [];
  const res = await httpV2.get('/stb/v2/detail/singer', {
    params: {
      singerid,
      p,
      size,
    }
  })
  return res;
}
