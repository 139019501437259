export function setUid(uid = '') {
  __bl &&
    __bl.setConfig({
      uid,
    });
}

export function setTag(tag) {
  __bl &&
    __bl.setConfig({
      tag,
    });
}