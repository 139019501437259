import { createComponent } from '@/components/modal/utils/index.js';
import RecommendVue from "./index.vue";

export default function useRecommendConfrim(globalProps = {}, globalSlots = {}) {

  return {
    show(props = globalProps, slots = globalSlots) {
      const forceProps = {
        programmatic: true,
        lockScroll: true,
        isFullPage: true,
        active: true,
      }

      const propsData = Object.assign({
        onConfirm: () => {},
        onCancel: () => {}
      }, globalProps, props, forceProps);
      const container = document.body;

      const mergedSlots = Object.assign({}, globalSlots, slots);
      const instance = createComponent(RecommendVue, propsData, container, mergedSlots);

      return {
        hide: instance.refs.root.hide
      }
    },
  }
}
