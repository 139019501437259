import { connect, StringCodec } from 'nats.ws/lib/src/mod';

export class NatsClient {

  nc = null
  sub = null
  sc = StringCodec()

  options = {
    servers: '',
    pingInterval: 15 * 1000,
    reconnectTimeWait: 3 * 1000,
    subs: '',
    token: '',
    messageHander: () => { }
  }

  constructor(options) {
    this.nc = null
    this.options = {
      ...this.options,
      ...options,
    };
  }

  async connect() {

    try {
      this.nc = await connect({
        servers: this.options.servers,
        token: this.options.token,
        pingInterval: this.options.pingInterval,
        reconnectTimeWait: this.options.reconnectTimeWait,
      });
      console.log('Connected to NATS server:', this.options.servers);
      this.subscribe()
      // this.subscribeAPI()
    } catch (error) {
      console.error('Error connecting to NATS server:', error);
      setTimeout(() => {
        console.log('Reconnecting...');
        this.connect();
      }, this.options.reconnectInterval);
    }
  }

  async request() {
    if (this.nc) {
      await this.nc.request('time', {}, { timeout: 1000 })
        .then((m) => {
          console.log(`got response: ${this.sc.decode(m.data)}`);
        })
        .catch((err) => {
          console.log(`problem with request: ${err.message}`);
        });
    }
  }

  async subscribeAPI() {
    if (this.nc) {
      const sub = this.nc.subscribe('list');
      const subj = sub.getSubject()
      console.log(`listening for ${subj}`);
      for await (const m of sub) {
        const subject = m.subject
        const data = m.data ? this.sc.decode(m.data) : ''
        console.log('nats', 'request', subject, data)
        // const resBody = this.options.messageHander(data)
        // console.log('nats', 'response', subject, resBody)
        // m.respond(this.sc.encode(JSON.stringify({ result: JSON.stringify(resBody), err: '' })))
      }
    }
  }

  async subscribe() {
    if (this.nc) {
      this.sub = this.nc.subscribe(this.options.subs);
      const subj = this.sub.getSubject()
      console.log(`listening for ${subj}`);
      for await (const m of this.sub) {
        const subject = m.subject
        const data = m.data ? this.sc.decode(m.data) : ''
        console.log('nats', 'request', subject, data)
        const resBody = this.options.messageHander(data)
        console.log('nats', 'response', subject, resBody)
        m.respond(this.sc.encode(JSON.stringify({ result: JSON.stringify(resBody), err: '' })))
      }
    }
  }

  async close() {
    if (this.nc) {
      const done = this.nc.closed();
      // do something with the connection

      // close the connection
      await this.nc.close();
      // check if the close was OK
      const err = await done;
      if (err) {
        console.log('nats error closing:', err);
        return
      }
      console.log('nats client', 'close')
    }
  }
}