<template>
  <div
    :style="{ top: layerTop + 'px', left: layerLeft + 'px' }"
    @touchstart="startDrag"
    @touchmove="doDrag"
    @touchend="endDrag"
    @click.stop="phoneSongShow"
    class="phone-song"
  >
    <img src="../../assets/images/phone-song-icon.png" class="icon" />
    <div class="label">手机点歌</div>
  </div>
</template>

<script>
import { computed, inject, ref, onUnmounted } from "vue";
import { useStore } from "vuex";

import { sendLog } from "@/directives/v-log/log";

export default {
  name: "index",
  props: {},
  setup() {
    const store = useStore();
    const isLogin = computed(() => !!store.state.userInfo.unionid);
    const $mobileOrderQrcodeModal = inject("$mobileOrderQrcodeModal");
    const phoneSongShow = () => {
      $mobileOrderQrcodeModal.show();
      sendLog({
        event_type: "10000~50000",
        event_name: 30241,
        event_data: {
          str1: "通用",
          str2: "手机点歌悬浮",
          str3: "点击",
          str4: "click",
          str5: isLogin.value ? "已登录" : "未登录",
        },
      });
    };
    const boxWidth = 120,
      boxHeight = 120;
    let windowWidth = window.innerWidth,
      windowHeight = window.innerHeight,
      rate = windowWidth / 1920;
    const layerTop = ref(578 * rate);
    const layerLeft = ref(0 * rate);
    const validPosition = (diffX) => {
      if (layerTop.value < 0) {
        layerTop.value = 0;
      } else if (layerTop.value > windowHeight - boxHeight) {
        layerTop.value = windowHeight - boxHeight;
      }
      layerLeft.value += diffX;
      if (layerLeft.value < 0) {
        layerLeft.value = 0;
      } else if (layerLeft.value > windowWidth - boxWidth) {
        layerLeft.value = windowWidth - boxWidth;
      }
    };
    const initValues = () => {
      windowWidth = window.innerWidth;
      windowHeight = window.innerHeight;
      rate = windowWidth / 1920;
      layerTop.value = 578 * rate;
      layerLeft.value = 0 * rate;
      validPosition(0);
    };
    initValues();
    let dragging = false,
      mouseX,
      mouseY;
    const startDrag = (e) => {
      dragging = true;
      mouseX = e.touches[0].clientX;
      mouseY = e.touches[0].clientY;
    };
    const doDrag = (e) => {
      if (dragging) {
        const diffX = e.touches[0].clientX - mouseX;
        const diffY = e.touches[0].clientY - mouseY;
        layerTop.value += diffY;
        validPosition(diffX);
        mouseX = e.touches[0].clientX;
        mouseY = e.touches[0].clientY;
      }
    };

    const endDrag = () => {
      dragging = false;
      // 磁吸效果
      const threshold = windowWidth / 2; // 设置磁吸的阈值为屏幕宽度的一半
      if (layerLeft.value < threshold) {
        layerLeft.value = 0;
      } else {
        layerLeft.value = windowWidth - boxWidth;
      }
    };

    window.addEventListener("resize", initValues);
    onUnmounted(() => {
      window.removeEventListener("resize", initValues);
    });

    return {
      phoneSongShow,
      layerTop,
      layerLeft,
      startDrag,
      doDrag,
      endDrag,
    };
  },
};
</script>

<style lang="stylus" scoped>
.phone-song
  width 120px
  height 120px
  background rgba(255, 255, 255, 0.2)
  backdrop-filter blur(50px)
  position fixed
  z-index 102
  display flex
  flex-direction column
  align-items center
  justify-content center
  border-radius 30px
  .icon
    width 44px
    height 44px
  .label
    font-size 20px
    margin-top 8px
    color #FFFFFF
</style>
