<template>
  <CommonModal ref="root">
    <div class="activity-modal-mic">
      <div @click="handleCloseModal" class="activity-modal-mic-close"></div>
    </div>
  </CommonModal>
</template>
<script>
import { computed, ref } from 'vue'
import store from '@/store'
import CommonModal from '@/components/modal/common/component.vue'
import { format } from 'date-fns'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'ActivityMicModal',
  components: {
    CommonModal,
  },
  props: {
    closeEvent: {
      type: Function,
      default: () => {
      }
    },
  },
  setup(props) {
    const root = ref(null)
    const appStartTime = computed(() => store.state.appStartTime)

    const handleCloseModal = () => {
      const now = Date.now()
      props.closeEvent.call()
      sendLog({
        event_type: 'show',
        event_name: 1723,
        event_data: {
          start_time: format(appStartTime.value, 'yyyy-MM-dd HH:mm:ss'),
          end_time: format(now, 'yyyy-MM-dd HH:mm:ss'),
          stay_time: Math.round((now - appStartTime.value) / 1000),
          key_words: '销售麦克风'
        }
      })
      root.value.hide()
    }

    return {
      root,
      handleCloseModal,
    }
  }
}
</script>
<style lang="stylus" scoped>
.activity-modal-mic
  padding 130px 80px 0
  position relative
  width 1200px
  height 730px
  background url(https://qncweb.ktvsky.com/20230612/vadd/361730642dd6787e1c4438c6e19c4875.png) no-repeat
  background-size 100% 100%
  background-position center
  border-radius 20px
  color rgba(255, 255, 255, .8)
  display flex
  flex-direction column
  align-items center
  &-close
    width 90px
    height 90px
    position absolute
    top 28px
    right 30px
</style>
