// import http from '@/utils/http'
import http from '@/utils/httpV2'
import store from '@/store'
import store2 from 'store2'
import { nanoid } from 'nanoid/non-secure'

export async function getCarplayInfo() {
  // /stb/carplay/info
  const res = await http.get('/stb/v2/info', {
    params: {
      session: store2('_boot_id'),
      mac_id: store.state.macAddress,
    }
  })
  return res;
}

export async function getAIface(unionid) {
  const res = await http.get(`/stb/v2/aiface?unionid=${unionid}`)
  return res;
}
