import { inject } from "vue";

export default function useRecommend() {
  const $RecommendConfirm = inject("$RecommendConfirm");

  let recommendInstance = null;

  const showRecommendConfirm = () => {
    if (recommendInstance) {
      hideRecommendConfirm();
    }
    recommendInstance = $RecommendConfirm.show();
  };

  const hideRecommendConfirm = () => {
    if (!recommendInstance) return;
    if (typeof recommendInstance.hide === "function") {
      recommendInstance.hide();
    }
  };

  return {
    showRecommendConfirm,
    hideRecommendConfirm,
  };
}
