<template>
  <CommonModal ref="root">
    <div class="loginconfirm-modal-content">
      <div class="content">{{ content }}</div>
      <div class="control">
        <div class="control-item confirm" @click="handleConfirm">确定</div>
        <div class="control-item" @click="handleCancel">取消</div>
      </div>
    </div>
  </CommonModal>
</template>
<script>
import { ref } from 'vue'
import CommonModal from '@/components/modal/common/component.vue'

export default {
  name: 'LoginModalConfirm',
  props: {
    content: {
      type: String,
      default: '请您前往登录'
    }
  },
  components: {
    CommonModal,
  },
  setup(props, { emit }) {
    const root = ref(null)

    const handleConfirm = () => {
      emit('confirm')
      handleCancel()
    }
    const handleCancel = () => {
      emit('cancel')
      root.value.hide()
    }

    return {
      root,
      handleConfirm,
      handleCancel,
    }
  }
}
</script>
<style lang="stylus" scoped>
.loginconfirm-modal
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  width 100vw
  height 100vh
  background rgba(0,0,0, 0.85)
  z-index 10
  display flex
  justify-content center
  align-items center
  &-content
    display flex
    flex-direction column
    align-items center
    padding 52px 80px
    position relative
    width: 800px;
    min-height: 296px;
    background: #1E1F21;
    box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.5)
    border-radius: 20px
    color rgba(255, 255, 255, 0.8)
    font-size 32px
    .content
      margin-bottom 50px
      text-align center
    .control
      display flex
      justify-content center
      &-item
        display flex
        justify-content center
        align-items center
        border-radius 14px
        background #383A3E
        color rgba(255, 255, 255, 0.8)
        width 300px
        height 90px
        &:first-child
          margin-right 40px
        &.confirm
          background rgba(255, 255, 255, 0.8)
          color #000000
</style>