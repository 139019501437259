<script lang="ts">
export default {
  name: 'VantStyle',
}
</script>

<style lang="stylus">
:root
  --van-dialog-header-padding-top: 52px !important
  --van-dialog-has-title-message-padding-top: 29px !important
  // --van-dialog-message-padding: 78px
  --van-dialog-background-color: rgba(30, 31, 33, 1)
  --van-background-color-light: rgba(30, 31, 33, 1)
  --van-dialog-font-size: 32px!important
  --van-dialog-has-title-message-text-color: rgba(255, 255, 255, .8) !important
  --van-dialog-width: 800px!important
  --van-dialog-header-font-weight: 400!important
  --van-dialog-message-font-size: 32px !important
  --van-dialog-message-line-height: 1.5!important
  --van-dialog-button-height: 60px!important
  --van-button-default-background-color: #D2D3D3!important
  --van-button-default-font-size: 32px!important
  --van-dialog-confirm-button-text-color: #1E1F21!important
  --van-dialog-header-line-height: 1!important
  --van-toast-background-color: rgba(56, 58, 62, 0.70) !important
  --van-toast-text-color: #FFF!important
  --van-toast-text-padding: 23.5px 48px!important
  --van-toast-font-size: 28px!important
  --van-toast-border-radius 10px !important
  --van-toast-position-top-distance 7.6% !important
.van-toast__text
  min-height 33px
  line-height 33px
.van-dialog__header
  color rgba(255, 255, 255, 0.5)
.van-hairline--top::after
  border-top-width: 0px!important
.van-hairline--left::after
  border-left-width: 0px!important
.van-dialog__footer
  margin-bottom: 60px
  justify-content: center
  flex-direction: row-reverse
.van-dialog__cancel,
.van-dialog__confirm
  flex inherit!important
  width 300px!important
  height 90px!important
  border-radius: 14px!important
.van-dialog__confirm
  &:not(:first-child)
    margin-right 40px!important
.van-dialog__cancel
  background #383A3E!important
  color rgba(255,255,255,0.8) !important
.van-dialog__message
  padding 60px 80px 50px 80px !important
// .van-dialog
  // padding-bottom 39px
.global-force-login
  color rgba(255, 255, 255, 0.8)
  border-radius: 8px!important
  background: #1E1F21!important
  font-size: 36px;
.van-dialog
  @media screen and (max-width 1200px)
    zoom 0.84
  &__message
    color rgba(255, 255, 255, 0.80)
    padding-top 60px!important
    padding-bottom 50px!important
  .van-button
    width 300px!important
    height 80px!important
    font-size: 28px!important
    border: 2px solid rgba(255, 255, 255, 0.10);
    background none!important
    border-radius 4px!important
  .van-button::before
    background none
  .van-dialog__confirm
    background: rgba(255, 255, 255, 0.10)!important;
    color #BE2D35
    border none!important
    backdrop-filter: blur(100px);
</style>
