const state = {
  orderListCutSongTag: 0,
}

const getters = {}

const actions = {
  updateCutSongTag({ commit }) {
    commit('UPDATE_ORDERLIST_CUTSONG_TAG')
  },
}

const mutations = {
  UPDATE_ORDERLIST_CUTSONG_TAG(state) {
    state.orderListCutSongTag += 1
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
