import httpV2 from '@/utils/httpV2'
import get from 'lodash/get'

export async function getSingsingList({ unionid }) {
  const res = await httpV2.get('/stb/v2/often-sing', {
    params: {
      unionid,
    }
  })
  // console.log(res)
  return get(res, 'data.data_list', []);
}

export async function getHotSongList({ p, size, unionid }) {
  const res = await httpV2.get('/stb/v2/often-and-hot/songlist', {
    params: {
      p,
      size,
      unionid,
      type: '热门歌曲，总有一首你会唱'
    }
  })
  return get(res, 'data', []);
}

export async function getTop50List() {
  const res = await httpV2.get('/stb/v2/top/100')
  // console.log(res)
  return get(res, 'data.data_list', []).filter((v,i) => i < 100);
}

// 常唱歌手
export async function getSingsingerList({ unionid }) {
  const res = await httpV2.get('/stb/v2/often-singer', {
    params: {
      unionid,
    }
  })
  // console.log(res)
  return get(res, 'data.data_list', []);
}
