// src/components/modal/global/retain-modal/create.js
import { createComponent } from '@/components/modal/utils/index.js'
import RetainModalVue from './index.vue'

export default function createRetainModal(globalProps = {}, globalSlots = {}) {
    // 将函数名改为 createRetainModal
    function show(Component, props = globalProps, slots = globalSlots) {
        const forceProps = {
            programmatic: true,
            lockScroll: true,
            isFullPage: true,
            active: true,
        }

        const propsData = { ...globalProps, ...props, ...forceProps }
        const container = document.body

        const mergedSlots = { ...globalSlots, ...slots }
        const instance = createComponent(
            Component,
            propsData,
            container,
            mergedSlots
        )

        return {
            hide: instance.refs.root.hide,
        }
    }
    return {
        show(props = globalProps, slots = globalSlots) {
            return show(RetainModalVue, props, slots)
        },
    }
}