import cloneDeep from 'lodash/cloneDeep'

export default class SourcePreloader {
  option = {
    // TODO
    concurrency: 2,
    // 暂时只支持图片src string
    sources: []
  }

  constructor(option) {
    this.option = {
      ...cloneDeep(option)
    }
    if (this.option.sources.length) {
      this.load(this.option.sources)
    }
  }

  load(sources) {
    sources.forEach(source => {
      const img = new Image()
      img.src = source
      img.onload = () => {
        console.log('source-preloader: ', source)
      }
      img.onerror = () => {
        console.log('source-preloader: failed: ', source)
      }
    })
  }
}