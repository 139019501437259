import {inject, h } from 'vue'
import LoadingIcon from '@/components/loading/icon.vue'

export default function useLoading() {
  const $loading = inject('$loading')

  let loadingInstance = null

  const showLoading = () => {
    if (loadingInstance) {
      hideLoading()
    }
    loadingInstance = $loading.show({
      color: '#747474',
      backgroundColor: 'rgba(255, 255, 255, 0)',
      opacity: 0,
      width: '3.64583vw',
      height: '3.64583vw'
    }, {
      default: h(LoadingIcon)
    })
  }

  const hideLoading = () => {
    if (!loadingInstance) return
    if (typeof loadingInstance.hide === 'function') {
      loadingInstance.hide()
    }
  }

  return {
    showLoading,
    hideLoading
  }
}