import { onMounted, onUnmounted, ref } from 'vue'

export default function useNetwork() {
  const navigator = window?.navigator
  // const isSupported = Boolean(navigator && 'connection' in navigator)
  const isOnline = ref(true)
  // const connection = isSupported && navigator.connection

  const updateNetworkInformation = () => {
    if (!navigator)
      return
    isOnline.value = navigator.onLine
    return navigator.onLine
  }

  const setOnline = () => {
    isOnline.value = true
  }

  const setNotOnline = () => {
    isOnline.value = false
  }

  function addEventListenerIsOnline() {
    if (window) {
      window.addEventListener('offline', setNotOnline) 
      window.addEventListener('online', setOnline)
    }
  }

  function removeEventListenerIsOnline() {
    if (window) {
      window.removeEventListener('offline', setNotOnline)
      window.removeEventListener('online', setOnline)
    }
  }

  const useNetworkEventListener = (type) => {
    if (type) {
      addEventListenerIsOnline()
    } else {
      removeEventListenerIsOnline()
    }
  }

  // 这样会重复挂载 先不这样用 后续优化
  // onMounted(() => {
  //   useNetworkEventListener(true)
  // })

  onUnmounted(() => {
    useNetworkEventListener(false)
  })
  
  updateNetworkInformation()
  useNetworkEventListener(true)

  return {
    isOnline,
    updateNetworkInformation,
    useNetworkEventListener,
  }
}