<template>
  <div class="top-menu">
    <div class="menu">
      <!-- 快速点歌 -->
      <div
        class="menu-item"
        @click.stop="handleShowSideBar('快速点歌', 'bottom', 10070)"
      >
        <img
          class="menu-item-icon"
          src="https://qncweb.ktvsky.com/20231102/vadd/fa015ce36c8013015815d1a708a5075b.png"
        />
        <div class="menu-item-text">快速点歌</div>
      </div>
      <!-- 调音 -->
      <div
        class="menu-item"
        @click.stop="handleShowSideBar('调音', 'bottom', 10072)"
      >
        <img
          class="menu-item-icon"
          src="https://qncweb.ktvsky.com/20231102/vadd/357c430db2fab353882222c49895314e.png"
        />
        <div class="menu-item-text">调音</div>
      </div>
      <!-- 画质 -->
      <div
        class="menu-item"
        @click.stop="handleShowSideBar('画质', 'bottom', 10076)"
      >
        <img
          class="menu-item-icon"
          src="https://qncweb.ktvsky.com/20231102/vadd/b22de2a431688df39c5c711b9334e7d2.png"
        />
        <div class="menu-item-text">画质</div>
      </div>
      <!-- 已点 -->
      <div
        class="menu-item"
        @click.stop="handleShowSideBar('已点', 'bottom', 10078)"
      >
        <img
          class="menu-item-icon"
          src="https://qncweb.ktvsky.com/20231102/vadd/7d585306f1b39d1653f421c1798b6d67.png"
        />
        <div class="menu-item-text">已点</div>
      </div>
      <!-- 个人中心 -->
      <div
        class="menu-item"
        @click.stop="handleShowSideBar('我的特权', 'bottom')"
      >
        <img class="menu-item-header" v-if="headerimg" :src="headerimg" />
        <img
          class="menu-item-header"
          v-else
          src="https://qncweb.ktvsky.com/20231227/vadd/2f8ab0593a6d30518fc2f1a64ba30f7d.png"
          alt=""
        />
        <div class="menu-item-text">我的</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'VideoPlayerTopMenu',
  props: {
    headerimg: {
      type: String,
      default: '',
    },
    audioTrackMap: {
      type: Object,
      default() {
        return {
          acc: {},
          org: {},
        }
      },
    },
    enabledAudioTrackId: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const handleSwitchAudioTrack = (audioTrack, pos) => {
      emit('switch-audio-track', audioTrack)

      // sendLog({
      //   event_type: '10000~50000',
      //   event_name: pos === 'left' ? 10074 : 10075,
      //   event_data: {
      //     str1: 'MV',
      //     str2: '播控',
      //     str3: `原唱${pos === 'left' ? '左' : '右'}`,
      //     str4: 'click',
      //   },
      // })
    }

    const handleShowSideBar = (name, pos, logname) => {
      emit('show-side-bar', { name, pos })

      // sendLog({
      //   event_type: '10000~50000',
      //   event_name: pos === 'left' ? logname : logname + 1,
      //   event_data: {
      //     str1: 'MV',
      //     str2: '播控',
      //     str3: `${name}${pos === 'left' ? '左' : '右'}`,
      //     str4: 'click',
      //   },
      // })
    }

    return {
      handleShowSideBar,
      handleSwitchAudioTrack,
    }
  },
}
</script>

<style lang="stylus" scoped>
.top-menu
  position absolute
  top 35px
  width 100%
  display flex
  align-items center
  justify-content center
  color #ffffff
  font-size 24px
  z-index 11
  display none
  height fit-content
  @media screen and (max-width 1200px) and (min-height 1200px)
    top auto
    bottom 100px
    display flex
  .menu
    width 850px
    height auto
    padding 30px 0px 16px
    display flex
    align-items center
    justify-content space-between
    &-item
      width 130px
      height 120px
      margin-bottom 10px
      display flex
      flex-direction column
      justify-content center
      align-items center
      &-icon
        width 80px
        height 80px
      &-header
        width 48px
        height 48px
        border-radius 50%
        margin-top 16px
        margin-bottom 16px
      &-text
        font-size 22px
        color rgba(255, 255, 255, 0.50)
        height 25px
        line-height 25px
</style>

