import VipQrcodeComponent from './index.vue'
import useVipQrcode from './create.js';

const Plugin = (app, props = {}, slots = {}) => {
  const vipQrcode = useVipQrcode(props, slots);
  app.config.globalProperties.$vipQrcode = vipQrcode;
  app.provide('$vipQrcode', vipQrcode)
};

VipQrcodeComponent.install = Plugin;

export default VipQrcodeComponent;
