<template>
  <div class="volume-item" :class="{'disabled': disabled}">
    <div class="v-slider">
      <p class="name">
        <slot>名称</slot>
      </p>
      <van-slider
        v-model="value"
        :disabled="disabled"
        @change="onChange"
        @update:model-value="onUpdate"
      >
        <template #button>
          <div class="custom-button"></div>
        </template>
      </van-slider>
      <div class="v-slider-ruler">
        <div v-for="item in rulers" :key="item">{{item}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, ref, toRefs } from 'vue'
import _ from 'lodash'
import { Slider } from 'vant'

export default {
  name: 'EffectSlider',
  components: {
    VanSlider: Slider,
  },
  props: {
    defaultValue: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    }
  },
  setup(props, { emit }) {
    const { disabled, defaultValue } = toRefs(props)
    // 默认50%
    let value = ref(50)
    const rulers = [0, 25, 50, 75, 100]

    const onChange = (val) => {
      emit('change', val)
    }
    const onUpdate = _.debounce((val) => {
      console.log(val)
      emit('update', val)
    }, 200)

    onBeforeMount(() => {
      if (defaultValue.value) {
        value.value = defaultValue.value
      }
      if (disabled.value) {
        value.value = 5
      }
    })

    return {
      value,
      onChange,
      onUpdate,
      rulers
    }
  },
}
</script>
<style lang="stylus" scoped>
.volume-item
  display flex
  align-items center
  justify-content center
  height: 108px;
  &:nth-child(3)
    margin 10px 0
  .v-slider
    width 800px
    height 112px
    display flex
    align-items center
    position relative
    justify-content space-between
    --van-slider-bar-height 12px !important
    --van-slider-button-width 40px!important
    --van-slider-button-height 40px!important
    --van-slider-button-background-color #6052FF !important
    --van-slider-button-border-radius 40px !important
    --van-slider-inactive-background-color #000 !important
    --van-slider-button-box-shadow none !important
    --van-slider-active-background-color #E3AB5D !important
    ::v-deep .van-slider
      border-radius 15px
    ::v-deep .van-slider__button-wrapper--right[aria-valuenow="0"],
    ::v-deep .van-slider__button-wrapper--right[aria-valuenow="1"],
    ::v-deep .van-slider__button-wrapper--right[aria-valuenow="2"],
    ::v-deep .van-slider__button-wrapper--right[aria-valuenow="3"]
      .custom-button
        margin-left 75px
    ::v-deep .van-slider__button-wrapper--right[aria-valuenow="98"],
    ::v-deep .van-slider__button-wrapper--right[aria-valuenow="99"],
    ::v-deep .van-slider__button-wrapper--right[aria-valuenow="100"]
      .custom-button
        margin-left -83px
    ::v-deep .van-slider--disabled
      --van-slider-button-background-color #2E2F32 !important
      --van-slider-active-background-color #2E2F32 !important
      --van-slider-inactive-background-color rgba(56, 58, 62, 0.60) !important
    .custom-button
      width 83px
      height 50px
      background url('https://qncweb.ktvsky.com/20231218/other/55b50a050b8754d7e97ec5e3a7963de1.png') no-repeat
      background-size 100%
      position relative
      z-index 10
    @media screen and (max-width 1200px) and (min-height 1200px)
      width 75vw
    .name
      font-size 26px
      color rgba(255, 255, 255, 0.6)
    &.disabled
      .name
        color rgba(255, 255, 255, 0.2)
    .van-slider
      width 586px
      border-radius: 38px;
      margin-bottom 44px
      position relative
      z-index 10
      @media screen and (max-width 1200px) and (min-height 1200px)
        width 802px
    ::v-deep .van-slider__bar
      height 2px !important
      top 6px
      padding 0 !important
      margin 0 6px
    &-ruler
      width 586px
      display flex
      justify-content space-around
      position absolute
      right 0
      top 60px
      color rgba(255,255,255,0.2)
      font-size 24px
      @media screen and (max-width 1200px) and (min-height 1200px)
        width 802px
        justify-content space-between
      div
        width 42px
        text-align center
        position relative
        &::after
          content ""
          position absolute
          top -10px
          left 50%
          width 1px
          height 12px
          background rgba(255,255,255,0.2)
          z-index 1
</style>