<template>
  <div class="fast-order-page" v-show="pageViewName === 'searchTabPage'">
    <!-- <div class="fast-order-page-title">{{ isSearch ? "搜索" : "快速点歌" }}</div> -->
    <div class="close-side" @click="$emit('close')">
      <img src="https://qncweb.ktvsky.com/20231215/vadd/5965d997c5fb54308e509e10203cde56.png" alt="">
    </div>
    <!-- <div class="singer-order" @click="$emit('go-singer-order')">
      <img src="https://qncweb.ktvsky.com/20231121/vadd/7ab7ee47767343bb8f4c8726733b6ba3.png" alt="">
      歌手点歌
    </div> -->
    <div class="fast-order-page-back" v-if="isSearch" @click="handleBackFastSearch">
      <img src="https://qncweb.ktvsky.com/20231218/vadd/675a88f8cb114a91342645d85f9f7b2f.png" alt="">
      搜索
    </div>
    <div class="tab" v-else>
      <div class="tab-item" :class="{ 'active':currPageTab === 'search' }" @click="handleSwitchPageTab('search')">快速点歌</div>
      <div class="tab-item" :class="{ 'active':currPageTab == 'singer' }" @click="handleSwitchPageTab('singer')">歌手点歌</div>
    </div>
    <div class="fast-order-page-main" v-if="currPageTab === 'search'">
      <SearchBar ref="searchInputRef" :isSearch="isSearch" @go-search="handleGoSearch" @search="handleSearch"/>
      <div class="song-list" v-if="keyword">
        <div class="tab">
          <div class="tab-item tab-right" :class="{ 'active':curSearchTab === 'song' }" @click="handleSwitchSearchTab('song')">歌曲</div>
          <div class="tab-item" :class="{ 'active':curSearchTab == 'singer' }" @click="handleSwitchSearchTab('singer')">歌手</div>
        </div>
        <div v-if="curSearchTab === 'song'" class="song-list">
          <LoadMore
            v-if="resultData.song.length"
            @load-more="getSearchResult"
            safeAreaHeight="20.6991vw"
          >
            <SongItem
              v-for="(songItem, index) in resultData.song"
              :key="index"
              :songItem="songItem"
              :log-from="{
                song_list_source: 10,
              }"
              :isStickButton="false"
              @singer-click="handleClickSinger"
            ></SongItem>
          </LoadMore>
          <div class="empty" v-if="resultData.song.length<=0 && !isRequest">
            <img src="https://qncweb.ktvsky.com/20231211/vadd/3bc6fd2689cda7c52578140c345c6272.png" alt="">
            <p>抱歉，暂无“{{ keyword }}”的结果</p>
          </div>
          <p class="hint" v-if="isEmpty && resultData.song.length">已加载全部</p>
        </div>
        <div v-else>
          <LoadMore
            class="singer-list singer-list-padding"
            ref="loadMoreRef"
            v-if="resultData.singer.length"
            @load-more="getSearchResult"
            safeAreaHeight="20.6991vw"
          >
            <SingerItem
              v-for="(item, index) in resultData.singer"
              :key="index"
              :singer="item"
              @click="handleClickSinger(item, 'search')"
            ></SingerItem>
          </LoadMore>
          <div class="empty" v-if="resultData.singer.length<=0 && !isRequest">
            <img src="https://qncweb.ktvsky.com/20231211/vadd/3bc6fd2689cda7c52578140c345c6272.png" alt="">
            <p>抱歉，暂无“{{ keyword }}”的结果</p>
          </div>
          <p class="hint" v-if="isEmpty && resultData.singer.length>20">已加载全部</p>
        </div>
      </div>
      <GuessSongList 
        class="guess-song-list" 
        showAllData 
        v-if="!isSearch" 
        pageRoute="3"
        :song_list_source="11"
        :ponitActionLog="{
          // 这里不上报了，传空
        }"
        renderType="list"
        :isStickButton="false"
        @singer-click="handleClickSinger"
      />
      <SearchHistory
        v-if="isSearch && !keyword"
        @clickWord="handleChangeSearchInput"
        @delete-word="handleDeleteSearchWord"
      />
    </div>
    <SingerOrder v-if="currPageTab === 'singer'" @singer-click="handleClickSinger" />
  </div>
  <SingerDetail v-if="pageViewName === 'singerDetail'" :singerData="singerData" @close="$emit('close')" @back="handlechangePageViewName" />
</template>

<script>
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import SearchBar from './search.vue'
import SongItem from '@/components/song-item/index.vue'
import SingerItem from '@/components/singer-item/index.vue'
import GuessSongList from '@/components/guess-song/songlist.vue'
import SearchHistory from '@/components/search-history/index.vue'
import SingerOrder from './../singer-order/index.vue'
import SingerDetail from './../singer-detail/index.vue'
import { search } from '@/service/search'
import { sendLog } from '@/directives/v-log/log'
import { setSearchCache } from '@/utils/historyCache'
import get from 'lodash/get'

export default {
  name: 'FastOrderPage',
  components: {
    SearchBar,
    SongItem,
    SingerItem,
    GuessSongList,
    SearchHistory,
    SingerOrder,
    SingerDetail,
  },
  emits: ['close'],
  setup() {
    const store = useStore()
    const searchCacheList = computed(() => store.state.search.searchCache)
    
    let pageViewName = ref('searchTabPage')
    let singerData = ref({
      singerid: '',
      name: '',
      image: '',
    }) // 侧边栏 - 歌手详情 -歌手数据

    let currPageTab = ref('search')

    let searchInputRef = ref(null)
    let isSearch = ref(false)
    let keyword = ref('')
    let curSearchTab = ref('song')
    let isEmpty = ref(false)
    let resultData = ref({
      singer: [],
      song: []
    })
    let pagination = {
      singer: 1,
      song: 1
    }
    let isRequest = ref(false)

    const handleGoSearch = () => {
      isSearch.value = true
      sendLog({
        event_type: '10000~50000',
        event_name: 10080,
        event_data: {
          str1: '快速点歌',
          str2: '搜索',
          str3: '点击搜索栏',
          str4: 'click',
        },
      })
    }

    const handleBackFastSearch = () => {
      isSearch.value = false
      curSearchTab.value = 'song'
      keyword.value = ''
      resultData.value.singer = []
      resultData.value.song = []
      if (searchInputRef.value) searchInputRef.value.changeInput('')
    }

    const handleSearch = async (k) => {
      keyword.value = k
    }

    const handleChangeSearchInput = (v) => {
      if (searchInputRef.value) searchInputRef.value.changeInput(v)
      sendLog({
        event_type: '10000~50000',
        event_name: 10084,
        event_data: {
          str1: '搜索',
          str2: '搜索历史',
          str3: '点击歌曲',
          str4: 'click',
        },
      })
    }

    const handleDeleteSearchWord = (v) => {
      sendLog({
        event_type: '10000~50000',
        event_name: v !== -1 ? 10085 : 10086, // 10085 单首删除 10086 全部删除
        event_data: {
          str1: '搜索',
          str2: '搜索历史',
          str3: v !== -1 ? '单首删除' : '全部删除',
          str4: 'click',
        },
      })
    }

    const getSearchReportStatus = (res) => {
      if (res.errcode !== 200) {
        return 12
      }
      return (res.singer.length || res.song.length)
        ? 10
        : 11
    }

    // 搜索词条时 如果第一页中有一首歌名与搜索内容一致 则符合一键演唱功能
    const setSearchSongList = (list) => {
      if (!!keyword.value && !resultData.value.song.length) {
        list.some(v => {
          const music_name = get(v, 'music_name', '').split('(HD)')[0]
          if (music_name === keyword.value) {
            store.dispatch('search/addSearchSong', {
              ...v,
              searchname: keyword.value
            })
            return true
          }
          return false
        })
      }
    }

    // 存储搜索历史
    const setSearchCacheList = (k) => {
      if (!searchCacheList.value.includes(k)) {
        let newSearchCacheList = [k, ...searchCacheList.value]
        newSearchCacheList = newSearchCacheList.slice(0, 10)
        store.dispatch('search/updateSearchCache', newSearchCacheList)
        setSearchCache(newSearchCacheList)
      }
    }

    // 优先展示搜索的vip歌曲
    const toSongvipSort = (arr) => {
      let arr1 = [], arr2 = []
      for(let i in arr) {
        if (arr[i].is_vip) {
          arr1.push(arr[i])
        } else {
          arr2.push(arr[i])
        }
      }
      return arr1.concat(arr2)
    }

    const searchHandler = {
      singer: async () => {
        const res = await search(keyword.value, pagination.singer, 'singer')
        // 搜索上报 10:搜索到结果，11:未搜索到结果，12:接口异常
        sendLog({
          event_type: 'click',
          event_name: 122,
          event_data: {
            key_words: keyword.value,
            status: getSearchReportStatus(res)
          }
        })
        if (res.singer.length) {
          resultData.value.singer = resultData.value.singer.concat(res.singer)
          pagination.singer ++
        }
        isRequest.value = false
        sendLog({
          event_type: '10000~50000',
          event_name: 10081,
          event_data: {
            str1: '快速点歌',
            str2: '搜索',
            str3: '点击搜索',
            str4: 'click',
          },
        })
      },
      song: async () => {
        const res = await search(keyword.value, pagination.song, 'song')
        // 搜索上报 10:搜索到结果，11:未搜索到结果，12:接口异常
        sendLog({
          event_type: 'click',
          event_name: 122,
          event_data: {
            key_words: keyword.value,
            status: getSearchReportStatus(res)
          }
        })
        if (res.song.length) {
          const songvipsort = toSongvipSort(res.song)
          setSearchSongList(songvipsort)
          resultData.value.song = resultData.value.song.concat(songvipsort)
          pagination.song ++
        }
        isRequest.value = false
        sendLog({
          event_type: '10000~50000',
          event_name: 10081,
          event_data: {
            str1: '快速点歌',
            str2: '搜索',
            str3: '点击搜索',
            str4: 'click',
          },
        })
      }
    }

    const getSearchResult = async () => {
      if (isRequest.value) {
        return
      }
      isRequest.value = true
      searchHandler[curSearchTab.value].call()
    }

    const handleSwitchSearchTab = (tab) => {
      curSearchTab.value = tab
      if (!resultData.value[tab].length) {
        searchHandler[tab].call()
      }
    }

    const handleSwitchPageTab = (tab) => {
      currPageTab.value = tab
    }

    const handleClickSinger = ({singer, singerhead, singerid}, type) => {
      if (type === 'search') {
        sendLog({
          event_type: '10000~50000',
          event_name: 10103,
          event_data: {
            str1: '快速点歌',
            str2: '搜索结果',
            str3: '点击任意歌手',
            str4: 'click',
          },
        })
      }
      singerData.value = {
        singerid,
        name: singer,
        image: singerhead,
      }
      pageViewName.value = 'singerDetail'
    }

    const handlechangePageViewName = () => {
      singerData.value = {
        singerid: '',
        name: '',
        image: '',
      }
      pageViewName.value = 'searchTabPage'
    }

    watch(keyword, (k) => {
      if (k) {
        resultData.value = {
          singer: [],
          song: []
        }
        pagination = {
          singer: 1,
          song: 1
        }
        getSearchResult()
        setSearchCacheList(k)
      }
    })

    return {
      pageViewName,
      singerData,
      currPageTab,
      searchInputRef,
      isSearch,
      keyword,
      curSearchTab,
      resultData,
      isEmpty,
      handleSearch,
      handleGoSearch,
      handleBackFastSearch,
      handleChangeSearchInput,
      handleDeleteSearchWord,
      getSearchResult,
      handleSwitchPageTab,
      handleSwitchSearchTab,
      handleClickSinger,
      handlechangePageViewName,
      isRequest,
    }
  }
}
</script>

<style lang="stylus" scoped>
.fast-order-page
  width 100%
  height 100vh
  position relative
  @media screen and (max-width 1200px)
    height 1080px
  &-title
    color rgba(255, 255, 255, 0.8)
    font-size 40px
    margin 71px 0 53px
    width 200px
    height 48px
    line-height 48px
    font-weight 600
  &-back
    color rgba(255, 255, 255, 0.8)
    font-size 32px
    display flex
    align-items center
    margin 64px 0 84px 48px
    width 200px
    height 48px
    img
      width 36px
      height 36px
      margin-right 30px
    @media screen and (max-width 1200px) and (min-height 1200px)
      height 30px
      font-size 26px
      margin 51px 0 51px 48px
      img
        width 26px
        height 26px
        margin-right 38px
  .close-side
    position absolute
    top 58px
    right 48px
    width 32px
    height 32px
    img
      width 100%
      height 100%
    @media screen and (max-width 1200px) and (min-height 1200px)
      top 48px
  .tab
    width 100%
    height 100px
    margin-top 22px
    margin-bottom 40px
    border-bottom 2px solid rgba(255,255,255,0.1)
    display flex
    align-items center
    justify-content center
    &-item
      display flex
      justify-content center
      align-items center
      width 173px
      height 100px
      font-size 32px
      color rgba(255, 255, 255, 0.40)
      &.active
        color #dbae6a
        border-bottom 2px solid #DBAE6A
      &:nth-child(1)
        margin-right 136px
    @media screen and (max-width 1200px) and (min-height 1200px)
      height 92px
      margin-top 20px
      margin-bottom 30px
      .tab
        height 92px
        &-item
          height 92px
          font-size 26px
    &-right
      margin-right 128px !important
  .singer-order
    width 202px
    height 90px
    color #fff
    opacity 0.8
    font-size 28px
    display flex
    align-items center
    justify-content center
    position absolute
    top 43px
    right 158px
    background #383A3E
    border-radius 14px
    img
      width 29.6px
      height 28.8px
      margin-right 9px
  &-main
    width 100%
    height auto
    padding 0
    @media screen and (max-width 1200px)
      display flex
      flex-direction column
      align-items center
    .guess-song-list
      margin 0 !important
      padding 48px !important
      min-height calc(100vh - 276px) !important
      ::v-deep .section-container-header
        padding-bottom 10px
        .section-container-header-title
          font-size 28px
          font-weight 400
          @media screen and (max-width 1200px)
            font-size 24px
      ::v-deep .sec-gusse-sing-list
        grid-template-columns repeat(1, 704px) !important
        overflow-y scroll 
        height 711px
        .sec-gusse-sing-list-item
          width 704px !important
          @media screen and (max-width 1200px)
            width calc(100vw - 120px) !important
            padding 30px 20px
            .name
              font-size 26px
            .desc
              font-size 20px
            .order-btn
              width 160px
              height 64px
              font-size 22px
          .author
            max-width 160px
            overflow hidden
            white-space nowrap
            text-overflow ellipsis
            @media screen and (max-width 1200px)
              max-width 160px
          .right
            margin-right 0 !important
    ::v-deep .search-history
      margin-top 48px
      padding 0 48px
      .search-history-title 
        height 32px
        span
          opacity 0.4
          font-size 28px
          margin-right 12px
        img
          // width 36px
          // height 36px
          opacity 0.6
        @media screen and (max-width 1200px)
          span
            font-size 24px
      .search-history-content
        margin-top 26px
        .search-history-content-item
          background rgba(56, 58, 62, 0.50)
          margin-right 24px
          margin-bottom 24px
          span
            color rgba(255, 255, 255, 0.6)
            margin-right 24px
          &-d-img
            opacity 0.7
          &-s-img
            display none
    .song-list
      width 100%
      box-sizing border-box
      padding 0
      margin-top 20px
      ::-webkit-scrollbar
        display none
      // ::-webkit-scrollbar-track
      //   background #1E1F21
      ::v-deep .song-item
        height 141px
        margin 0 48px
        padding 0
        border-color rgba(255, 255, 255, 0.08)
        .right
          margin-right 0
        .name
          color rgba(255, 255, 255, 0.70)
          .name-txt
            max-width 600px
      @media screen and (max-width 1200px)
        width calc(100vw - 120px) !important
        padding 0 20px
        ::v-deep .song-item
          padding 0
          .name
            font-size 26px
          .desc
            font-size 20px
          .order-btn
            width 160px
            height 64px
            font-size 22px
      .empty
        display flex
        flex-direction column
        justify-content center
        align-items center
        margin-top 30px
        font-size 28px
        color rgba(255, 255, 255, 0.40)
        text-align center
        img
          width 80px
          height 80px
          margin-bottom 40px
        p
          height 32px
          line-height 32px
        @media screen and (max-width 1200px) and (min-height 1200px)
          margin-top 14vh
          font-size 22px
          img
            width 72px
            height 72px
      .hint
        text-align center
        color #555555
      .singer-list
        margin 0 auto
        display grid
        grid-template-columns repeat(4, 140px)
        justify-content space-between
        padding-top 12px
        padding-left 48px
        padding-right 48px
        ::v-deep .singer-item
          width 140px
          margin-bottom 40px
          font-size 24px
          margin-right 45px
          &:nth-child(4n)
            margin-right 0
          .singer-item-cover
            width 140px
            height 140px
            margin-bottom 20px
          p
            width 140px
      .singer-list-padding
        padding-left 48px !important
        padding-right 48px !important
        @media screen and (max-width 1200px) and (min-height 1200px)
          padding-left 32px !important
          padding-right 32px !important
          grid-template-columns repeat(4, 200px)
          ::v-deep .singer-item
            width 200px
            margin-bottom 60px
            font-size 22px
            .singer-item-cover
              width 200px
              height 200px
            p
              width 200px
</style>