import { getSignInData, getSignInActivityStatus, postSignIn } from '@/service/signin'
import get from 'lodash/get'
import { Toast } from 'vant'
import { sendLog } from '@/directives/v-log/log'

const defalutUserSignData = [{
	"sign_status": 3, // 1 已签 2 漏签 3 未签
	"days_type": 4, // 周一到周日对应 0-6
	"num": 0,
	"date_time": "7.21",
  "showText": '1',
}, {
	"sign_status": 3,
	"days_type": 5,
	"num": 0,
	"date_time": "7.22",
  "showText": '2',
}, {
	"sign_status": 3,
	"days_type": 6,
	"num": 0,
	"date_time": "7.23",
  "showText": '1日VIP',
  "isVipDay": true,
}, {
	"sign_status": 3,
	"days_type": 0,
	"num": 0,
	"date_time": "7.24",
  "showText": '4',
}, {
	"sign_status": 3,
	"days_type": 1,
	"num": 0,
	"date_time": "7.25",
  "showText": '3日VIP',
  "isVipDay": true,
}, {
	"sign_status": 3,
	"days_type": 2,
	"num": 0,
	"date_time": "7.26",
  "showText": '6',
}, {
	"sign_status": 3,
	"days_type": 3,
	"num": 0,
	"date_time": "7.27",
  "showText": '7日VIP',
  "isVipDay": true,
}]

const vipDayObj = {
  0: undefined,
  1: undefined,
  2: undefined,
  3: 1,
  4: undefined,
  5: 2,
  6: undefined,
  7: 3
}

const state = {
  isShowSignIn: false,
  signInActStart: '',
  signInActEnd: '',
  signInData: defalutUserSignData,
  nowActivityDay: 0, // 今天是活动的第几天
  sign_days: 0 // 已签到的总天数
}

const getters = {}

const actions = {
  async setSignInActivityStatus({ commit }) {
    const res = await getSignInActivityStatus()
    if (res.errcode == 200) {
      commit('UPDATE_SIGNIN_ACT_TIME', {start: get(res, 'data.start_time', ''), end: get(res, 'data.end_time', '')})
      commit('UPDATE_ISSHOW_SIGNIN', true)
    }
  },
  async setSignInData({ commit }, unionid) {
    const res = await getSignInData({ unionid })
    if (get(res, 'week_data_list', []).length) {
      const currActDay = get(res, 'past_days', 0)
      const sign_days = get(res, 'sign_days', 0)
      let i = 0
      const signInArr = res.week_data_list.map((v, index) => {
        let obj = { ...v, showText: '', isVipDay: false }
        switch (v.sign_status) {
          case 1:
            i++
            obj.showText = i
          break
          case 2:
            obj.showText = 'Miss'
          break
          case 3:
            i++
            obj.showText = i
          break
        }
        switch (obj.showText) {
          case 3:
            obj.showText = '1日VIP'
            obj.isVipDay = true
          break
          case 5:
            obj.showText = '3日VIP'
            obj.isVipDay = true
          break
          case 7:
            obj.showText = '7日VIP'
            obj.isVipDay = true
          break
        }
        return obj
      })
      commit('SAVE_SIGNIN_DATA', signInArr)
      commit('SAVE_NOWDAY', currActDay)
      commit('SAVE_SIGN_DAYS', sign_days)
    }
  },
  async signInToday({ dispatch }, { unionid, mac_id }) {
    const res = await postSignIn({
      unionid,
      mac_id
    })
    if (res && res.errcode == 200) {
      if (get(res, 'data.sign_status') == 1) {
        sendLog({
          event_type: 'click',
          event_name: 1730,
          event_data: {
            str1: String(get(res, 'data.sign_days', 0))
          }
        })
        let vipDay = null
        
        vipDay = vipDayObj[get(res, 'data.sign_days', 0)]
        if (vipDay) {
          sendLog({
            event_type: 'click',
            event_name: 1731,
            event_data: {
              str1: String(vipDay)
            }
          })
        }
      }
      Toast({
        message: get(res, 'data.sign_status') == 1 ? '签到成功' : '已签到过了',
        position: 'center',
        className: 'toast-zoom-max'
      })
      dispatch('setSignInData', unionid)
      setTimeout(() => {
        dispatch('getCarplayInfo', {}, { root: true })
      }, 1500)
    } else {
      Toast({
        message: get(res, 'errmsg', '服务器错误'),
        position: 'center',
        className: 'toast-zoom-max'
      })
    }
  },
  resetSignInData({ commit }) {
    commit('SAVE_SIGNIN_DATA', defalutUserSignData)
    commit('SAVE_NOWDAY', 0)
    commit('SAVE_SIGN_DAYS', 0)
  },
  setIsShowSignIn({ commit }) {
    commit('UPDATE_ISSHOW_SIGNIN', true)
  },
}

const mutations = {
  SAVE_SIGNIN_DATA(state, data) {
    state.signInData = data
    console.log('signInArr:', data)

  },
  UPDATE_ISSHOW_SIGNIN(state, data) {
    state.isShowSignIn = data
  },
  SAVE_NOWDAY(state, data) {
    state.nowActivityDay = data
  },
  SAVE_SIGN_DAYS(state, data) {
    state.sign_days = data
  },
  UPDATE_SIGNIN_ACT_TIME(state, data) {
    state.signInActStart = data.start.split(' ')[0]
    state.signInActEnd = data.end.split(' ')[0]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
