import { createComponent } from '@/components/modal/utils/index.js'
import NewSongVue from './index.vue'

export default function useNewSongModal(globalProps = {}, globalSlots = {}) {
  function show(Component, props = globalProps, slots = globalSlots) {
    const forceProps = {
      programmatic: true,
      lockScroll: true,
      isFullPage: true,
      active: true,
    }

    const propsData = { ...globalProps, ...props, ...forceProps }
    const container = document.body

    const mergedSlots = { ...globalSlots, ...slots }
    const instance = createComponent(
      Component,
      propsData,
      container,
      mergedSlots
    )

    return {
      hide: instance.refs.root.hide,
    }
  }
  return {
    show(props = globalProps, slots = globalSlots) {
      return show(NewSongVue, props, slots)
    },
  }
}
