<template>
  <div class="mv-user-vip">
    <div class="mv-user-vip-back">
      <div class="left">
        我的特权
      </div>
      <img
        class="close"
        src="https://qncweb.ktvsky.com/20231212/other/f3e0b3ce8cd5bbc595aa74a5199aa046.svg"
        @click="$emit('close')"
      >
    </div>
    <MineVip v-if="isLogin" from="mv" />
    <div v-else class="mv-user-vip-unlogin">
      <h3>登录查看特权</h3>
      <p>扫码登录，记录唱过的歌</p>
       <div class="qrcode">
        <img :src="qrCodeURL" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onBeforeMount, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { getCarplayInfo } from '@/service/carplay-info'
import useQRCode from '@/composables/useQRCode'
import { Toast } from 'vant'
import get from 'lodash/get'
import MineVip from '@/components/vip/index.vue'
import { vipLogFrom } from '@/constants/index'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'Profile',
  components: {
    MineVip,
  },
  setup() {
    const store = useStore()
    const isLogin= computed(() => !!store.state.userInfo.unionid)
    const { getQRCodeURL } = useQRCode()

    let qrCodeURL = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')
    const userType = computed(() => store.state.userInfo.userType)
    
    const getLoginQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'login_qr', '')) {
        const loginUrl = `${data.pay_qr}&log=${vipLogFrom.get('MV界面')}&ut=${userType.value}`
        const qrCodeData = await getQRCodeURL(loginUrl)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }
    
    onBeforeMount(getLoginQrcode)

    onMounted(() => {
      sendLog({
        event_type: '10000~50000',
        event_name: isLogin.value ? 30196 : 30105,
        event_data: {
          str1: '欢唱页',
          str2: '我的特权',
          str3: isLogin.value ? '支付码' : '登录码',
          str4: 'show',
        },
      })
    })

    return {
      isLogin,
      qrCodeURL,
    }
  }
}
</script>

<style lang="stylus" scoped>
.mv-user-vip
  width 100%
  &-back
    display flex
    justify-content space-between
    align-items center
    margin 22px 48px 22px 50px
    .left
      display flex
      align-items center
      height 120px
      color: rgba(255, 255, 255, 0.80);
      font-size: 32px;
    .close
      width 25px
    @media screen and (max-width 1200px) and (min-height 1200px)
      margin 3px 48px 3px 60px
      .left
        font-size 26px
  &-unlogin
    display flex
    flex-direction column
    align-items center
    padding-top 171px
    h3
      color: rgba(255, 255, 255, 0.80);
      font-size: 44px;
      font-weight: 700;
    p
      color: rgba(255, 255, 255, 0.40);
      font-size: 24px;
    .qrcode
      width: 266px;
      height: 266px;
      border-radius 10px
      background #fff
      margin-top 48px
      img
        width 246px
        height 246px
        margin 10px
</style>
