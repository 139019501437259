import { createComponent } from '@/components/modal/utils/index.js';
import UserCollectModalComponent from "./index.vue";

export default function useUserCollectModal(globalProps = {}, globalSlots = {}) {

  return {
    show(props = globalProps, slots = globalSlots) {
      const forceProps = {
        programmatic: true,
        lockScroll: true,
        isFullPage: true,
        active: true,
      }

      const propsData = Object.assign({}, globalProps, props, forceProps);
      const container = document.body;

      const mergedSlots = Object.assign({}, globalSlots, slots);
      const instance = createComponent(UserCollectModalComponent, propsData, container, mergedSlots);
      return {
        hide: instance.refs.root.hide
      }
    },
  }
}
