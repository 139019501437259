import { setSearchCache, setSearchSong } from '@/utils/historyCache'

const state = {
  searchCache: [],
  searchSong: [],
}

const getters = {}

const actions = {
  updateSearchCache({ commit }, data) {
      commit('SAVE_SEARCHCACHE_LIST', data)
  },
  initSearchSong({ commit }, data) {
    commit('SAVE_SEARCH_SONG', data)
  },
  addSearchSong({ commit, state }, data) {
    if (!state.searchSong.map(v => v.searchname).includes(data.searchname)) {
      let newSong = [data, ...state.searchSong]
      newSong = newSong.slice(0, 10)
      commit('SAVE_SEARCH_SONG', newSong)
      setSearchSong(newSong)
    }
  },
  deleteSearchSong({ commit, state }, searchname) {
    let newSong = state.searchSong.filter(v => v.searchname !== searchname)
    commit('SAVE_SEARCH_SONG', newSong)
    setSearchSong(newSong)
  },
  clearSearchCache({ commit }) {
    commit('SAVE_SEARCHCACHE_LIST', [])
    commit('SAVE_SEARCH_SONG', [])
    setSearchCache([])
    setSearchSong([])
  }
}

const mutations = {
  SAVE_SEARCHCACHE_LIST(state, data) {
      state.searchCache = data
  },
  SAVE_SEARCH_SONG(state, data) {
    state.searchSong = data
},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}