<template>
  <div class="atmosphere">
    <audio ref="audioEl">
      您的设备不支持该音频文件
    </audio>
    <div
      class="atmosphere-audio"
      v-for="audio in audioList"
      :key="audio.name"
      @click="handleClick(audio)"
    >
      <div>
        <img :src="audio.img" alt="">
      </div>
      <p>{{ audio.name }}</p>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'EffectAdjustAtmospherePanel',
  setup() {
    const audioEl = ref(null)

    const audioList = [{
      name: '喝彩',
      img: 'https://qncweb.ktvsky.com/20211215/vadd/e64ffb22676239ce4a7e37ff5e968e17.png',
      src: 'https://qncweb.ktvsky.com/20211215/vadd/2bc0de765cdb1dc9bc643e07f1f22bf5.wav'
    }, {
      name: '鼓掌',
      img: 'https://qncweb.ktvsky.com/20211215/vadd/24098760d1576e3ffa29a6d65bb96da4.png',
      src: 'https://qncweb.ktvsky.com/20211215/vadd/1650c82282ce408850df122f790c1869.wav'
    }, {
      name: '倒彩',
      img: 'https://qncweb.ktvsky.com/20211215/vadd/83de2c74cfc546e36eef6efda18f6179.png',
      src: 'https://qncweb.ktvsky.com/20211215/vadd/5135a9e5f7a1d6c7f9404f3fe48be7c1.wav'
    }]

    const handleClick = ({ src, name }) => {
      if (audioEl.value) {
        audioEl.value.src = src
        audioEl.value.play()
        audioEl.value.volume = 0.4
        sendLog({
          event_type: '10000~50000',
          event_name: 10051,
          event_data: {
            str1: 'MV',
            str2: '播控',
            str3: '进入气氛',
            str4: 'click',
            str5: '发送气氛',
            str6: 'click',
            str9: name
          },
        })
      }
    }

    return {
      audioEl,
      audioList,
      handleClick,
    }
  },
}
</script>
<style lang="stylus" scoped>
.atmosphere
  display: flex
  margin-top 160px
  &-audio
    &:not(:last-child)
      margin-right 100px
    div
      display: flex
      width 160px
      height 160px
      justify-content: center
      align-items: center
      background: #383A3E
      border-radius: 100%
      margin-bottom 30px
      &:hover
        transform scale(1.05)
      img
        width 80px
        height 80px
    p
      font-size 32px
      text-align: center
</style>