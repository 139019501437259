// 常唱数据以及特斯拉点歌前50数据
import { getSingsingList, getTop50List } from '@/service/singing'

const state = {
  oftenSingList: [],
  top50: [], //改为top前100了
}

const getters = {}

const actions = {
  async initOftenSingList({ commit }, unionid) {
    let res = []
    const list = await getSingsingList({
      unionid
    })
    if (list.length) res = list
    commit('SET_OFTENSINGLIST', res)
  },
  async initTop50List({ commit }) {
    let res = []
    const list = await getTop50List()
    if (list.length) res = list
    commit('SET_TOP50LIST', res)
  },
  resetOftenSingList({ commit }) {
    commit('RESET_OFTENSINGLIST')
  },
}

const mutations = {
  SET_OFTENSINGLIST(state, data) {
    state.oftenSingList = data
  },
  SET_TOP50LIST(state, data) {
    state.top50 = data
  },
  RESET_OFTENSINGLIST(state) {
    state.oftenSingList = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
