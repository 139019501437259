<template>
  <div class="step">
    <template v-for="(step, index) in signInData" :key="index">
      <template v-if="!step.isVipDay">
        <!-- 未签或Miss -->
        <div class="step-item" v-if="step.sign_status !== 1 && index !== nowActivityDay">
          <div v-if="index !== 0" class="step-item-bar" :class="{ 'step-item-bar-active' : index < (nowActivityDay + 1), 'step-item-bar-width': barClass }"></div>
          <div v-if="index > nowActivityDay" class="step-item-circle">{{step.showText}}天</div>
          <div v-else class="step-item-circle">Miss</div>
        </div>
        <!-- 已签或当天可签 -->
        <div class="step-item" v-if="step.sign_status == 1 || index == nowActivityDay">
          <div v-if="index !== 0" class="step-item-bar" :class="{ 'step-item-bar-active' : index < (nowActivityDay + 1), 'step-item-bar-width': barClass }"></div>
          <div class="step-item-circle step-item-circle-active">
            {{ step.showText }}天
            <img v-if="step.sign_status == 1" class="step-item-complete" :src="signInIcon.complete" alt="">
          </div>
        </div>
      </template>
      <template v-else>
        <!-- vip领取日 - 亮（已领取或者当天可领取） -->
        <div class="step-item" v-if="step.sign_status == 1 || index == nowActivityDay">
          <div v-if="index !== 0" class="step-item-bar" :class="{ 'step-item-bar-active' : index < (nowActivityDay + 1), 'step-item-bar-width': barClass }"></div>
          <div class="step-item-circle-vip">
            {{ step.showText.split('日')[0] }}日<br>VIP
            <img v-if="step.sign_status == 1" class="step-item-complete" :src="signInIcon.complete" alt="">
          </div>
        </div>
        <!-- vip领取日 - 暗（未领取） -->
        <div class="step-item" v-else>
          <div v-if="index !== 0" class="step-item-bar" :class="{ 'step-item-bar-active' : index < (nowActivityDay + 1), 'step-item-bar-width': barClass }"></div>
          <div class="step-item-circle-vip step-item-circle-vip-dark">
            {{ step.showText.split('日')[0] }}日<br>VIP
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import store from '@/store'
// import { useStore } from 'vuex'

export default {
  name: 'StepComponent',
  props: {
    // signInData: {
    //   type: Array,
    //   default: () => []
    // },
    // nowActivityDay: {
    //   type: Number,
    //   default: 0
    // }
    barClass: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const signInIcon = ref({
      darkBg: 'https://qncweb.ktvsky.com/20230721/vadd/e10be7ae3fe47c700d12fc6392f07974.png',
      lightBg: 'https://qncweb.ktvsky.com/20230721/vadd/3a612a95e72554a5301e5b6b5e3794b4.png',
      canSignBg: 'https://qncweb.ktvsky.com/20230721/vadd/ced1455972a6d1feba970b9e7f0077ad.png',
      complete: 'https://qncweb.ktvsky.com/20230721/vadd/c7e990c4f586c9c1d287c18ed26f00d4.png',
    })
    // const store = useStore()
    const signInData = computed(() => store.state.signin.signInData)
    const nowActivityDay = computed(() => store.state.signin.nowActivityDay)

    onMounted(() => {
      console.log('signInData',signInData.value)
    })

    return {
      signInIcon,
      nowActivityDay,
      signInData,
    }
  }
}
</script>

<style lang="stylus" scoped>
.step
  width 100%
  height 100px
  // margin-top 195px
  display flex
  flex-direction row
  align-items center
  &-item
    display flex
    flex-direction row
    align-items center
    &-circle
      width 68px
      height 68px
      color #666
      font-size 24px
      text-align center
      line-height 68px
      position relative
      background url(https://qncweb.ktvsky.com/20230721/vadd/e10be7ae3fe47c700d12fc6392f07974.png) no-repeat
      background-size 100% 100%
      background-position center
    &-circle-active
      background url(https://qncweb.ktvsky.com/20230721/vadd/3a612a95e72554a5301e5b6b5e3794b4.png) no-repeat
      background-size 100% 100%
      background-position center
    &-circle-vip
      width 100px
      height 100px
      font-weight 700
      font-size 24px
      color rgba(223, 84, 46, 1)
      text-align center
      line-height 24px
      padding-top 24px
      position relative
      background url(https://qncweb.ktvsky.com/20230721/vadd/ced1455972a6d1feba970b9e7f0077ad.png) no-repeat
      background-size 100% 100%
      background-position center
    &-circle-vip-dark
      color #666
      background url(https://qncweb.ktvsky.com/20230721/vadd/e10be7ae3fe47c700d12fc6392f07974.png) no-repeat
      background-size 100% 100%
      background-position center
    &-complete
      width 28px
      height 28px
      position absolute
      bottom -10px
      left calc(50% - 14px)
    &-bar
      width 60px
      height 6px
      background rgba(255, 255, 255, 1)
    &-bar-active
      background linear-gradient(90deg, #FBD7AF 0.13%, #F1BE73 100%)
    &-bar-width
      width 138px
      @media screen and (max-width 1200px)
        width 60px
</style>