import cloneDeep from "lodash/cloneDeep";

export default function injectToken(headers, originResponseData) {
  let data = cloneDeep(originResponseData);
  const token =
    headers["access-control-allow-tk"] || headers["Access-Control-Allow-Tk"];
  const tokenExp =
    headers["access-control-allow-tk-exp"] ||
    headers["Access-Control-Allow-Tk-Exp"];
  const serverTime =
    headers["access-control-allow-tk-now"] ||
    headers["Access-Control-Allow-Tk-Now"];
  if (token) {
    // Access-Control-Allow-Tk 这里会自动转换为小写
    if (!data) data = {};
    data.token = token;
  }
  if (tokenExp) {
    // 同上
    if (!data) data = {};
    data.tokenExp = Number(tokenExp); // 解析出来的可能是字符串 需转换成数字
  }
  if (serverTime) {
    // 判断token是否过期时使用 用接口前后服务器时间进行对比
    if (!data) data = {};
    data.serverTime = serverTime;
  }
  return data;
}
