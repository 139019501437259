import { getClimaxSongList, getSongToken } from '@/service/climaxSong';
import { get } from 'lodash';
import store2 from 'store2';

const state = {
  climaxList: [],
  climaxSong: {},
  songToken: '',
  freeSing: false,
}

const getters = {}

const actions = {
  async fetchClimaxList({ commit }) {
    const { data } = await getClimaxSongList();
    commit('SET_CLIMAX_LIST', data);
  },
  async fetchSongToken({ commit }) {
    const data = await getSongToken();
    commit('SET_SONG_TOKEN', data.token);
  },
  setCurrentClimaxSong({ commit }, songData) {
    commit('SET_CLIMAX_SONG', songData);
  },
  updateFreeSing({ commit }, freeSing) {
    commit('SET_FREE_SING', freeSing);
    if (freeSing) store2('freeSing', freeSing);
  }
}

const mutations = {
  SET_CLIMAX_LIST(state, climaxList) {
    state.climaxList = climaxList
    // state.climaxList = climaxList.filter(song => song.songid === 7572506);
  },
  SET_CLIMAX_SONG(state, climaxSong) {
    state.climaxSong = climaxSong;
  },
  SET_SONG_TOKEN(state, token) {
    state.songToken = token;
  },
  SET_FREE_SING(state, freeSing) {
    state.freeSing = freeSing;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
