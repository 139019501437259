<template>
  <transition ref="root" :name="transition">
    <div class="notify" v-show="isActive">
      <slot name="default"></slot>
    </div>
  </transition>
</template>

<script>
import { ref, onMounted, render, toRefs } from 'vue'
import { removeElement } from '@/components/modal/utils/index.js'

export default {
  name: 'Notify',
  props: {
    active: Boolean,
    transition: {
      type: String,
      default: 'fade'
    },
  },
  setup(props, { emit }) {
    const root = ref(null)
    const { active } = toRefs(props)
    let isActive =ref(true)

    onMounted(() => {
      isActive.value = active.value
    })

    const hide = () => {
      emit('hide')
      isActive.value = false
      setTimeout(() => {
        if (root.value) {
          const parent = root.value.parentElement
          render(null, parent)
          removeElement(parent)
        }
      }, 300)
    }

    return {
      root,
      isActive,
      hide,
    }
  }
}
</script>

<style lang="stylus" scoped>
.notify
  max-width 670px
  height 80px
  z-index 1001
  background-image linear-gradient(96deg, #F9DBB8 0%, #F9DBB8 41%, #daad77fa 100%)
  border-radius 8px
  position fixed
  top 35px
  left calc(50vw - 335px)
</style>