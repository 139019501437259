import { createRouter, createWebHashHistory } from "vue-router"
import { Toast } from 'vant'
import store from '@/store'
import useLoginConfirm from '@/components/modal/global/login-confirm/create'
import useLoginQrcode from '@/components/modal/global/login-qrcode/create'
import { sendLog } from '@/directives/v-log/log'
import { format } from 'date-fns'
import formatStr from '@/constants/index'

const loginConfrimModal = useLoginConfirm()
const loginQrcodeModal = useLoginQrcode()

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../pages/index/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: false,
      keepAlive: true,
      key:"home"
    }
  },
  {
    path: "/agreement/privacy",
    name: "agreementPrivacy",
    component: () =>
      import(
        /* webpackChunkName: "agreementPrivacy" */ "../pages/agreement/privacy.vue"
      ),
    meta: {
      showOrderedPopupBtn: true
    }
  },
  {
    path: "/agreement/user",
    name: "agreementUser",
    component: () =>
      import(
        /* webpackChunkName: "agreementUser" */ "../pages/agreement/user.vue"
      ),
    meta: {
      showOrderedPopupBtn: true
    }
  },
  {
    path: "/songlist",
    name: "songList",
    component: () =>
      import(
        /* webpackChunkName: "songList" */ "../pages/songlist/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: true,
      keepAlive: false,
      key:"songlist"
    }
  },
  {
    path: "/playlist",
    name: "playList",
    component: () =>
      import(
        /* webpackChunkName: "songList" */ "../pages/songlist/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: true,
      keepAlive: true,
      key:"playlist"
    }
  },
  // {
  //   path: "/mv",
  //   name: "mv",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "mv" */ "../pages/mv/index.vue"
  //     ),
  //   meta: {
  //     showOrderedPopupBtn: false
  //   }
  // },
  {
    path: "/classify",
    name: "classify",
    component: () =>
      import(
        /* webpackChunkName: "classify" */ "../pages/classify/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: true,
      keepAlive: true,
      key:"classify"
    }
  },
  {
    path: "/singer",
    name: "singer",
    component: () =>
      import(
        /* webpackChunkName: "singer" */ "../pages/singer/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: true,
      keepAlive: true,
      key:"singer"
    }
  },
  {
    path: "/singing",
    name: "singing",
    component: () =>
      import(
        /* webpackChunkName: "songList" */ "../pages/singing/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: true,
      keepAlive: false,
      key:"singing"
    }
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(
        /* webpackChunkName: "search" */ "../pages/search/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: true,
      isUseCache: false,
      keepAlive: true,
      key:"search"
    }
  },
  {
    path: "/mine",
    name: "mine",
    component: () =>
      import(
        /* webpackChunkName: "mine" */ "../pages/mine/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: true
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "../pages/mine/profile-new.vue"
      ),
    meta: {
      showOrderedPopupBtn: true,
      requireLoginValid: true
    }
  },
  {
    path: "/vip",
    name: "vip",
    component: () =>
      import(
        /* webpackChunkName: "vip" */ "../pages/vip/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: true,
      requireLoginValid: true
    }
  },
  {
    path: "/vip-exchange",
    name: "vipExchange",
    component: () =>
      import(
        /* webpackChunkName: "vipExchange" */ "../pages/vip/exchange.vue"
      ),
    meta: {
      showOrderedPopupBtn: true,
      requireLoginValid: true
    }
  },
  {
    path: "/setting",
    name: "setting",
    component: () =>
      import(
        /* webpackChunkName: "mine" */ "../pages/mine/setting.vue"
      ),
    meta: {
      showOrderedPopupBtn: true
    }
  },
  {
    path: "/sing-task",
    name: "singTask",
    component: () =>
      import(
        /* webpackChunkName: "singTask" */ "../pages/sing-task/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: false,
      requireLoginValid: true
    }
  },
  {
    path: "/signin",
    name: "signin",
    component: () =>
      import(
        /* webpackChunkName: "signin" */ "../pages/signin/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: false,
      requireLoginValid: false
    }
  },
  {
    path: "/activity",
    name: "activity",
    component: () =>
      import(
        /* webpackChunkName: "activity" */ "../pages/act/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: false,
      requireLoginValid: false
    }
  },
  {
    path: "/song-sheet",
    name: "song-sheet",
    component: () =>
      import(
        /* webpackChunkName: "song-sheet" */ "../pages/song-sheet/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: false,
      requireLoginValid: false
    }
  },
  {
    path: "/zerobuy",
    name: "zerobuy",
    component: () =>
      import(
        /* webpackChunkName: "zerobuy" */ "../pages/act-zerobuy/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: false,
      requireLoginValid: true
    }
  },
  {
    path: "/letter-search",
    name: "letter-search",
    component: () =>
      import(
        /* webpackChunkName: "letter-search" */ "../pages/letter-search/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: false,
      requireLoginValid: false
    }
  },
  {
    path: "/climax",
    name: "climax",
    component: () =>
      import(
        /* webpackChunkName: "climax" */ "../pages/climax/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: false,
      requireLoginValid: false
    }
  },
  {
    path: "/aiface",
    name: "aiface",
    component: () =>
      import(
        /* webpackChunkName: "climax" */ "../pages/aiface/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: false,
      requireLoginValid: false
    }
  },
  {
    path: "/album",
    name: "album",
    component: () =>
      import(
        /* webpackChunkName: "album" */ "../pages/album/index.vue"
      ),
    meta: {
      showOrderedPopupBtn: false,
      requireLoginValid: false
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // console.log(savedPosition) 貌似不太适应搜索页 ~ 先放着
    if (savedPosition) {        
      return savedPosition    
    } else {      
      if (from.meta.keepAlive) {        
        from.meta.savedPosition = document.body.scrollTop     
      }        
      return { x: 0, y: to.meta.savedPosition || 0 }    
    }
  }  
})

function canUserAccess() {
  if (!store.state.userInfo.unionid) {
    router.push({
      name: 'home'
    })
    // 需求变更 - 改回只登录
    loginConfrimModal.show({
      onConfirm: () => {
        loginQrcodeModal.show()
      }
    })
    // 改为最新的vip登录支付页
    // vipLoginQrcodeModal.show()
    return false
  }
  return true
}

router.beforeEach((to) => {
  if (to.meta.requireLoginValid) {
    return canUserAccess()
  }
  return true
})

// 页面停留时间上报
// https://m2miovoqda.feishu.cn/wiki/wikcnKrGotVoDCPjP6lki2IhLFf
let enterStartTime = 0

const RouteNameStatCodeMap = {
  songList: 181,
  singer: 182,
  classify: 183,
}

function stayTimeStat(routeName) {
  if (enterStartTime) {
    const endTime = Date.now()
    const stayTime = Math.round((endTime - enterStartTime) / 1000)
    sendLog({
      event_type: 'custom',
      event_name: RouteNameStatCodeMap[routeName],
      event_data: {
        start_time: format(enterStartTime, formatStr),
        end_time: format(endTime, formatStr),
        stay_time: stayTime,
      }
    })
  }
  if (RouteNameStatCodeMap[routeName]) {
    enterStartTime = Date.now()
  }
}

router.afterEach((to) => {
  const toName = to.name || 'unknown'
  // 路由上报
  sendLog({
    event_type: 'custom',
    event_name: 161,
    event_data: {
      route_page: toName || to.path || 'unknown', 
      route_name: toName || to.path || 'unknown'
    }
  })
  // 页面停留时间上报
  stayTimeStat(toName)
  Toast.clear()
})

export default router
