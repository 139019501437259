<template>
  <CommonModal ref="root">
    <!-- 免费歌曲用尽会员运营弹窗 -->
    <div class="activity-nofree-vip">
      <div @click="handleCloseModal" class="activity-nofree-vip-close"></div>
      <div class="activity-nofree-vip-bottom">
        <div class="activity-nofree-vip-code">
          <img :src="qrCodeURL" alt="">
          <span>微信扫码开通</span>
        </div>
      </div>
    </div>
  </CommonModal>
</template>
<script>
import { computed, ref, onBeforeMount } from 'vue'
import store from '@/store'
import CommonModal from '@/components/modal/common/component.vue'
// import { format } from 'date-fns'
import { getCarplayInfo } from '@/service/carplay-info'
import useQRCode from '@/composables/useQRCode'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { sendLog } from '@/directives/v-log/log'
import { vipLogFrom } from '@/constants/index'

export default {
  name: 'FreeSongNoTimeModal',
  components: {
    CommonModal,
  },
  props: {
    closeEvent: {
      type: Function,
      default: () => {
      }
    },
  },
  setup(props) {
    const root = ref(null)
    // const appStartTime = computed(() => store.state.appStartTime)
    const userType = computed(() => store.state.userInfo.userType)

    const { getQRCodeURL } = useQRCode()
    let qrCodeURL = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')

    const getVipQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(`${data.pay_qr}&log=${vipLogFrom.get('新用户免费次数用尽')}&ut=${userType.value}`)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const handleCloseModal = () => {
      // const now = Date.now()
      props.closeEvent.call()
      root.value.hide()
    }

    onBeforeMount(() => {
      getVipQrcode()
      sendLog({
        event_type: '10000~50000',
        event_name: 30258,
        event_data: {
          str1: '通用',
          str2: '免费点歌次数用尽后弹窗',
          str3: '展示',
          str4: 'show',
        },
      })
    })

    return {
      root,
      qrCodeURL,
      handleCloseModal,
    }
  }
}
</script>
<style lang="stylus" scoped>
.activity-nofree-vip
  padding-top 0 !important
  position relative
  width 710px
  height 524px
  background url('https://qncweb.ktvsky.com/20240806/vadd/d9e880acf236091b74211643a3b77bbe.png') no-repeat
  background-size 100% 100%
  background-position center
  color rgba(255, 51, 100, 1)
  display flex
  flex-direction column
  align-items center
  &-close
    width 40px
    height 40px
    position absolute
    top 32px
    right 32px
  &-bottom
    width 100%
    height 212px
    padding-left 149px
    display flex
    // justify-content center
    position absolute
    bottom 113px
    left 0
  &-code
    width 180px
    height 212px
    background #fff
    border-radius 12px
    display flex
    flex-direction column
    align-items center
    img
      width 160px
      height 160px
      margin-top 10px
    span
      height 23px
      line-height 23px
      font-weight 400
      font-size 20px
      color rgba(11, 104, 81, 1)
      margin-top 10px
</style>
