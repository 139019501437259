import http from '@/utils/httpV2'
import get from 'lodash/get'

export async function getSongM3U8(songid) {
  if (!songid) return {}
  const result = await http.get('/stb/v2/detail/song', {
    params: {
      songid
    }
  })
  return {
    ...get(result, 'data', {}),
    msg: get(result, 'errmsg', ''),
    error: result === undefined
  }
}

export async function getSongToken() {
  // if (!songid) return {};
  const result = await http.get('/stb/v2/detail/song/token')
  return get(result, 'data', {})
}

export async function getSongLrc(songid, lrc) {
  if (!songid) return {}
  const result = await http.get('/stb/v2/detail/song/lrc', {
    params: {
      songid,
      lrc
    }
  })
  return {
    ...get(result, 'data', {}),
    msg: get(result, 'errmsg', ''),
    error: result === undefined
  }
}