const state = {
  song: [],
}

const getters = {}

const actions = {
  addSong({ commit }, data) {
    commit('UPDATE_VIP_SONG', [data])
    console.log('addSong:', data)
  },
  resetSong({ commit }) {
    commit('UPDATE_VIP_SONG', [])
    console.log('resetSong:', [])
  }
}

const mutations = {
  UPDATE_VIP_SONG(state, data) {
    state.song = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}