import Toast from '@/utils/toast'
import QRCode from 'qrcode'
import { sendLog } from '@/directives/v-log/log'

export default function useQRCode() {

  const getQRCodeURL = async (url) => {
    if (url) {
      try {
        const qrCodeURL = await QRCode.toDataURL(url, {
          errorCorrectionLevel: 'H',
          margin: 0
        })
        sendLog({
          event_type: 'show',
          event_name: 204,
        })
        return qrCodeURL
      } catch (err) {
        console.log(err)
        Toast('二维码生成失败')
        return false
      }      
    }
  }

  return {
    getQRCodeURL
  }
}