<template>
  <div class="adj-volume">
    <div class="adj-volume-title">
      <div class="left">调音</div>
      <img
        class="close"
        src="https://qncweb.ktvsky.com/20231212/other/f3e0b3ce8cd5bbc595aa74a5199aa046.svg"
        @click="$emit('close')"
      >
    </div>
    <div class="adj-volume-main">
      <EffectSlider
        :defaultValue="videoVolume"
        @update="onVolumeChange"
      >
        <div class="adj-volume-main-item">
          伴奏
        </div>
      </EffectSlider>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import store from '@/store'
import EffectSlider from './slider.vue'
import eventBus from '@/utils/event-bus'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'AdjVolume',
  components: {
    EffectSlider,
  },
  setup() {
    const videoVolume = computed(() => store.state.videoVolume)
    const onVolumeChange = (val) => {
      store.commit('UPDATE_MV_VIDEO_VOLUME', val)
      eventBus.emit('effect-adjust-tuner-volume-change', val)
      sendLog({
        event_type: '10000~50000',
        event_name: 10093,
        event_data: {
          str1: '调音',
          str2: '伴奏',
          str3: '调节伴奏',
          str4: 'click',
        },
      })
    }

    return {
      videoVolume,
      onVolumeChange
    }
  },
}
</script>

<style lang="stylus" scoped>
.adj-volume
  width 100%
  height 100vh
  padding 0 48px
  position relative
  &-title
    display flex
    justify-content space-between
    align-items center
    margin 22px 0
    .left
      display flex
      align-items center
      height 120px
      color rgba(255, 255, 255, 0.80)
      font-size 32px
    .close
      width 25px
  &-main
    width 100%
    height auto
    &-hint
      font-size 28px
      color rgba(255, 255, 255, .4)
      margin-bottom 8px
    &-item
      background url(https://qncweb.ktvsky.com/20231218/other/4be675b4a158addf272b4ebd8f1ae2e0.svg) no-repeat top center
      background-size 40px 40px
      padding-top 46px
  @media screen and (max-width 1200px) and (min-height 1200px)
    &-title
      margin 0
      .left
        font-size 26px
    &-main
      margin-top 55px
      &-item
        font-size 20px
        background-size 32px 32px
</style>