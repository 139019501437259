<template>
  <CommonModal
    ref="root"
  >
    <div class="effect-adjust-modal-content">
      <img @click="handleCloseLogin" class="close" src="https://qncweb.ktvsky.com/20211109/vadd/8c10127469ec6e740a3de5c76dee9f66.png"/>
      <!-- <div class="tabs">
        <div
          class="tab"
          :class="{ 'active':curSearchTab === tab.name }"
          v-for="tab in tabs"
          :key="tab.name"
          @click="handleSwitchTab(tab.name)"
        >
          {{ tab.name }}
        </div>
      </div>
      <div>
        <TunerPaner v-show="curSearchTab === '调音'"></TunerPaner>
        <AtmospherePanel v-show="curSearchTab === '气氛'" />
      </div> -->

      <div class="title">{{ name }}</div>

      <TunerPaner v-show="name === '调音'" />
      <AtmospherePanel v-show="name === '气氛'" />
    </div>
  </CommonModal>
</template>
<script>
import { ref } from 'vue'
import CommonModal from '@/components/modal/common/component.vue'
import AtmospherePanel from './components/atmosphere.vue'
import TunerPaner from './components/tuner.vue'

export default {
  name: 'EffectAdjustModal',
  components: {
    CommonModal,
    AtmospherePanel,
    TunerPaner,
  },
  props: {
    name: {
      type: String,
      default: '调音' // 气氛
    }
  },
  setup() {
    const root = ref(null)

    const tabs = [{
      name: '调音',
    }, {
      name: '气氛'
    }]

    let curSearchTab = ref(tabs[0].name)

    const handleSwitchTab = (name) => {
      curSearchTab.value = name
    }

    const handleCloseLogin = () => {
      root.value.hide()
    }

    return {
      root,
      tabs,
      curSearchTab,
      handleSwitchTab,
      handleCloseLogin,
    }
  }
}
</script>
<style lang="stylus" scoped>
.effect-adjust
  &-modal
    &-content
      padding-top 30px
      position relative
      width 1000px
      height 700px
      background #1E1F21
      border-radius 20px
      color rgba(255, 255, 255, .8)
      display flex
      flex-direction column
      align-items center
      .close
        position absolute
        top 30px
        left 30px
        width 90px
        height 90px
      .title
        font-size 40px
        color rgba(255, 255, 255, 0.8)
        margin-top 15px
        font-weight 400
      .tabs
        width 482px
        height 90px
        background #383A3E
        border-radius 14px
        padding 14px
        margin 0
        .tab
          display flex
          align-items center
          justify-content center
          width 220px
          height 64px
          border-radius 10px
          color rgba(255, 255, 255, 0.5)
          font-size 32px
          &:first-child
            float left
          &:nth-child(2)
            float right
        .active
          background rgba(255, 255, 255, 0.15)
          color rgba(255, 255, 255, 0.8)
</style>
