import {computed, ref} from 'vue'
import createRetainModal from '@/components/modal/global/retain-modal/create' // 引入 createRetainModal
import store2 from 'store2'
import {format} from 'date-fns'
import {sendLog} from "@/directives/v-log/log";
import store from "@/store";

export default function useRetainModal() {
    const $retainModal = createRetainModal() // 使用 createRetainModal 创建API
    const retainModalInstance = ref(null)
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const showRetainModal = () => {
        retainModalInstance.value = $retainModal.show()
    }

    const setRetainModalTag = () => {
        const currDate = format(Date.now(), 'yyyy-MM-dd')
        store2('retainModalTag', {
            time: currDate
        })
    }

    const getRetainModalTag = () => {
        const currDate = format(Date.now(), 'yyyy-MM-dd')
        if (!store2('retainModalTag')) {
            return false
        }
        const {time} = store2('retainModalTag')
        if (currDate !== time) {
            return false
        }
        return true
    }

    // 返回包含所有函数的对象
    return {
        showRetainModal,
        setRetainModalTag,
        getRetainModalTag,
    }
}