<template>
  <div class="mv-control-guide">
    <div class="menu-item" @click.stop="handleShowSideBar('快速点歌', 'left', 10070)">
      <img class="menu-item-icon" src="https://qncweb.ktvsky.com/20231102/vadd/fa015ce36c8013015815d1a708a5075b.png"/>
      <div class="menu-item-text">快速点歌</div>
    </div>
    <div class="mv-control-guide-center">
      <p>点击两侧都可以快速点歌喔~</p>
      <div @click="$emit('close-control-guide')">知道啦</div>
    </div>
    <div class="menu-item right" @click.stop="handleShowSideBar('快速点歌', 'right', 10070)">
      <img class="menu-item-icon" src="https://qncweb.ktvsky.com/20231102/vadd/fa015ce36c8013015815d1a708a5075b.png"/>
      <div class="menu-item-text">快速点歌</div>
    </div>
  </div>
</template>
<script>
import { useShareBrowserSize } from '@/composables/sharedComposable'

export default {
  name: 'ControlGuide',
  setup(props, { emit }) {
    const { browserType } = useShareBrowserSize()

    const handleShowSideBar = (name, pos, logname) => {
      emit('show-side-bar', {
        name,
        pos: browserType.value === 'landscape' ? pos : 'bottom'
      })
      emit('close-control-guide')
    }

    return {
      handleShowSideBar
    }
  }
}
</script>

<style lang="stylus" scoped>
.mv-control-guide
  width 100vw
  height 100vh
  position fixed
  left 0
  top 0
  z-index 20
  background: rgba(0, 0, 0, 0.90);
  display flex
  justify-content center
  align-items center
  .menu-item
    position fixed
    top 50%;
    left 40px
    margin-top -65px
    width: 130px;
    height: 130px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.10);
    display flex
    flex-direction column
    justify-content center
    align-items center
    color: rgba(255, 255, 255, 0.50);
    font-size: 22px;
    &-icon
      width 80px
    &.right
      left unset
      right 40px
  &-center
    background url(https://qncweb.ktvsky.com/20231215/other/48e937945a6c6cc7f241e7873fb8b10c.png) no-repeat top center
    background-size 132px auto
    text-align center
    p
      color: rgba(255, 255, 255, 0.70);
      font-size: 36px;
      padding 70px 0 47px
    div
      width: 300px;
      height: 100px;
      border-radius: 200px;
      background: rgba(255, 255, 255, 0.10);
      font-size: 32px;
      color: rgba(255, 255, 255, 0.80);
      display flex
      align-items center
      justify-content center
      margin 0 auto
</style>
