import { createComponent } from '@/components/modal/utils/index.js'
import VipModalVue from './index.vue'
import VipLoggedInModalVue from './loggedIn.vue'

export default function useVipQrcode(globalProps = {}, globalSlots = {}) {
  function show(Component, props = globalProps, slots = globalSlots) {
    const forceProps = {
      programmatic: true,
      lockScroll: true,
      isFullPage: true,
      active: true,
    }

    const propsData = { ...globalProps, ...props, ...forceProps }
    const container = document.body

    const mergedSlots = { ...globalSlots, ...slots }
    const instance = createComponent(
      Component,
      propsData,
      container,
      mergedSlots
    )

    return {
      hide: instance.refs.root.hide,
    }
  }
  return {
    show(props = globalProps, slots = globalSlots) {
      if (props.isLogin) {
        return this.showLoggedin(props, slots)
      }
      return show(VipModalVue, props, slots)
    },
    showLoggedin(props = globalProps, slots = globalSlots) {
      return show(VipLoggedInModalVue, props, slots)
    },
  }
}
