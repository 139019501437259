import RecommendComponent from './index.vue'
import useRecommendConfrim from './create'

const Plugin = (app, props = {}, slots = {}) => {
  const RecommendConfirm = useRecommendConfrim(props, slots);
  app.config.globalProperties.$RecommendConfirm = RecommendConfirm;
  app.provide('$RecommendConfirm', RecommendConfirm)
};

RecommendComponent.install = Plugin;

export default RecommendComponent;
