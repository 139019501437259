<template>
  <div v-if="isShowSonginfoBar" class="songinfo-bar">
    <Vue3Marquee>
      <div v-show="current" class="songinfo-bar-current">
        <div class="marquee">正在演唱：{{ currentSongTitle }}</div>
      </div>
      <div class="songinfo-bar-next">
        <div v-if="nextSongTitle">下一首：{{ nextSongTitle }}</div>
        <div v-else class="next-song">下一首：没有歌曲啦，快去点歌吧</div>
      </div>
    </Vue3Marquee>
  </div>
</template>

<script>
import { reactive, onUnmounted, toRefs, watch } from 'vue'
import useOrder from '@/composables/useOrder'
import { Vue3Marquee } from 'vue3-marquee'

export default {
  name: 'SongInfoBar',
  components: {
    Vue3Marquee,
  },
  props: {
    currentSong: Object,
    nextSong: Object,
  },
  setup(props) {
    const state = reactive({
      current: props.currentSong || {},
      currentSongTitle: props.currentSong ? `${props.currentSong.music_name}-${props.currentSong.singer}` : '',
      nextSongTitle: props.nextSong ? `${props.nextSong.music_name}-${props.nextSong.singer}` : '',
      isShowSonginfoBar: true,
    })

    const { orderedList } = useOrder()

    const SHOW_DURATION = 30 * 1000 // 30秒
    const HIDE_DURATION = 2 * 60 * 1000 // 2分钟
    let timerId = null

    const handleShowBar = () => {
      state.isShowSonginfoBar = true
      setTimeout(() => {
        state.isShowSonginfoBar = false
      }, SHOW_DURATION)
      timerId = setTimeout(() => {
        handleShowBar()
      }, HIDE_DURATION)
    }

    if (!props.currentSong || !props.nextSong) {
      watch(orderedList.value, ([currentSong, nextSong]) => {
        try {
          if (currentSong?.songid !== state.current?.songid) {
            clearTimeout(timerId)
            handleShowBar()
          }
          state.current = currentSong
          state.currentSongTitle = `${currentSong?.music_name}-${currentSong?.singer}`
          state.nextSongTitle = nextSong
            ? `${nextSong?.music_name}-${nextSong?.singer}`
            : ''
        } catch (error) {
          // 处理错误
          console.error(error)
        }
      })
    }

    onUnmounted(() => {
      clearTimeout(timerId)
    })

    return {
      ...toRefs(state),
    }
  },
}

</script>

<style lang="stylus" scoped>
.songinfo-bar
  width: 800px;
  height: 100px;
  border-radius: 14px;
  padding 0 40px
  background: rgba(30, 31, 33, 0.7);
  display flex
  align-items center
  position fixed
  z-index 10
  top 43px
  left 50%
  margin-left -400px
  color #fff
  font-size 30px
  &-current
    white-space nowrap
    margin-right 100px
    padding-left 48px
  &-next
    white-space nowrap
    color: rgba(255, 255, 255, 0.50);
    margin-right 100px
</style>

