<template>
  <div class="tuner">
    <p>麦克风与混响功能暂未开放</p>
    <div class="tuner-list">
      <EffectSlider
        :defaultValue="videoVolume"
        @update="onVolumeChange"
      >
        伴奏
      </EffectSlider>
      <EffectSlider
        :disabled="true"
      >
        麦克风
      </EffectSlider>
      <EffectSlider
        :disabled="true"
      >
        混响
      </EffectSlider>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import store from '@/store'
import EffectSlider from './slider.vue'
import eventBus from '@/utils/event-bus'

export default {
  name: 'EffectAdjustTunerPanel',
  components: {
    EffectSlider,
  },
  setup() {
    const videoVolume = computed(() => store.state.videoVolume)
    const onVolumeChange = (val) => {
      store.commit('UPDATE_MV_VIDEO_VOLUME', val)
      eventBus.emit('effect-adjust-tuner-volume-change', val)
    }

    return {
      videoVolume,
      onVolumeChange
    }
  },
}
</script>
<style lang="stylus" scoped>
.tuner
  display: flex
  flex-direction: column
  align-content: center
  p
    text-align center
    font-size 26px
    opacity .8
    color rgba(255, 255, 255, .5)
    margin 30px 0
  &-list
    display: flex
    justify-content: space-between
    // margin-top 59px
    width 600px
</style>