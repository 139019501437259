<template>
    <SecContainer
      title="猜你会唱"
      class="sec-gusse-sing"
    >
      <template #header>
        <div v-if="!showAllData" class="sec-gusse-sing-change" @click.stop="handlechangePagenation">
          <span>换一批</span>
          <img src="https://qncweb.ktvsky.com/20231206/vadd/c48ff9a1b6f6d161eebea8d8acf64751.png" alt="">
        </div>
      </template>
      <div class="sec-gusse-sing-list">
        <SongItem
          :renderType="renderType"
          :isStickButton="isStickButton"
          className="sec-gusse-sing-list-item"
          v-for="(songItem, ind) in guessSongShowList"
          :key="ind"
          :songItem="songItem"
          :log-from="{
            song_list_source: song_list_source ? song_list_source : (isGuessSongList && songItem.sing_cnt) ? 5 : 9,
            str1: pageRoute
          }"
          :ponitActionLog="ponitActionLog"
          @singer-click="handleClickSinger"
        />
      </div>
    </SecContainer>
</template>

<script>
import { onMounted, ref, computed, watch, toRefs } from 'vue'
import SecContainer from '@/components/section-container/index.vue'
import SongItem from "@/components/song-item/index.vue"
import { useStore } from 'vuex'
import { sendLog } from '@/directives/v-log/log'

function generateGuessSongList(oftenSingList, top50List) { // “唱过的歌”和“热唱top100”交替展示（2个唱过的歌 + 1个热唱循环）
  let guessSongList = [];
  let oftenSingIndex = 0;
  let top50Index = 0;

  while (oftenSingIndex < oftenSingList.length && top50Index < top50List.length) {
    guessSongList.push(oftenSingList[oftenSingIndex]);
    oftenSingIndex++;

    if (oftenSingIndex < oftenSingList.length) {
      guessSongList.push(oftenSingList[oftenSingIndex]);
      oftenSingIndex++;
    }

    guessSongList.push(top50List[top50Index]);
    top50Index++;
  }

  // 将剩余的歌曲添加到列表中
  guessSongList = guessSongList.concat(oftenSingList.slice(oftenSingIndex));
  guessSongList = guessSongList.concat(top50List.slice(top50Index));

  return guessSongList;
}

export default {
  name: 'GuessSongList',
  components: {
    SongItem,
    SecContainer,
  },
  props: {
    // 渲染形态
    renderType: {
      default: 'list', // list:列表形态 block：块状形态
      type: String,
    },
    // 是否显示置顶按钮
    isStickButton: {
      default: true,
      type: Boolean
    },
    pageSize: {
      default: 6,
      type: Number
    },
    showAllData: {
      default: false,
      type: Boolean
    },
    pageRoute: {
      default: '1', // 1：首页 2：二级页面 3：mv页
      type: String,
    },
    ponitActionLog: {
      default: () => { // 点歌位置上报 - 10000~50000
        return {
            event_type: '10000~50000',
            event_name: 10017,
            event_data: {
              str1: '首页',
              str2: '猜你会唱',
              str3: '歌曲列表',
              str4: 'click',
            },
          }
      },
      type: Object,
    },
    song_list_source: {
      default: 0,
      type: Number,
    }
  },
  emits: ['singer-click'],
  setup(props, { emit }) {
    const store = useStore()
    const isLogin= computed(() => !!store.state.userInfo.unionid)
    const oftenSingList = computed(() => store.state.oftenSing.oftenSingList)
    const isGuessSongList = computed(() => isLogin.value && oftenSingList.value.length)
    const top50List = computed(() => store.state.oftenSing.top50)
    const { pageSize, showAllData } = toRefs(props)
    let page = 1
    let guessSongShowList = ref([])

    const guessSongShowListUpdate = () => {
      let guessSongList = isGuessSongList.value ? generateGuessSongList(oftenSingList.value, top50List.value) : top50List.value;

      if (showAllData.value) {
        guessSongShowList.value = guessSongList;
        page = 1; // 重置 page 为 1
        return;
      }

      let startIndex = (page - 1) * pageSize.value;
      let endIndex = page * pageSize.value;

      if (startIndex >= guessSongList.length) {
        guessSongShowList.value = [];
        page = 1; // 重置 page 为 1
      } else {
        guessSongShowList.value = guessSongList.slice(startIndex, Math.min(endIndex, guessSongList.length));
      }

      if (guessSongShowList.value.length === 0) {
        page = 1;
        startIndex = 0;
        endIndex = pageSize.value;
        guessSongShowList.value = guessSongList.slice(startIndex, Math.min(endIndex, guessSongList.length));
      }
    }

    const handlechangePagenation = () => {
      if (!oftenSingList.value.length && !top50List.value.length) return
      ++page
      guessSongShowListUpdate()
      sendLog({
        event_type: '10000~50000',
        event_name: 10018,
        event_data: {
          str1: '首页',
          str2: '猜你会唱列表',
          str3: '换一换',
          str4: 'click',
        },
      })
    }

    const resetPage = () => {
      page = 1
      guessSongShowListUpdate()
    }

    const handleClickSinger = (data) => {
      emit('singer-click', data)
    }

    watch(isLogin, () => {
      resetPage()
    })

    watch(oftenSingList, () => {
      resetPage()
    })

    watch(top50List, () => {
      resetPage()
    })

    onMounted(() => {
      resetPage()
    })

    return {
      isLogin,
      isGuessSongList,
      guessSongShowList,
      handlechangePagenation,
      handleClickSinger,
    }
  }
}
</script>

<style lang="stylus" scoped>
.sec-gusse
  &-sing
    margin-bottom 40px
    ::-webkit-scrollbar
      display none
    &-list
      min-height 400px
      display grid
      grid-template-columns repeat(3, 560px)
      justify-content space-between
      position relative
      ::v-deep .song-item
        height 137px
      @media screen and (max-width 1200px)
        grid-template-columns repeat(3, 344px)
        min-height 304px
        overflow hidden
        ::v-deep .song-block
          width 344px
          height 140px
          margin-bottom 24px
          .name
            font-size 26px
          .desc
            font-size 20px
    &-change
      width 122px
      height 40px
      display flex
      align-items center
      justify-content center
      span
        width auto
        height 40px
        margin-right 5px
        color rgba(255, 255, 255, 0.6)
        font-size 28px
      img
        width 32px
        height 32px
    @media screen and (max-width 1200px)
      ::v-deep .section-container-header
        padding-bottom 32px
        .section-container-header-title
          font-size 26px
      &-change
        span
          font-size 22px
</style>