<template>
  <div class="floating-layer-wrapper" @click.stop="handleOutsideClick">
    <div class="floating-layer">
      <img src="https://qncweb.ktvsky.com/20240314/vadd/89cbc84ba7f2371ffba8f5f1bb1d502a.png" class="bg-image" />
      <img
        src="https://qncweb.ktvsky.com/20240314/vadd/2843cb35ad8692400f699eb263c57407.png"
        class="close-button"
        @click.stop="closeLayer"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'FloatingLayer',
  emits: ['open', 'close'],
  setup(props, { emit }) {

    const closeLayer = () => {
      emit('close')
    }

    const handleOutsideClick = () => {
      // 在这里添加您需要执行的操作
      emit('open')
    }

    return {
      closeLayer,
      handleOutsideClick
    }
  }
}
</script>

<style scoped>
.floating-layer-wrapper {
  position: fixed;
  top: 26px;
  left: 210px;
  z-index: 117;
}

.floating-layer {
  position: relative;
  width: 194px;
  height: 114px;
}

.bg-image {
  width: 180px;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  cursor: pointer;
}
</style>
