<template>
  <CommonModal ref="root">
    <div class="recommend-confirm-modal">
      <div class="recommend-confirm-modal-content">
        <div
          class="recommend-confirm-modal-content-item"
          :class="[selected === content.id && 'active']"
          v-for="content in contents"
          :key="content.name"
          @click="handleSelect(content.id)"
        >
          {{ content.name }}
        </div>
      </div>
      <div class="control">
        <div class="control-item" @click="handleConfirm">确定</div>
        <div class="control-item" @click="handleCancel">取消</div>
      </div>
    </div>
  </CommonModal>
</template>
<script>
import { computed, ref } from "vue";
// import { useStore } from "vuex";
import store from "@/store";
import CommonModal from "@/components/modal/common/component.vue";
import {
  setReduceRecommendSetting,
  setReduceMvRecommendSetting,
} from "@/utils/historyCache";
import { sendLog } from "@/directives/v-log/log";
import eventBus from "@/utils/event-bus";

export default {
  name: "RecommendConfirm",
  // props: {

  // },
  components: {
    CommonModal,
  },
  setup(props, { emit }) {
    // const store = useStore();
    const root = ref(null);
    const contents = ref([
      {
        id: 1,
        name: "保持推荐",
      },
      {
        id: 2,
        name: "减少推荐频率",
      },
      {
        id: 3,
        name: "从不推荐",
      },
    ]);
    const selected = ref(1);

    const mvIsHide = computed(() => store.state.mvIsHide);

    const handleSelect = (id) => {
      selected.value = id;
    };

    const handleConfirm = () => {
      if (selected.value === 2) {
        sendLog({
          event_type: "10000~50000",
          event_name: 30264,
          event_data: {
            str1: mvIsHide.value ? "首页" : "欢唱页",
            str2: "智能推荐-设置弹窗-减频确定",
            str3: "点击",
            str4: "click",
          },
        });
        if (mvIsHide.value) eventBus.emit("handle-show-home-recommend");
      } else if (selected.value === 3) {
        sendLog({
          event_type: "10000~50000",
          event_name: 30265,
          event_data: {
            str1: mvIsHide.value ? "首页" : "欢唱页",
            str2: "智能推荐-设置弹窗-从不确定",
            str3: "点击",
            str4: "click",
          },
        });
      }
      emit("confirm");
      if (mvIsHide.value) {
        setReduceRecommendSetting(selected.value);
      } else {
        setReduceMvRecommendSetting(selected.value);
      }
      handleCancel("confirm");
    };
    const handleCancel = (type = "cancel") => {
      if (type === "cancel") {
        sendLog({
          event_type: "10000~50000",
          event_name: 30263,
          event_data: {
            str1: mvIsHide.value ? "首页" : "欢唱页",
            str2: "智能推荐-设置弹窗-取消",
            str3: "点击",
            str4: "click",
          },
        });
      }

      emit("cancel");
      root.value.hide();
    };

    return {
      root,
      selected,
      contents,
      handleSelect,
      handleConfirm,
      handleCancel,
    };
  },
};
</script>
<style lang="stylus" scoped>
.recommend-confirm-modal
  position relative
  display flex
  flex-direction column
  align-items center
  width 948px
  height 450px
  font-size 32px
  background rgba(43, 48, 59, 0.7)
  &-content
    display flex
    flex-direction row
    align-items center
    width 780px
    height 180px
    font-size 28px
    margin-top 64px
    &-item
      width 240px
      height 180px
      display flex
      align-items center
      justify-content center
      // gap 24px
      border-radius 16px
      color rgba(255, 255, 255, 1)
      font-size 28px
      background linear-gradient(93.55deg, rgba(56, 58, 62, 0.9) 0.37%, rgba(56, 58, 62, 0.5) 98.8%);
      // margin-right 24px
      &:nth-child(2)
        margin 0 24px
    .active
      border 4px solid rgba(219, 174, 106, 1)
      // background rgba(255, 255, 255, 1)
      // color rgba(56, 58, 62, 1)
  .control
      display flex
      justify-content center
      margin-top 40px
      &-item
        display flex
        justify-content center
        align-items center
        border-radius 4px
        background transparent
        font-size 28px
        color rgba(255, 255, 255, 0.8)
        width 300px
        height 80px
        border 2px solid rgba(255, 255, 255, 0.1)
        &:nth-child(1)
          border none
          margin-right 40px
          color rgba(255, 255, 255, 1)
          background rgba(255, 255, 255, 0.1)
  @media screen and (max-width 1200px) and (min-height 1200px)
    // width 640px
    // height 563px
    zoom 0.8
</style>
