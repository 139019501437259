import ModalComponent from './index.vue'
import useCreate from './create.js';

const Plugin = (app, props = {}, slots = {}) => {
  const createFunction = useCreate(props, slots);
  app.config.globalProperties.$mobileOrderQrcodeModal = createFunction;
  app.provide('$mobileOrderQrcodeModal', createFunction)
};

ModalComponent.install = Plugin;

export default ModalComponent;
