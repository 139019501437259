<template>
  <CommonModal ref="root">
    <div class="activity-modal-kuaichang">
      <!-- <div class="time">{{newdelay}}<span>S</span></div> -->
      <div class="title">当前歌曲</div>
      <div class="song-info">
        <div class="song-name">{{currentSong.value.music_name}}</div>
        <div class="song-singer">{{currentSong.value.singer}}</div>
      </div>
      <div class="nextsong">下一曲：{{nextSong.value.music_name}}-{{nextSong.value.singer}}</div>
    </div>
  </CommonModal>
</template>
<script>
import { ref, onBeforeMount, toRefs, onMounted, onUnmounted } from 'vue'
import CommonModal from '@/components/modal/common/component.vue'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'ActivityKuaichangModal',
  components: {
    CommonModal,
  },
  props: {
    delay: {
      type: Number,
      default: 3,
    },
    currentSong: {
      type: Object,
      default: () => ({}),
    },
    nextSong: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const root = ref(null)
    // const newdelay = ref(props.delay)
    // eslint-disable-next-line vue/no-setup-props-destructure
    const { currentSong, nextSong } = toRefs(props)
    console.log(currentSong.value, nextSong.value)
    const handleCloseModal = () => {
      root.value.hide()
    }
    // let timer = null;
    // const startTimer = () => {
    //   timer = setInterval(() => {
    //     if (newdelay.value > 1) {
    //       newdelay.value--;
    //     } else {
    //       handleCloseModal();
    //       clearInterval(timer);
    //       timer = null;
    //     }
    //   }, 1000);
    // };
    // onMounted(() => {
    //   startTimer()
    // })
    // onUnmounted(() => {
    //   if (timer) {
    //     clearInterval(timer); // 组件销毁时清除定时器
    //     timer = null;
    //   }
    // });
    onBeforeMount(() => {
      sendLog({
        event_type: '10000~50000',
        event_name: 30117,
        event_data: {
          str1: 'k歌人生运营位',
          str2: '入口弹窗',
          str3: '弹窗展示',
          str4: 'show',
        },
      })
    })

    return {
      root,
      // newdelay,
      // timer,
      handleCloseModal,
    }
  }
}
</script>
<style lang="stylus" scoped>
.activity-modal-kuaichang
  position relative
  width 500px
  height 290px
  background url(https://qncweb.ktvsky.com/20240618/vadd/b7956ec806c323da538359b2a49674fa.png) no-repeat
  background-size 100% 100%
  background-position center
  display flex
  flex-direction column
  align-items center
  .time
    font-size 56px
    font-weight 700
    line-height 65.63px
    text-align center
    color #fff
    margin-top 17px
    span 
      font-size 40px
      font-weight 700
      line-height 56.26px
  .title
    font-size 22px
    font-weight 400
    height auto
    line-height 25.78px
    color rgba(54, 50, 66, 1)
    margin-top 50px
  .song-info
    margin-top 20px
    .song-name
      font-size 40px
      font-weight 700
      line-height 46.88px
      background linear-gradient(90deg, #8F3367 0%, #04030A 30%)
      -webkit-background-clip text
      -webkit-text-fill-color transparent
    .song-singer
      font-size 24px
      font-weight 400
      line-height 28.13px
      text-align center
      background linear-gradient(90deg, #8F3367 0%, #04030A 30%)
      -webkit-background-clip text
      -webkit-text-fill-color transparent
      margin-top 8px
.nextsong
  font-size 22px
  font-weight 400
  line-height 25.78px
  text-align center
  color rgba(54, 50, 66, 0.6)
  margin-top 43px
</style>
