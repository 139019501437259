// 处理歌词数据
import get from 'lodash/get'

const convertToSeconds = (s) => {
  let arr = s.split(':')
  return Number(arr[0]) * 60 + Number(arr[1])
}
const convertStrToArr = (s, length) => {
  if (length === 1) {
    return [s]
  }
  // 区分中文与非中文 非中文会有空格
  if (s.includes(' ')) {
    return s.split(' ').map((v) => v + ' ')
  }
  return s.split('')
}
const convertDelayTime = (arr) => {
  let delayArr = [0]
  const visualErrorTimer = 200 // 视差 ms
  arr.reduce((total, num) => {
    delayArr.push(total + num + visualErrorTimer)
    return total + num
  }, 0)
  delayArr.pop()
  return delayArr
}
const getComponentLrcData = (data) => {
  return data.length
    ? data.map((v) => {
        return {
          t: convertToSeconds(v[0]),
          irc: convertStrToArr(v[1], get(v, '[2]', []).length),
          textT: get(v, '[2]', []),
          delayTime: convertDelayTime(get(v, '[2]', [])),
        }
      })
    : []
}

export default getComponentLrcData
