import { useRouter } from 'vue-router'
// import { useStore } from 'vuex'
import { ref, inject, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { sendLog } from '@/directives/v-log/log'

let LoginFrom = ''

export default function useLoginValid() {
  const store = useStore()
  const $loginQrcode = inject('$loginQrcode')
  // 统一改为vipLogin的模块
  // const $vipLoginQrcode = inject('$vipQrcode')
  // 改回只login
  const $loginConfirm = inject('$loginConfirm')
  const loginQrcodeInstance = ref(null)
  const loginConfirmInstance = ref(null)

  const isLogin = computed(() => !!store.state.userInfo.unionid)
  const router = useRouter();

  const showLoginQrcode = (obj) => {
    // 此处需求变更 - 改回之前的login
    loginQrcodeInstance.value = $loginQrcode.show({
      ...obj,
    })

    LoginFrom = obj?.from || ''
    // loginQrcodeInstance.value = $vipLoginQrcode.show()
    store.dispatch('startCheckLoginStatus')
  }

  const showLoginConfirm = (props) => {
    loginConfirmInstance.value = $loginConfirm.show(props)
  }

  const checkUserLoginStatus = (from = '') => {
    if (isLogin.value) {
      return true
    }
    showLoginQrcode()
    return false
  }

  const handleIsLoginChange = (val) => {
    if (val && loginQrcodeInstance.value) {
      loginQrcodeInstance.value.hide()
      sendLog({
        event_type: '10000~50000',
        event_name: 10098,
        event_data: {
          str1: '任意页',
          str2: '登录弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 30206,
        event_data: {
          str1: '未登录点vip歌',
          str2: '弹窗',
          str3: '扫码',
        },
      })
    }
    if (LoginFrom === 'singing') {
      router.push({
        name: 'singing'
      })
    }
    LoginFrom = ''
  }

  watch(isLogin, handleIsLoginChange)

  return {
    isLogin,
    showLoginQrcode,
    showLoginConfirm,
    checkUserLoginStatus,
  }
}