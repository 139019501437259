import httpV2 from '@/utils/httpV2'
import get from 'lodash/get'

// 更新服务器演唱时长数据
export async function updateSingTaskTime({ unionid, song_time }) {
  const res = await httpV2.put(`/stb/v2/songtask/songtime?unionid=${unionid}&song_time=${song_time}`)
  return get(res, 'errmsg', '');
}

// 获取演唱时长及任务状态
export async function getSingTaskStatus({ unionid }) {
  const res = await httpV2.get('/stb/v2/songtask/songtime', {
    params: {
      unionid,
    }
  })
  return get(res, 'data', {});
}

// 领取演唱奖励
export async function getSingTaskVip({ unionid, free_day }) {
  const res = await httpV2.post(`/stb/v2/vip/free_songtask?unionid=${unionid}&free_time=${free_day}`)
  return get(res, 'errmsg', '');
}