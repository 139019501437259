<template>
  <div class="video-control-layer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VideoControlLayer',
}
</script>

<style lang="stylus" scoped>
.video-control-layer
  position fixed
  width 100vw
  height 100vh
  display flex
  flex-direction column
  justify-content center
  align-content center
  z-index 10
</style>
