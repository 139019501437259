import store2 from 'store2'
import { nanoid } from 'nanoid'

export function initBootId() {
  const _boot_id = nanoid(32).toUpperCase()
  store2('_boot_id', _boot_id)
  return _boot_id
}

export function initSessionID() {
  store2('_session_id', `f_${nanoid()}`)
}