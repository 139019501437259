<template>
  <CommonModal ref="root">
    <div class="activity-modal-alert">
      <img class="top-img" src="https://qncweb.ktvsky.com/20220831/vadd/d3ab59e887db628ea859dd0e0a35fb19.png"/>
      <div class="title">
        领取通知
      </div>
      <div class="content">
        {{text}}
      </div>
      <div @click="handleCloseModal" class="alert">我知道了</div>
    </div>
  </CommonModal>
</template>
<script>
import { ref } from 'vue'
import CommonModal from '@/components/modal/common/component.vue'

export default {
  name: 'ActivityModal',
  components: {
    CommonModal,
  },
  props: {
    text: {
      type: String,
      default: '对不起，您的账号已经领取过了，不可以再领取了！'
    },
    closeEvent: {
      type: Function,
      default: () => {
      }
    },
  },
  setup(props) {
    const root = ref(null)

    const handleCloseModal = () => {
      props.closeEvent.call()
      root.value.hide()
    }

    return {
      root,
      handleCloseModal,
    }
  }
}
</script>
<style lang="stylus" scoped>
.activity-modal-alert
  padding 130px 80px 0
  position relative
  width 800px
  height 494px
  background url(https://qncweb.ktvsky.com/20220826/vadd/f204d895e76caab9fe2540ddc41188b5.png) no-repeat
  background-size 100% 100%
  background-position center
  border-radius 20px
  color rgba(255, 255, 255, .8)
  display flex
  flex-direction column
  align-items center
  .top-img
    width 276px
    height 276px
    position absolute
    top -140px
    left 262px
  .title
    margin-bottom 38px
    font-size 40px
    font-weight 700
    line-height 1
    color rgba(240, 210, 144, 1)
  .content
    font-size 32px
    color rgba(255, 255, 255, 0.8)
  .alert
    width 300px
    height 90px
    font-size 32px
    font-weight 500
    margin-top 42px
    margin-right 40px
    border-radius 14px
    text-align center
    line-height 90px
    background linear-gradient(to right, #FBD7AF, #F1BE73)
    color rgba(107, 21, 21, 0.8)
</style>
