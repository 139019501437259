<template>
  <div class="back-home" @click.stop="back">
    <img src="https://qncweb.ktvsky.com/20231226/other/4ca25d2ac3821806e2fca3d97d725063.svg" alt="">
    <p>返回</p>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import eventBus from '@/utils/event-bus'
import useActivity from '@/composables/useActivity'
import { setFreeSongNoTimeShow, getFreeSongNoTimeShow } from '@/utils/historyCache'

export default {
  name: 'BackHome',
  setup() {
    const store = useStore()
    const route = useRoute()
    const { showActivityFreeSongNoTime } = useActivity()

    const isVipUser = computed(() => !!store.state.vipInfo.end_time)
    const freeVipNumber = computed(() => store.state.freeVipNumber)
    const _song = computed(() => store.state.videoPlayerHistory.songItem)

    let FNTInstance = ref(null)

    const showNoFreeSongVipModal = () => {
      const vipNoTimeShow = getFreeSongNoTimeShow()

      // console.log('vipNoTimeShow - vipNoTimeShow', vipNoTimeShow)
      // console.log('vipNoTimeShow - is_vip', _song.value.is_vip)
      // console.log('vipNoTimeShow - isVipUser', isVipUser.value)
      // console.log('vipNoTimeShow - freeVipNumber', freeVipNumber.value)


      // 需求逻辑变更 - 暂不在这里处理
      if (!vipNoTimeShow && _song.value.is_vip && !isVipUser.value) {
        console.log('back home')
        // if (freeVipNumber.value === 1) {
        //   FNTInstance.value = showActivityFreeSongNoTime()
        //   setFreeSongNoTimeShow(true)
        // }
      }
    }

    // 关闭mv页面
    const back = () => {
      if (route.name === 'climax') {
        eventBus.emit('handle-video-pause')
      }
      store.commit('UPDATE_MV_ISHIDE', true)

      showNoFreeSongVipModal()
    }

    return {
      back,
    }
  }
}
</script>

<style lang="stylus" scoped>
.back-home
  width 130px
  height 130px
  border-radius 10px
  background rgba(30, 31, 33, 0.70)
  backdrop-filter blur(15px)
  display flex
  flex-direction column
  align-items center
  // justify-content center
  position absolute
  top 40px
  left 40px
  z-index 11
  img
    width 80px
    height 80px
    margin-top 8px
  p
    height 28px
    line-height 28px
    font-size 22px
    color rgba(255, 255, 255, 0.80)
    margin-top -6px
  @media screen and (max-width 1200px) and (min-height 1200px)
    top 32px
    left 32px
    width 104px
    height 104px
    border-radius 8px
    backdrop-filter blur(12px)
    img
      width 64px
      height 64px
      margin-top 6px
    p
      height 23px
      line-height 23px
      font-size 19.2px
      color rgba(255, 255, 255, 0.80)
      margin-top -3px
</style>