// 0元购活动
import get from 'lodash/get'
import { getActZeroBuyStatus, getZeroBuySigninStatus, actZeroBuySignin, actZeroBuySubmit } from '@/service/actZeroBuy'
import { sendLog } from '@/directives/v-log/log'

let zbTimer = null
let zbTime = 0

const state = {
  zerobuyStatus: 0, // 任务状态
  signinCalendar: [], // 任务签到
  remaining_days: 0, // 活动剩余签到天数
  sign_count: 0, // 签到次数
}

const getters = {}

const actions = {
  async updateZerobuyStatus({ commit }, unionid) {
    const res = await getActZeroBuyStatus({ unionid })
    const status = get(res, '15.state', 0)
    if (!status) {
      commit('RESET_ZEROBUY_TASK_STATUS')
      return
    }
    commit('UPDATE_ZEROBUY_TASK_STATUS', {
      status,
      day: get(res, '15.remaining_days', 0),
      count: get(res, '15.sign_count', 0),
    })
  },
  async updateSigninStatus({ commit }, unionid) {
    const res = await getZeroBuySigninStatus({ unionid })
    commit('UPDATE_SIGNIN_CALENDAR', get(res, '15.sign_daily', []))
  },
  async compelteZerobuyTask({ dispatch }, unionid) {
    const res = await actZeroBuySubmit({ unionid })
    if (res) {
      dispatch('updateZerobuyStatus', unionid)
    }
  },
  async zerobuySignIn({ dispatch }, unionid) {
    sendLog({
      event_type: 'show',
      event_name: 1736,
      event_data: {
        str1: '3'
      }
    })
    const res = await actZeroBuySignin({ unionid })
    // 延时更新 避免服务端接口慢还没更新的情况
    setTimeout(() => {
      dispatch('updateZerobuyStatus', unionid)
      dispatch('updateSigninStatus', unionid)
    }, 2000)
  },
  resetZerobuyStatus({ commit }) {
    commit('RESET_ZEROBUY_TASK_STATUS')
  },
  startCheckZerobuyStatus({ dispatch, state }, unionid) {
    if (zbTimer) dispatch('clearCheckZerobuyStatus')
    zbTimer = setInterval(() => {
      if (zbTime >= 20 || state.zerobuyStatus !== 0) {
        dispatch('clearCheckZerobuyStatus')
      }
      zbTime++
      dispatch('updateZerobuyStatus', unionid)
      dispatch('updateSigninStatus', unionid)
    }, 3000)
  },
  clearCheckZerobuyStatus() {
    zbTime = 0
    if (zbTimer) clearInterval(zbTimer)
    zbTimer = null
  }
}

const mutations = {
  UPDATE_ZEROBUY_TASK_STATUS(state, data) {
    state.zerobuyStatus = data.status
    state.remaining_days = data.day
    state.sign_count = data.count
  },
  UPDATE_SIGNIN_CALENDAR(state, data) {
    state.signinCalendar = data
  },
  // 重置任务状态
  RESET_ZEROBUY_TASK_STATUS(state) {
    state.zerobuyStatus = 0
    state.signinCalendar = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
