export default function getBaseUrl (baseURLs = {
  pro: '//x.ktvsky.com',
  pre: '//xpre.ktvsky.com',
  test: '//x.stage.ktvsky.com'
}) {
  const isPro = process.env.NODE_ENV === "production"
  // 预发布http环境下后端接口不可用 返回301 让改回去 所以暂时注释掉
  const isPre = window.location.href.includes('teslapre.ktvsky')
  if (isPre) return baseURLs.pre
  if (isPro) {
    return baseURLs.pro;
  } else {
    return baseURLs.test;
  }
}
