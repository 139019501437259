import httpV2 from '@/utils/httpV2'
import get from 'lodash/get'
import config from '@/config'

export async function postSignIn({ unionid, mac_id }) {
  const res = await httpV2.post('/stb/v2/user/sign_in', {
      _src: config.src,
      unionid,
      mac_id
  })
  // console.log(res)
  return res;
}

export async function getSignInData({ unionid }) {
  const res = await httpV2.get(`/stb/v2/user/sign_in?unionid=${unionid}&state=2`)
  // console.log(res)
  return get(res, 'data', {})
}

export async function getSignInActivityStatus() {
  const res = await httpV2.get(`/stb/v2/user/sign_in_rule`)
  // console.log(res)
  return {
    errcode: get(res, 'errcode'),  // 200 时有签到活动
    errmsg: get(res, 'errmsg', ''),
    data: get(res, 'data', {})
  }
}