<template>
  <div
    :class="['floating-image', { 'mv-floating-image': from === 'mv', 'global-floating-image': from !== 'mv' && isShowFloating && mvIsHide }, routeName + '-float']"
    v-show="from === 'mv' ? isShowMvFloating : (isShowFloating && mvIsHide) && (routeName && routeName !=='home')"
    v-log="{
      event_type: 'show',
      event_name: from === 'mv' ? 30210 : 30207,
      event_data: {
        str1: from === 'mv' ? '欢唱页' : '全局',
        str2: 'ai换脸' + (from === 'mv' ? '横条' : '浮窗'),
        str3: '展示',
        str4: 'show',
        str5: isLogin ? '已登录' : '未登录',
      }
    }"
  >
    <div class="qrcode">
      <img :src="AIQrcode" />
    </div>
    <div v-if="mvIsHide" class="close" @click="from === 'mv' ? (isShowMvFloating = false) : (isShowFloating = false)"></div>
  </div>
</template>

<script>
import { ref, watch, toRefs, computed, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import { getAIShow, setAIShow } from '@/utils/historyCache.js'
import { useStore } from 'vuex';
import useQRCode from '@/composables/useQRCode'
import { getCarplayInfo } from '@/service/carplay-info'

export default {
  name: 'FloatingLayer',
  props: {
    from: {
      type: String,
      default: ''
    },
    songItem: {
      type: Object,
      default: () => ({})
    },
    mvIsHide: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { songItem, from } = toRefs(props)
    const route = useRoute()
    const store = useStore();
    const { getQRCodeURL } = useQRCode()
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const aiface = computed(() => store.state.base.aiface)
    // const _mac = computed(() => store.state.macAddress)
    const aiFaceQr = computed(() => store.state.aiFaceQr)
    const userType = computed(() => store.state.userInfo.userType)

    const isShowFloating = ref(true)
    const isShowMvFloating = ref(false)
    const routeName = computed(() => route.name)

    let AIQrcode = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')

    const getQrcode = async () => {
      console.log('aiFaceQr', aiFaceQr.value)
      if (!aiFaceQr.value) {
        const { data } = await getCarplayInfo()
        AIQrcode.value = await getQRCodeURL(`${data.aiface_qr}&log=${1888}&ut=${userType.value}`)
        return
      }
      AIQrcode.value = await getQRCodeURL(`${aiFaceQr.value}&log=${1888}&ut=${userType.value}`)
      // AIQrcode.value = await getQRCodeURL(`https://m.ktvsky.com/vadd/facechange/mv/p/r?f=${from.value === 'mv'? 'tesla_songpage' : 'tesla_rtad'}&_src=600114&_mac=${_mac.value}`)
    }

    onBeforeMount(getQrcode)

    watch([songItem, from], ([itemVal, fromVal]) => {
      if (aiface.value.includes(String(itemVal.songid))) {
        if (isShowMvFloating.value) return

        const tag = getAIShow()
        if (fromVal === 'mv' && tag) {
          isShowMvFloating.value = true
          setAIShow(typeof tag === 'number' ? tag + 1 : 1)
        }
      } else {
        isShowMvFloating.value = false
      }
    }, { immediate: true })

    watch(from, () => {
      getQrcode()
    })

    return {
      isShowFloating,
      isShowMvFloating,
      isLogin,
      AIQrcode,
      routeName,
    }
  }
}
</script>

<style scoped lang="stylus">
.floating-image {
  width: 280px;
  height: 160px;
  position: fixed;
  bottom: 17px;
  left: 40px;
  z-index: 110;
  background: url(https://qncweb.ktvsky.com/20240429/other/fa4284febaca30a036b0e4fc6928497d.png) no-repeat;
  background-size: 100% 100%;
  background-position: center;
  &.climax {
    display none
  }
  .close {
    position absolute
    width 50px
    height 50px
    right 0
    top -54px
    background url(https://qncweb.ktvsky.com/20240429/other/2ee3b77098ded3f096cadbc706962ee6.png)
    background-size 100%
  }
  .qrcode {
    position absolute;
    bottom 10px;
    right 10px;
    margin-left -86px;
    width 140px;
    height 140px;
    display flex;
    justify-content center;
    align-items center;
    background #fff;
    border-radius 10px;
    img {
      width 128px;
      height 128px;
    }
  }
}
.global-floating-image {
  bottom 128px
}
.aiface-float, .mv-floating-image{
  bottom: 17px;
  .close {
    background-image url(https://qncweb.ktvsky.com/20240429/other/99c74682c51800322b5b4ae3b401d29d.png)
  }
}
.mv-floating-image  {
  @media screen and (max-width 1200px) and (min-height 1200px) {
    bottom 860px
  }
}

.home-float
  @media screen and (max-width 1200px)
    bottom 250px
</style>
