<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div class="vip-modal-content">
      <div @click="handleCloseModal" class="close"></div>
      <div class="qrcode">
        <img :src="qrCodeURL" />
      </div>
      <div class="cancel" @click="handleClickCancel"></div>
    </div>
  </CommonModal>
</template>
<script>
import { onBeforeMount, ref, toRefs, computed } from 'vue'
import store from '@/store'
import CommonModal from '@/components/modal/common/component.vue'
import { getCarplayInfo } from '@/service/carplay-info'
import useQRCode from '@/composables/useQRCode'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { vipLogFrom } from '@/constants/index'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'VipModalQRcode',
  components: {
    CommonModal,
  },
  props: {
    songid: {
      type: Number,
      default: 0 // 当通过点歌打开弹框时增加二维码增加songid参数，埋点使用
    },
    log: {
      type: String,
      default: '首页-画质歌单-画质权益弹窗'
    },
    cancelEvent: {
      type: Function,
      default: () => {}
    },
  },
  setup(props) {
    const { songid, log } = toRefs(props)
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')

    const userType = computed(() => store.state.userInfo.userType)

    const getVipQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(`${data.pay_qr}&songid=${songid.value ? songid.value : ''}&log=${vipLogFrom.get(log.value)}&ut=${userType.value}`)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        sendLog({
          event_type: '10000~50000',
          event_name: 30183,
          event_data: {
            str1: '首页',
            str2: '画质歌单',
            str3: '画质权益弹窗展示',
            str4: 'show',
          },
        })
        return
      }
      Toast('未获取到登录二维码')
    }

    const handleCloseModal = () => {
      // 手动关闭时统一做一次用户信息更新 处理用户在个人中心模块-前往开通时，关闭弹窗刷新用户会员信息
      store.dispatch('getCarplayInfo')
      root.value.hide()
      sendLog({
        event_type: '10000~50000',
        event_name: 10094,
        event_data: {
          str1: '任意页',
          str2: 'VIP弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
    }

    const handleCancel = () => {
      // 点击其他区域关闭弹窗时，也去刷一次
      store.dispatch('getCarplayInfo')
      sendLog({
        event_type: '10000~50000',
        event_name: 10094,
        event_data: {
          str1: '任意页',
          str2: 'VIP弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
    }

    const handleClickCancel = () => {
      root.value.hide()
      props.cancelEvent.call()
      sendLog({
        event_type: '10000~50000',
        event_name: 30184,
        event_data: {
          str1: '首页',
          str2: '画质歌单',
          str3: '画质权益弹窗拒绝按钮点击',
          str4: 'click',
        },
      })
    }

    onBeforeMount(getVipQrcode)

    return {
      qrCodeURL,
      root,
      isLogin,
      handleCancel,
      handleCloseModal,
      handleClickCancel,
    }
  }
}
</script>
<style lang="stylus" scoped>
.vip-modal
  &-content
    position relative
    width 1000px
    height 760px
    padding-top 0 !important
    background url(https://qncweb.ktvsky.com/20240305/other/aa607e40bc9cba7ca9f63431f4355fc8.png) no-repeat
    background-size 100% 100%
    background-position center
    @media screen and (max-width 1200px)
      zoom 0.8
    .cancel
      color #fff
      position absolute
      bottom 28px
      left 50%
      width 192px
      height 50px
      margin-left -96px
    .close
      position absolute
      top 30px
      right 30px
      left unset!important
      width 40px
      height 40px
    .vip-top-img
      width 242px
      height 138px
      position absolute
      top -70px
      left 379px
    .title
      margin 120px 0 47px
      width 1000px
      height 309px
      display flex
      justify-content center
      img
        width 700px
        height 309px
    .qrcode
      position absolute
      bottom 88px !important
      left 50%
      margin-left -86px
      width 172px
      height 172px
      display flex
      justify-content center
      align-items center
      background url(https://qncweb.ktvsky.com/20240201/vadd/edc5c9b5d66ba253b7694fe88db97c54.png) no-repeat
      background-size 100% 100%
      border-radius 10px
      @media screen and (max-width 1200px)
        bottom 25px
      img
        width 180px
        height 180px
        border-radius 5px
      &-txt
        width 226px
        height 49px
        position absolute
        bottom -42px
        left -18px
        background linear-gradient(90deg, #FF3D3D 0%, #FF881A 100%)
        font-size 24px
        color #fff
        text-align center
        line-height 49px
        border-radius 30px
        padding-left 10px
    .tip
      font-size 32px
      text-align center
      display flex
      flex-direction column
      align-items center
      span
        display flex
        align-items center
      img
        width 45px
        height 45px
        margin-right 10px
</style>
