import { sendLog } from './log';
import ShowClass from './show';

const show = new ShowClass({
  primaryKey: 'show_id',
});

function clickLog(el, value) {
  sendLog(value);
}

const bindTypeMap = {
  pv(el, value) {
    sendLog(value);
  },
  show(el, value) {
    show.add.bind(show)(el, value);
  },
  click(el, value) {
    el.onclick = () => {
      clickLog(el, value);
    };
  },
};

const updateTypeMap = {
  show(el, value, oldValue) {
    show.update.bind(show)(el, value, oldValue);
  },
  click(el, value) {
    el.onclick = () => {
      clickLog(el, value);
    };
  },
};

const unbindTypeMap = {
  show(el) {
    show.remove.bind(show)(el);
  },
  click(el) {
    el.onclick = null;
  },
};

export default {
  beforeMount(el, { value }) {
    const type = value.event_type;

    if (bindTypeMap[type]) {
      bindTypeMap[type](el, value);
    }
  },
  update(el, { value, oldValue }) {
    if (value.event_type) {
      updateTypeMap[value.event_type](el, value, oldValue);
      return;
    }
    updateTypeMap.click(el, value, oldValue);
  },
  unmounted(el, { value }) {
    const type = value.event_type;

    if (unbindTypeMap[type]) {
      unbindTypeMap[type](el);
    }
  },
};
