<template>
  <div
    class="mv-enter-hint"
    v-html="sing_tips_text"
    v-if="isShowSingTips"
    :style="{
      background: `url(${sing_tips_img}) no-repeat`,
      backgroundSize: 'auto 100%',
      'background-position': 'right'
    }"
  >
  </div>
</template>

<script>
import { computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import get from 'lodash/get'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'SearchBarTips',
  setup() {
    const store = useStore()
    const orderedSongNum = computed(() => store.state.orderedList.length)
    const canShow = computed(() => store.state.searchTips.canShow)
    const isShowSingTips = computed(() => store.state.searchTips.isShowSingTips)
    const sing_tips = computed(() => store.state.sing_tips)
    const sing_tips_text = computed(() => get(sing_tips.value, 'text', '').replace('{songs}', `<span style="color: #FFD850;font-size: 32px;font-weight: 700;text-align: center;line-height: 38px;">${orderedSongNum.value}</span>`))
    const sing_tips_img = computed(() => get(sing_tips.value, 'img', ''))
    const videoPaused = computed(() => store.state.videoPaused)

    let sing_tips_timer = ref(null)

    const closeSingTipsTimer = () => {
      if (sing_tips_timer.value) {
        clearTimeout(sing_tips_timer.value)
        sing_tips_timer.value = null
      }
    }

    watch(orderedSongNum, val => {
      // console.log('已点监听', val, canShow.value)
      if (canShow.value) {
        if (!videoPaused.value) {
          store.dispatch('searchTips/updateIsShowSingTips', false) // 已经在播放了直接禁止提示
          return
        }
        if (val == sing_tips.value.songs) {
          sendLog({
            event_type: '10000~50000',
            event_name: 10067,
            event_data: {
              str1: '首页',
              str2: '进入欢唱',
              str3: '欢唱引导',
              str4: 'show',
            }
          })
          store.dispatch('searchTips/updateIsShowSingTips', true)
          closeSingTipsTimer()
          sing_tips_timer.value = setTimeout(() => {
            store.dispatch('searchTips/updateIsShowSingTips', false)
            closeSingTipsTimer()
          }, sing_tips.value.duration * 1000)
        }
      }
    })

    return {
      sing_tips_text,
      sing_tips_img,
      isShowSingTips,
    }
  }
}
</script>

<style lang="stylus" scoped>
.mv-enter
  &-hint
    position fixed
    top 140px
    right 80px
    z-index 9
    width 568px
    height 106px
    padding-top 20px
    padding-right 22px
    background-position center
    display flex
    justify-content right
    align-items center
    color #fff
    font-size 26px
    @media screen and (max-width 1200px) and (min-height 1200px)
      top 112px
      right 60px
      height 84px
      font-size 20px
      padding-top 15px
      padding-right 18px
</style>