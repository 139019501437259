import { getSongCollectList } from '@/service/collect'

const state = {
  collectList: [],
}

const getters = {}

const actions = {
  async getUserCollectList({ commit }, unionid) {
    const collectList = await getSongCollectList({
      unionid
    })
    console.log(collectList)
    commit('GET_USER_COLLECTLIST', collectList)
  },
  async updateCollectList({ commit }, collectList) {
    console.log(collectList)
    commit('UPDATECOLLECTLIST', collectList)
  },
  resetUserCollectList({ commit }) {
    commit('RESET_USER_COLLECTLIST')
  },
  pushNewSongToCollect({ commit }, song) {
    commit('PUSH_NEWSONG_TO_COLLECT', song)
  },
  deleteSong({ commit }, song) {
    commit('DELETE_SONG_FROM_COLLECT', song)
  },
}

const mutations = {
  GET_USER_COLLECTLIST(state, data) {
    state.collectList = data
  },
  RESET_USER_COLLECTLIST(state) {
    state.collectList = []
  },
  UPDATECOLLECTLIST(state, data) {
    state.collectList = data
  },
  PUSH_NEWSONG_TO_COLLECT(state, data) {
    state.collectList = [
      data,
      ...state.collectList
    ]
  },
  DELETE_SONG_FROM_COLLECT(state, data) {
    state.collectList = state.collectList.filter(v => v.songid !== data.songid)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
