<template>
  <div class="loading-icon">
    <img src="https://qncweb.ktvsky.com/20240611/vadd/eef76cc2a511b14af83e28fd0ce2a686.png" alt="">
    <p>玩命加载中...</p>
  </div>
</template>
<script>
export default {
  name: 'LoadingIcon',
}
</script>
<style lang="stylus" scoped>
.loading-icon
  padding-top 50px
  display flex
  flex-direction column
  align-items center
  p
    color #ffffff
  img
    width: 80px
    height: 80px
    margin-bottom 26px
    // animation: turn 1s linear infinite
@keyframes turn
    0%
      transform:rotate(0deg)
    25%
      transform:rotate(90deg)
    50%
      transform:rotate(180deg)
    75%
      transform:rotate(270deg)
    100%
      transform:rotate(360deg)
</style>