// import http from '@/utils/http'
import httpV2 from '@/utils/httpV2'
import get from 'lodash/get'

export async function getSingerClassList() {
  const res = await httpV2.get('/stb/v2/top/class/singer')
  return get(res, 'data', []);
}

export async function getSingerList({k, p, version}) {
  // /stb/carplay/detail/class
  if (!k) return []

  let params = {
    k,
    p,
    size: 20,
  }
  if (version && p > 1) {
    params = {
      ...params,
      ver: version
    }
  }
  const res = await httpV2.get('/stb/v2/detail/class/singer', { params })
  if (p <= 1) {
    return {
      data: [...get(res, 'data', [])],
      version: get(res, 'version', {})
    }
  }
  return {
    data: get(res, 'data', []),
    version: get(res, 'version', {})
  }
}

// 飙升周榜数据 - 接口调整为还是用之前老的接口了
// export async function getSingerRiseList({p, version}) {
//   let params = {
//     page: p,
//     size: 20,
//   }
//   if (version && p > 1) {
//     params = {
//       ...params,
//       ver: version
//     }
//   }
//   const res = await httpV2.get('/stb/v2/singer/rise', { params })
//   if (p <= 1) {
//     return {
//       data: [...get(res, 'data', [])],
//       version: get(res, 'version', {})
//     }
//   }
//   return {
//     data: get(res, 'data', []),
//     version: get(res, 'version', {})
//   }
// }