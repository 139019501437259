<template>
  <CommonModal ref="root">
    <div class="activity-modal-content">
      <img class="top-img" src="https://qncweb.ktvsky.com/20220831/vadd/d3ab59e887db628ea859dd0e0a35fb19.png"/>
      <div class="title">
        会员权益
      </div>
      <div class="content">
        雷石KTV
        <span>180</span>
        天会员免费送，新歌热歌任您选择，全部歌曲随意嗨唱，快来领取解锁海量曲库吧！
      </div>
      <div class="bottom-container">
        <div @click="handleConfirm" class="confirm">立即领取</div>
        <div @click="handleCloseModal" class="close">关闭</div>
      </div>
    </div>
  </CommonModal>
</template>
<script>
import { ref, computed, watch } from 'vue'
import store from '@/store'
import CommonModal from '@/components/modal/common/component.vue'
import useLoginQrcode from '@/components/modal/global/login-qrcode/create'
import { partakeSendVipActivity } from '@/service/user'
import Toast from '@/utils/toast'
import get from 'lodash/get'

export default {
  name: 'ActivityModal',
  components: {
    CommonModal,
  },
  props: {
    closeEvent: {
      type: Function,
      default: () => {
      }
    },
  },
  setup(props) {
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const root = ref(null)
    const loginQrcodeModal = useLoginQrcode()
    const loginQrcodeInstance = ref(null)

    const handleCloseModal = () => {
      props.closeEvent.call()
      root.value.hide()
    }

    const partakeActivity = async () => {
      const res = await partakeSendVipActivity()
      if (get(res, 'errcode') == 200) {
        store.dispatch('getCarplayInfo')
      } else {
        Toast('会员领取失败!')
      }
    }

    const showLoginQrcode =  (from) => {
      loginQrcodeInstance.value = loginQrcodeModal.show(from)
      store.dispatch('startCheckLoginStatus')
    }

    const handleConfirm = () => {
      if (isLogin.value) {
        // 参与领会员活动
        partakeActivity()
        root.value.hide()
      } else {
        // 打开登录弹窗
        showLoginQrcode({ from: 'loginSendVipActivity' })
      }
    }

    watch(isLogin, (val) => {
      if (val && loginQrcodeInstance.value) {
        loginQrcodeInstance.value.hide()
        root.value.hide()
      }
    })

    return {
      root,
      handleCloseModal,
      handleConfirm
    }
  }
}
</script>
<style lang="stylus" scoped>
.activity-modal-content
  padding 130px 80px 0
  position relative
  width 800px
  height 532px
  background url(https://qncweb.ktvsky.com/20220826/vadd/f204d895e76caab9fe2540ddc41188b5.png) no-repeat
  background-size 100% 100%
  background-position center
  border-radius 20px
  color rgba(255, 255, 255, .8)
  display flex
  flex-direction column
  align-items center
  .top-img
    width 276px
    height 276px
    position absolute
    top -140px
    left 262px
  .title
    margin-bottom 38px
    font-size 40px
    font-weight 700
    line-height 1
    color rgba(240, 210, 144, 1)
  .content
    font-size 32px
    color rgba(255, 255, 255, 0.8)
    span
      margin 0 5px
      color rgba(240, 210, 144, 1)
      font-weight 800
  .bottom-container
    width 100% !important
    height 90px
    margin-top 32px
    font-size 32px
    display flex
    flex-direction row
    .confirm
      width 300px
      height 90px
      margin-right 40px
      border-radius 14px
      text-align center
      line-height 90px
      font-weight 500
      background linear-gradient(to right, #FBD7AF, #F1BE73)
      color rgba(107, 21, 21, 0.8)
    .close
      width 300px
      height 90px
      border-radius 14px
      text-align center
      line-height 90px
      background rgba(56, 58, 62, 1)
      color rgba(255, 255, 255, 0.5)
</style>
