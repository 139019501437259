import get from 'lodash/get'
import eventBus from '@/utils/event-bus'
import useSongItem from './useSongItem'
import useLoginValid from './useLoginValid'
import useVip from './useVip'
import useOrder from './useOrder'
import { sendLog } from '@/directives/v-log/log'
import store from '@/store'
import { computed } from 'vue'

export const NatsCommandRouteMap = {
  ORDER_SONG: '/song/vod', // 点歌
  ORDER_NEXT: '/song/next', // 切歌
  ORDER_REPLAY: '/song/reset', // 重唱
  ORDER_PLAY_TOGGLE: '/song/ptoggle', // 暂停
  ORDER_AUDIO_TRACK_TOGGLE: '/song/ctoggle', // 原伴唱切换
  ORDER_LIST: '/song/list', // 已点列表
  ORDER_LIST_TOP: '/song/listtop', // 已点置顶
  ORDER_LIST_DELETE: '/song/del', // 已点删除
  INFORM: '/inform', // 消息推送
}

export default function useNats() {
  // const { handleLoginSuccess } = useLogin()
  // const { handlePayToast } = useVip()
  const { orderSong } = useSongItem()
  const { isLogin } = useLoginValid()
  const { isVipUser } = useVip()
  const { orderedList, stickSongToTop, deleteSong } = useOrder()
  const vipAddSong = computed(() => store.state.vipAddSong.song)


  const NatsCommandControler = {
    [NatsCommandRouteMap.ORDER_SONG]: ({ data }) => {
      try {
        const body = JSON.parse(atob(data))
        const songData = get(body, 'musicinfo[0]', null)
        if (!songData) {
          return {
            code: 2,
            msg: '未获取到歌曲信息'
          }
        }

        sendLog({
          event_type: '10000~50000',
          event_name: 10100,
          event_data: {
            str1: '手机点歌',
            str2: '进入上报',
            str3: '车机登录身份',
            str4: 'click',
            str5: isVipUser.value ? '1' : '2'
          },
        })

        const isRequireVip = songData.songVip

        if (!isLogin.value && isRequireVip) {
          return {
            code: -1,
            msg: '请登录后点歌'
          }
        }

        const songLocal = {
          acc: songData.acc,
          flag: songData.flag,
          music_name: songData.musicname,
          org: songData.org,
          singer: songData.singer,
          songid: songData.musicno,
          m3u8: '',
          hls: '',
          token: '', // 新增token播放鉴权
          tokenExp: 300, // token过期时间
          serverTime: 0,
          is_vip: isRequireVip
        }
        orderSong(songLocal, {
          immediate: false,
          from: {
            song_list_source: 1
          }
        })
        if (isRequireVip && isLogin.value && !isVipUser.value) {
          return {
            code: -1,
            msg: '更多歌曲需要解锁VIP才能演唱哦，请您前往应用开通'
          }
        }
        return {
          code: 1,
          msg: '请求成功'
        }

      } catch (error) {
        return {
          code: 2,
          msg: '歌曲解析失败'
        }
      }
    },
    [NatsCommandRouteMap.ORDER_NEXT]: () => {
      eventBus.emit('nats-player-control', {
        name: NatsCommandRouteMap.ORDER_NEXT,
      })
      return {
        code: 1,
        msg: '请求成功'
      }
    },
    [NatsCommandRouteMap.ORDER_REPLAY]: () => {
      eventBus.emit('nats-player-control', {
        name: NatsCommandRouteMap.ORDER_REPLAY,
      })
      return {
        code: 1,
        msg: '请求成功'
      }
    },
    [NatsCommandRouteMap.ORDER_PLAY_TOGGLE]: () => {
      eventBus.emit('nats-player-control', {
        name: NatsCommandRouteMap.ORDER_PLAY_TOGGLE,
      })
      return {
        code: 1,
        msg: '请求成功'
      }
    },
    [NatsCommandRouteMap.ORDER_AUDIO_TRACK_TOGGLE]: () => {
      eventBus.emit('nats-player-control', {
        name: NatsCommandRouteMap.ORDER_AUDIO_TRACK_TOGGLE,
      })
      return {
        code: 1,
        msg: '请求成功'
      }
    },
    [NatsCommandRouteMap.ORDER_LIST_TOP]: ({ query }) => {
      const index = query.get('mindex')
      if (!isNaN(index) && !!index.trim()) {
        stickSongToTop(index)
        return {
          code: 1,
          msg: '请求成功'
        }
      }
      return {
        code: -1,
        msg: '请求失败'
      }
    },
    [NatsCommandRouteMap.ORDER_LIST_DELETE]: ({ query }) => {
      const index = query.get('mindex')
      if (!isNaN(index) && !!index.trim()) {
        deleteSong(index)
        return {
          code: 1,
          msg: '请求成功'
        }
      }
      return {
        code: -1,
        msg: '请求失败'
      }
    },
    [NatsCommandRouteMap.ORDER_LIST]: ({ query }) => {
      // {
      //   "appid": "1250EB524F754BA7A4A0450C19E09F60",
      //   "musiclanguage": "MV",
      //   "musicname": "世界这么大还是遇见你(HD)",
      //   "musicno": "7592580",
      //   "singername": "程响",
      //   "vip": 0
      // }
      return {
        code: 1,
        msg: '请求成功',
        list: orderedList.value.map((item) => {
          return {
            appid: query.get('appid'),
            musiclanguage: item.flag[0],
            musicname: item.music_name,
            musicno: item.songid,
            singername: item.singer,
            vip: item.is_vip
          }
        })
      }
    },
    [NatsCommandRouteMap.INFORM]: ({ query = {} }) => {
      // - Query: tp=x 枚举值
      // - 0: 登录成功
      // - 1: 购买VIP成功
      // - 2: 注销成功
      // - 10: 获取换脸视频
      console.log('INFORM', query);

      if (query.tp == 10) {
        // 调用换脸视频
        store.dispatch('getAIface')
      } else {
        store.dispatch('getCarplayInfo')
        if (vipAddSong.value.length) {
          if (query.tp == 0) {
            setTimeout(() => {
              if (isVipUser.value) {
                orderSong(vipAddSong.value[0], {
                  immediate: false,
                })
                store.dispatch('vipAddSong/resetSong')
              }
            }, 1000)
          } else if (query.tp == 1) {
            setTimeout(() => {
              orderSong(vipAddSong.value[0], {
                immediate: false,
              })
              store.dispatch('vipAddSong/resetSong')
            }, 1000)
          }
        }
      }

      return {
        code: 1,
        msg: '请求成功',
      };
    }    
  }

  const handleResponse = (payload) => {
    const controller = NatsCommandControler[payload.route]
    if (typeof controller === 'function') {
      const res = controller.call(this, payload)
      return res
    }
    return {
      code: 2,
      msg: '网络异常,请稍后重试'
    }
  }

  const handleReceiveMessageFromNats = (msgJsonStr) => {
    if (!msgJsonStr) return
    try {
      const msgObj = JSON.parse(msgJsonStr)
      const routeSplitArray = msgObj.route.split('?') || []

      let route = ''
      let query = {}

      if (msgObj.route.includes('/inform')) {
        route = routeSplitArray[0] || ''
        const queryParams = new URLSearchParams(routeSplitArray[1] || '')
        query = Object.fromEntries(queryParams.entries())
      } else {
        route = get(routeSplitArray, '0', '')
        query = new URLSearchParams(get(routeSplitArray, '1', ''))
      }

      const response = handleResponse({
        ...msgObj,
        route,
        query,
      })
      return response
    } catch (error) {
      console.log(error)
      return {
        code: 2,
        msg: '网络异常,请稍后重试'
      }
    }
  }

  return {
    handleReceiveMessageFromNats,
  }
}
