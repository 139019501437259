import { getBaseInfo, reportSession, getConfig } from '@/service/base'

const state = {
  loginModalEnabled: false, // 此字段未使用，暂时保留
  phone_control_qr: '',
  nats: {
    eip: '',
    iip: '',
    port: '',
    PingInterval: 15,
    reconnectdelay: 3,
    subs: '',
  },
  aiface: []
}

const getters = {}

const actions = {
  reportSession() {
    reportSession()
  },
  setLoginModalEnabled({ commit }, enabled) {
    commit('SAVE_LOGINMODAL_ENABLED', enabled)
  },
  async getBaseInfo({ commit }) {
    try {
      const { data } = await getBaseInfo()
      data && commit('SAVE_BASE_INFO', data)
    } catch (error) {
      console.log('base info')
    }
  },
  async getConfig({ commit }) {
    try {
      const { data } = await getConfig()
      data && commit('SAVE_AIFACE', data.aiFace)
    } catch (error) {
      console.log('base info')
    }
  },
}

const mutations = {
  SAVE_LOGINMODAL_ENABLED(state, enabled) {
    state.loginModalEnabled = enabled
  },
  SAVE_BASE_INFO(state, { phone_control_qr, nats }) {
    state.phone_control_qr = phone_control_qr
    state.nats = nats
  },
  SAVE_AIFACE(state, data) {
    state.aiface = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
