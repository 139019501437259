<template>
    <ControlPopup ref="root">
      <template #control-popup-plugin>
        <div class="high-singing" v-if="routeName == 'climax'">
          <img
            src="https://qncweb.ktvsky.com/20240530/vadd/4362d14eb74fdaf90818b009deb4ce15.png"
            @click="handleClose"
            alt=""
            class="high-singing-close"
          />
          <img class="high-singing-tit" src="https://qncweb.ktvsky.com/20240530/vadd/13d923bd294d52702f79143f2d0cf53e.png">
          <img class="high-singing-free" src="https://qncweb.ktvsky.com/20240530/vadd/a4c6f4f09163f0d6d6cdcc2964fd9a67.png">
          <img class="high-singing-time" src="https://qncweb.ktvsky.com/20240530/vadd/7f5e5b2e81714931e4306a98148ee977.png">
          <img class="high-singing-btn" @click="handleClickRoute" src="https://qncweb.ktvsky.com/20240530/vadd/78fe8eb86df007203805f8c93a2271b6.png">
          <img class="high-singing-tips" src="https://qncweb.ktvsky.com/20240530/vadd/7278fc611c067b561b59f03706bece9b.png">
          <Checkbox  class="high-singing-next" plain checked-color="rgba(19, 171, 111, 1)" v-model="highChecked" @change="(val)=>checkedChange(val,1)"></Checkbox>
        </div>

        <div class="free-singing" v-else>
          <img
          src="https://qncweb.ktvsky.com/20240530/vadd/4362d14eb74fdaf90818b009deb4ce15.png"
          @click="handleClose"
          alt=""
          class="free-singing-close"
        />
          <img class="free-singing-tit" src="https://qncweb.ktvsky.com/20240530/vadd/13d923bd294d52702f79143f2d0cf53e.png">
          <img class="free-singing-free" src="https://qncweb.ktvsky.com/20240530/vadd/770dac60a830e338a68270781a2c512e.png">
          <img class="free-singing-tip" src="https://qncweb.ktvsky.com/20240530/vadd/6d67f024139125d367a9a20c5c3f32f8.png">
          <img class="free-singing-btn"  @click="handleClickStartSing"  src="https://qncweb.ktvsky.com/20240530/vadd/78fe8eb86df007203805f8c93a2271b6.png">
          <img class="free-singing-tips" src="https://qncweb.ktvsky.com/20240530/vadd/7278fc611c067b561b59f03706bece9b.png">
          <span class="free-singing-num">
              <span class="free-singing-num-left">{{freeEndVipNumber}}</span>
              <span class="free-singing-num-right">/3</span>
          </span>
          <Checkbox  class="free-singing-next" plain checked-color="rgba(19, 171, 111, 1)" v-model="checked" @change="(val)=>checkedChange(val,2)"></Checkbox>
      </div>
      </template>
    </ControlPopup>
  </template>
  <script setup>
  import { ref, onMounted, onBeforeUnmount, computed, watch, nextTick } from 'vue'
  import { Store, useStore } from 'vuex'
  import { useRouter,useRoute } from 'vue-router'
  import eventBus from '@/utils/event-bus'
  import ControlPopup from '@/components/control-popup/index.vue'
  import { Checkbox } from 'vant'
  const store = useStore()
  const router = useRouter()
  const route = useRoute();
  const root = ref(null)
  const checked = ref(false)
  const highChecked = ref(false)
  const routeName = ref(null)
  // const orderedListVipNumber = ref(0)
  const freeEndVipNumber =computed(() => 4 - Number(store.state.freeVipNumber) > 3 ? 3 : 4 - Number(store.state.freeVipNumber) )
  const isLogin = computed(() => !!store.state.userInfo.unionid);
  const isVip = computed(() => !!store.state.vipInfo.end_time);
  const mvIsHide = computed(() => !!store.state.mvIsHide)
  import { sendLog } from '@/directives/v-log/log'
  watch(mvIsHide,(val)=>{
    if(!val){
      nextTick(()=>{
        root.value.close()
      })
    }
  },{
    deep: true,
    immediate: true
  })
  const handleShow = (val) => {
    routeName.value = val
    if(highChecked.value){
      handleClickRoute()
    }else{
      sendLog({
        event_type: '10000~50000',
        event_name: 30227,
        event_data: {
          str1: '通用',
          str2: '用户点快唱免费时长弹窗',
          str3: '展示',
          str4: 'show',
        },
      })
      root.value.show()
    }
  }
  const handleVipNumber =(number)=>{
    if(checked.value){
      handleClose()
    }else{
      root.value.show()
    }
  }
  const handleClickStartSing = ()=>{
    eventBus.emit('handle-click-start-sing')
  }
  const handleClickRoute = ()=>{
    handleClose()
    sendLog({
      event_type: '10000~50000',
      event_name: 30228,
      event_data: {
        str1: '通用',
        str2: '用户点快唱免费时长弹窗',
        str3: '点击',
        str4: 'click',
      },
    })
    router.push({
      name: 'climax',
    })
  }
  const handleClose = () => {
    routeName.value = ''
    root.value.close()
  }
  const checkedChange =(val,type)=>{
    if(type == 1){
      highChecked.value = val
    }else{
      checked.value = val
    }
  }
  onMounted(() => {
    eventBus.on('free-singing-control-popup', handleShow) // 点歌
    eventBus.on('ordered-list-vip-number', handleVipNumber) // 免费次数
  })
  
  onBeforeUnmount(() => {
    eventBus.off('free-singing-control-popup', handleShow)
    eventBus.off('ordered-list-vip-number', handleVipNumber)
  })
  </script>
  <style lang="stylus" scoped>
  .free-singing
    padding 30px
    width 600px
    height 690px
    //background #1E1F21
    // box-shadow 0px 20px 80px 0px rgba(0, 0, 0, 0.5)
    //border-radius 14px
    padding 0 !important
    background: url(https://qncweb.ktvsky.com/20240530/vadd/ca3247adc220eaf43f19b365c9ad697b.png) no-repeat;
    background-size: 100% 100%;
    position relative
    @media screen and (max-width 1200px)
      zoom 1.2
    &-close
        position absolute
        top 200px
        right 20px
    &-tit
        position absolute
        top 250px
        left 200px
    &-free
        position absolute
        top 320px
        left 130px
    &-tip
        position absolute
        top 500px
        left 120px
    &-num
        position absolute
        top 350px
        left 260px
        &-left
            color rgba(234, 82, 82, 1)
            font-size 90px
            font-weight 800
        &-right
            color rgba(234, 82, 82, 0.5)
            font-size 36px
    &-btn
        position absolute
        top 540px
        left 170px
    &-next
        position absolute
        top 630px
        left 230px
    &-tips
        position absolute
        top 630px
        left 260px
.high-singing
  padding 30px
  width 600px
  height 642px
  padding 0 !important
  background: url(https://qncweb.ktvsky.com/20240530/vadd/b9fcfe3c421522424e2200b9604811df.png) no-repeat;
  background-size: 100% 100%;
  position relative
  &-close
    position absolute
    top 200px
    right 25px
  &-tit
    position absolute
    top 250px
    left 200px
  &-free
    position absolute
    top 320px
    left 79px
  &-time
    position absolute
    top 360px
    left 230px
  &-btn
    position absolute
    top 490px
    left 170px
  &-next
      position absolute
      top 580px
      left 230px
  &-tips
      position absolute
      top 580px
      left 260px
  </style>
  