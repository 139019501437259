<template>
  <div class="tuner-item" :class="{'disabled': disabled}">
    <div class="slider">
      <van-slider
        v-model="value"
        vertical
        reverse
        :disabled="disabled"
        @change="onChange"
        @update:model-value="onUpdate"
      />
    </div>
    <p class="name">
      <slot>名称</slot>
    </p>
  </div>
</template>
<script>
import { onBeforeMount, ref, toRefs } from 'vue'
import _ from 'lodash'
import { Slider } from 'vant'

export default {
  name: 'EffectSlider',
  components: {
    VanSlider: Slider,
  },
  props: {
    defaultValue: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    }
  },
  setup(props, { emit }) {
    const { disabled, defaultValue } = toRefs(props)
    // 默认50%
    let value = ref(50)

    const onChange = (val) => {
      emit('change', val)
    }
    const onUpdate = _.debounce((val) => {
      emit('update', val)
    }, 200)

    onBeforeMount(() => {
      if (defaultValue.value) {
        value.value = defaultValue.value
      }
      if (disabled.value) {
        value.value = 5
      }
    })

    return {
      value,
      onChange,
      onUpdate
    }
  },
}
</script>
<style lang="stylus" scoped>
.tuner-item
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  .slider
    height 362px
    --van-slider-bar-height: 30px!important
    --van-slider-button-border-radius: 0px!important
    --van-slider-inactive-background-color: #383A3E!important
    --van-slider-button-box-shadow: none!important
    ::v-deep .van-slider__bar
      border-radius 0px 0px 15px 15px!important
      background linear-gradient(360deg, #7E80F8 0%, #5558FF 100%)!important
      background-color none!important
    ::v-deep .van-slider__button
      width 80px!important
      height 80px!important
      background url(https://qncweb.ktvsky.com/20211223/vadd/c9113e5626c1a38f27ca259afbd9a6fb.png) no-repeat !important
      background-size 100% 100% !important
      background-position center !important
    ::v-deep .van-slider--disabled
      .van-slider__button
        background url(https://qncweb.ktvsky.com/20211223/vadd/37eb350f3b75999fe9793599fd630b2c.png) no-repeat !important
        background-size 100% 100% !important
        background-position center !important
  .name
    margin-top 31px
    font-size 32px
    text-align: center
    color rgba(255, 255, 255, 0.8)
  &.disabled
    .name
      color rgba(255, 255, 255, 0.3)
</style>