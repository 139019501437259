<template>
  <div class="both-sides-menu">
    <div
      class="menu"
      :style="{
        [item.pos]: '40px'
      }"
      v-for="(item, i) in bothSides"
      :key="i"
    >
      <!-- 个人中心 -->
      <div class="menu-header-img" @click.stop="handleShowSideBar('我的特权', item.pos)">
        <img v-if="headerimg" :src="headerimg"/>
        <img v-else src="https://qncweb.ktvsky.com/20230424/vadd/608c9e8b386f6493599d2cc79ba361d4.png" alt="">
        <div class="menu-header-img-icon">
          <img :src="vipImg[vipStatus]" alt="">
        </div>
      </div>
      <!-- 快速点歌 -->
      <div class="menu-item" @click.stop="handleShowSideBar('快速点歌', item.pos, 10070)">
        <img class="menu-item-icon" src="https://qncweb.ktvsky.com/20231102/vadd/fa015ce36c8013015815d1a708a5075b.png"/>
        <div class="menu-item-text">快速点歌</div>
      </div>
      <!-- 调音 -->
      <div class="menu-item" @click.stop="handleShowSideBar('调音', item.pos, 10072)">
        <img class="menu-item-icon" src="https://qncweb.ktvsky.com/20231102/vadd/357c430db2fab353882222c49895314e.png"/>
        <div class="menu-item-text">调音</div>
      </div>
      <!-- 音轨 -->
      <!-- <div
        v-for="(track, key) in audioTrackMap"
        :key="key"
      >
        <div
          v-show="(enabledAudioTrackId !== 999 && enabledAudioTrackId !== track.id) || (enabledAudioTrackId === 999 && key === 'org')"
          class="menu-item"
          @click.stop="handleSwitchAudioTrack(track, item.pos)"
        >
          <img class="menu-item-icon" :src="iconConfig[key]"/>
          <div class="menu-item-text">{{ key === 'org' ? '原唱' : '伴唱' }}</div>
        </div>
      </div> -->
      <!-- 画质 -->
      <div class="menu-item" @click.stop="handleShowSideBar('画质', item.pos, 10076)">
        <img class="menu-item-icon" src="https://qncweb.ktvsky.com/20231102/vadd/b22de2a431688df39c5c711b9334e7d2.png"/>
        <div class="menu-item-text">{{ playingMvQuality }}P</div>
        <div class="menu-item-hint" v-if="item.pos === 'left' && isShowQHint">
          <div class="menu-item-hint-close" @click.stop="handleCloseQHint"></div>
        </div>
      </div>
      <!-- 已点 -->
      <div class="menu-item" @click.stop="handleShowSideBar('已点', item.pos, 10078)">
        <img class="menu-item-icon" src="https://qncweb.ktvsky.com/20231102/vadd/7d585306f1b39d1653f421c1798b6d67.png"/>
        <div class="menu-item-text">已点</div>
      </div>
      <!-- 气氛  -->
      <!-- <div class="menu-item" @click.stop="handleShowSideBar('气氛', item.pos)">
        <img class="menu-item-icon" src="https://qncweb.ktvsky.com/20230113/vadd/5bc69ba4eaec9baf882ed75784e6c273.png"/>
        <div class="menu-item-text">气氛</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { computed, toRefs, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { sendLog } from '@/directives/v-log/log'
import get from 'lodash/get'
import { setOperationQualityShow, getOperationQualityShow } from '@/utils/historyCache'
import { format } from 'date-fns'
import Toast from '@/utils/toast'

export default {
  name: 'VideoBothSidesMenu',
  props: {
    headerimg: {
      type: String,
      default: ''
    },
    audioTrackMap: {
      type: Object,
      default() {
        return {
          acc: {},
          org: {}
        }
      }
    },
    enabledAudioTrackId: {
      type: Number,
    },
    showMvControlGuide: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const iconConfig = {
      org: 'https://qncweb.ktvsky.com/20231102/vadd/535f3a6eda6ecaab4ed3f1b1540e66b8.png',
      acc: 'https://qncweb.ktvsky.com/20231102/vadd/6fbdc34d53bffc3f6f2394d3f0a8a94f.png'
    };

    const bothSides = ref([{
      value: 0,
      label: '左侧',
      pos: 'left',
    },{
      value: 1,
      label: '右侧',
      pos: 'right',
    }])

    const isShowQHint = ref(false)
    const { showMvControlGuide } = toRefs(props)

    const store = useStore()
    const userInfo = computed(() => store.state.userInfo)
    const vipInfo = computed(() => store.state.vipInfo)
    const isLogin = computed(() => !!userInfo.value.unionid)
    const isVip = computed(() => !!vipInfo.value.end_time)
    const playingMvQuality = computed(() => store.state.playingMvQuality)
    const currPlayingSongIsAI = computed(() => store.state.orderedList[0]?.isAIMV)
    const currPlayingSong = computed(() => store.state.videoPlayerHistory.songItem.songid)
    const playingSongItemM3u8 = computed(() => get(store.state.videoPlayerHistory, 'songItem.m3u8.1080', false))
    const userType = computed(() => store.state.userInfo.userType)
    const mvIsHide = computed(() => store.state.mvIsHide);

    const vipImg = ref([
      'https://qncweb.ktvsky.com/20240314/vadd/62e68a15c55814a2273a03d13d9cd5d3.png',
      'https://qncweb.ktvsky.com/20240314/vadd/d8a63836f1dc2615fdca2dd95819dfc3.png',
      'https://qncweb.ktvsky.com/20240314/vadd/8cc9bad11773817ce114d49abbc09837.png',
    ])

    const vipStatus = computed(() => {
      if (!isLogin.value) {
        return 0
      } else {
        if (!isVip.value) {
          if (!vipInfo.value.expire) {
            return 0
          }
          return 1
        }
        return 2
      }
    })

    const handleSwitchAudioTrack = (audioTrack, pos) => {
      emit('switch-audio-track', audioTrack)

      sendLog({
        event_type: '10000~50000',
        event_name: pos === 'left' ? 10074 : 10075,
        event_data: {
          str1: 'MV',
          str2: '播控',
          str3: `原唱${pos === 'left' ? '左' : '右'}`,
          str4: 'click',
        },
      })
    }

    const handleShowSideBar = (name, pos, logname) => {
      if (name === '我的特权') store.dispatch('vipAddSong/resetSong')
      if (name === '画质' && currPlayingSongIsAI.value) {
        Toast({
          message: '正在播放换脸mv，暂不支持切换画质',
          position: 'center',
          className: 'toast-zoom-max'
        })
        return
      }
      emit('show-side-bar', { name, pos })

      sendLog({
        event_type: '10000~50000',
        event_name: pos === 'left' ? logname : logname + 1,
        event_data: {
          str1: 'MV',
          str2: '播控',
          str3: `${name}${pos === 'left' ? '左' : '右'}`,
          str4: 'click',
        },
      })

      if (name === '画质') {
        sendLog({
          event_type: '10000~50000',
          event_name: pos === 'left' ? 30088 : 30089,
          event_data: {
            str1: '欢唱页',
            str2: '播控侧边栏',
            str3: pos === 'left' ? '画质左' : '画质右',
            str4: 'click',
            str7: userType.value,
          },
        })
      }
    }

    const handleCloseQHint = () => {
      isShowQHint.value = false
      const currDate = format(Date.now(), 'yyyy-MM-dd')
      setOperationQualityShow(currDate, 2)
      sendLog({
        event_type: '10000~50000',
        event_name: 30164,
        event_data: {
          str1: '欢唱页',
          str2: '画质选择',
          str3: '画质引导提示关闭',
          str4: 'click',
          str5: userInfo.value.unionid ? '已登录' : '未登录',
        },
      })
    }

    const showQHint = () => {
      console.log('showQHint')
      if (getOperationQualityShow()) {
        isShowQHint.value = true
        sendLog({
          event_type: '10000~50000',
          event_name: 30163,
          event_data: {
            str1: '欢唱页',
            str2: '画质选择',
            str3: '画质引导提示',
            str4: 'show',
            str5: userInfo.value.unionid ? '已登录' : '未登录',
            str7: userType.value,
          },
        })
        setTimeout(() => {
          isShowQHint.value = false
          sendLog({
            event_type: '10000~50000',
            event_name: 30164,
            event_data: {
              str1: '欢唱页',
              str2: '画质选择',
              str3: '画质引导提示关闭',
              str4: 'click',
              str5: userInfo.value.unionid ? '已登录' : '未登录',
            },
          })
        }, 10 * 1000)
      }
    }

    watch([currPlayingSong, mvIsHide, showMvControlGuide], ([song, isHide, showGuide]) => {
      if (song && !isHide && !showGuide && playingSongItemM3u8.value && playingMvQuality.value != '1080') {
        showQHint()
      }
    })

    return {
      bothSides,
      iconConfig,
      vipImg,
      vipStatus,
      isShowQHint,
      playingMvQuality,
      handleShowSideBar,
      handleCloseQHint,
      handleSwitchAudioTrack,
    }
  }
  
}
</script>

<style lang="stylus" scoped>
.both-sides-menu
  position absolute
  top 0
  width 100%
  height 100%
  display flex
  align-items center
  justify-content center
  z-index 9
  @media screen and (max-width 1200px) and (min-height 1421px)
    display none
  @media screen and (max-width 1180px) and (min-height 1200px)
    display none
  .menu
    width 130px
    height auto
    padding 30px 0px 16px
    // padding 16px 0px 6px
    background rgba(30, 31, 33, 0.7)
    backdrop-filter blur(15px)
    position absolute
    top 210px
    border-radius 20px
    display flex
    flex-direction column
    align-items center
    &-item
      width 130px
      height 120px
      margin-bottom 10px
      display flex
      flex-direction column
      justify-content center
      align-items center
      position relative
      &-icon
        width 80px
        height 80px
      &-text
        font-size 22px
        color rgba(255, 255, 255, 0.50)
        height 25px
        line-height 25px
      &-hint
        position absolute
        top 0
        right -290px
        width 340px
        height 93px
        background url(https://qncweb.ktvsky.com/20240320/vadd/d634576f6540bf366a1dcaf0790bef54.png) no-repeat
        background-size 100% 100%
        background-position center
        &-close
          width 36px
          height 36px
          float right
          margin-right 22px
          margin-top 22px
    &-header-img
      width 80px
      height 80px
      margin-bottom 30px
      border-radius 100%
      overflow hidden
      &-icon
        width 130px
        height 26px
        position absolute
        top 94px
        left 0px
        display flex
        align-items center
        justify-content center
        img
          width auto !important
          height 26px
</style>
