<template>
  <div class="user-vip" :class="[
    isVip && 'vip',
    ['mv', 'climax'].includes(from) && 'mv',
    ['profile', 'mine'].includes(from) && 'mine-user-vip'
  ]">
    <div v-if="from !== 'climax'" class="user-vip-top">
      <div class="user-vip-info">
        <div>
          <img
            class="avatar"
            :src="
              userInfo.avatar ||
              'https://qncweb.ktvsky.com/20211108/vadd/978f457f5f485e19364f234b2cfcbf07.png'
            "
          />
        </div>
        <div class="user-vip-info-user">
          <div class="username">
            {{ userInfo.username }}
          </div>
          <div v-if="isLogin" class="desc">{{ vipDesc }}</div>
        </div>
      </div>
      <div v-if="from !== 'mv'" class="user-vip-entry">
        <template v-if="from === 'profile'">
          <div class="active" @click="handleExchange">兑换VIP</div>
        </template>
        <template v-else>
          <div class="active" @click="handleProfile">会员中心</div>
          <div @click="handleSetting">设置</div>
        </template>
      </div>
      <div v-if="from === 'mv'" class="packages-title">
        <img
          src="https://qncweb.ktvsky.com/20231207/other/80719cedbeeb09959c2c613edb8ade42.png"
        />
      </div>
    </div>
    <div class="user-vip-openvip">
      <div class="packages">
        <div v-if="!['mv', 'climax'].includes(from)" class="packages-title">
          <h3><span>VIP权益</span></h3>
          <img
            src="https://qncweb.ktvsky.com/20231207/other/80719cedbeeb09959c2c613edb8ade42.png"
          />
        </div>
        <div class="vip-packages">
          <PerfectScrollbar>
            <div
              class="vip-packages-item"
              v-for="(item, index) in packages"
              :key="item.id"
              :class="[
                item.id === selectItem.id && 'active',
                packages.length - 1 === index && 'last',
              ]"
              @click="handleClickItem(item)"
            >
              <div
                v-if="item.tips"
                class="tips"
                :class="item.isOrange && 'orange'"
              >
                {{ item.tips }}
              </div>
              <div class="days">{{ item.title }}</div>
              <div class="day-price">
                <span>¥</span>{{ formatValue(item.day_fee) }}<span>元/天</span>
              </div>
              <div class="price">
                <span>¥{{ formatValue(item.fee) }}</span>
                <span v-if="item.fee !== item.old_fee" class="origin"
                  >¥{{ formatValue(item.old_fee) }}</span
                >
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
      <div class="pay-info">
        <div class="left">
          <div class="price" v-html="formatPayTitle"></div>
          <h3>微信扫码支付</h3>
          <p>有效期至-{{ expirationDate }}</p>
        </div>
        <div class="code">
          <img :src="qrCodeURL" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, onBeforeMount, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useQRCode from '@/composables/useQRCode'
import get from 'lodash/get'
import { Toast } from 'vant'
import { addDays, format } from 'date-fns'
import {
  getVipPkg,
  getVipPkgQr,
  getVipQuery,
  clearVipQueryPolling,
} from '@/service/vip'
import { sendLog } from '@/directives/v-log/log'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

export default {
  name: 'vipComponent',
  components: {
    PerfectScrollbar
  },
  props: {
    from: {
      type: String,
      default: 'mine',
    },
    fr: {
      type: Number,
      default: null,
    }
  },
  setup(props) {
    const { from, fr } = toRefs(props)
    const store = useStore()
    const router = useRouter()
    const { getQRCodeURL } = useQRCode()
    const userInfo = computed(() => store.state.userInfo)
    const vipInfo = computed(() => store.state.vipInfo)
    const isLogin = computed(() => !!userInfo.value.unionid)
    const isVip = computed(() => !!vipInfo.value.end_time)
    const setting = computed(() => store.state.setting)
    const loginSendVip = computed(() => store.state.activityInfo.loginSendVip)
    const isShowSignIn = computed(() => store.state.signin.isShowSignIn)
    const unionid = computed(() => store.state.userInfo.unionid)

    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const packages = ref([])
    const selectItem = ref({})

    const vipDesc = computed(() => {
      return isVip.value
        ? `会员有效期至: ${vipInfo.value.end_time
            .split(' ')[0]
            .replaceAll('-', '.')}`
        : '未开通 VIP'
    })

    const expirationDate = computed(() => {
      if (!selectItem.value.days) return ''
      const currentDate = isVip.value ? new Date(vipInfo.value.end_time) : new Date()
      const expirationDate = addDays(currentDate, selectItem.value.days)

      return format(expirationDate, 'yyyy.MM.dd')
    })

    const formatValue = (value) => {
      if (value === undefined || isNaN(value)) {
        return 'N/A'
      }
      return value / 100
    }

    const getVipQrcode = async () => {
      try {
        const data = await getVipPkgQr({
          unionid: unionid.value,
          pkg_id: selectItem.value.id,
          fr: fr.value || (from.value !== 'mv' ? 1759 : 1834),
        })

        const qr = get(data, 'qr', '')
        if (qr) {
          const qrCodeData = await getQRCodeURL(qr)
          if (qrCodeData) {
            qrCodeURL.value = qrCodeData
            // store.dispatch('startVipInfoStatus');
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    const formatPayTitle = computed(() => {
      const regex = /(\d+)(年|天)/g
      if (!selectItem.value.title) return ''

      const matches = selectItem.value.title.match(regex)
      if (!matches) return ''

      const formatted = matches
        .map((match) => {
          const [_, number, unit] = match.match(/(\d+)(年|天)/)
          return `<span class="user-vip-year-num">${number}</span>${unit}`
        })
        .join(' ')

      return formatted
    })

    const fetchConfig = async () => {
      if (!unionid.value) return
      const res = await getVipPkg(unionid.value)
      packages.value = res.data
      if (packages.value.length === 3) {
        const index = packages.value.findIndex((item) => !!item.tips)
        packages.value[index].isOrange = true
      } else {
        const reversedIndex = packages.value
          .slice()
          .reverse()
          .findIndex((item) => !!item.tips)
        const index = packages.value.length - 1 - reversedIndex
        packages.value[index].isOrange = true
      }

      const active =
        packages.value.find((item) => item.id === res.recommend_pkg) ||
        packages.value[0]
      selectItem.value = active

      getVipQrcode()
    }

    const handleClickItem = (item) => {
      qrCodeURL.value =
        'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
      selectItem.value = item
      getVipQrcode()
    }

    const handleProfile = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10030,
        event_data: {
          str1: '我的',
          str2: '开通VIP',
          str3: '点击进入',
          str4: 'click',
        },
      })
      router.push({
        name: 'profile',
      })
    }

    const handleExchange = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10030,
        event_data: {
          str1: '我的',
          str2: '开通VIP',
          str3: '点击进入',
          str4: 'click',
          str5: '会员兑换',
          str6: 'click',
        },
      })
      router.push({
        name: 'vipExchange',
      })
    }

    const handleSetting = () => {
      router.push({
        name: 'setting',
      })
    }

    watch(unionid, (val) => {
      if (val) {
        fetchConfig()
      }
    })

    onBeforeMount(() => {
      fetchConfig()
    })

    return {
      vipInfo,
      userInfo,
      isLogin,
      isVip,
      setting,
      isShowSignIn,
      loginSendVip,
      vipDesc,
      formatValue,
      packages,
      expirationDate,
      selectItem,
      handleClickItem,
      qrCodeURL,
      handleProfile,
      handleExchange,
      handleSetting,
      formatPayTitle,
    }
  },
}
</script>

<style lang="stylus" scoped>
.user-vip
  &-top
    display flex
    justify-content space-between
    flex-wrap wrap
    align-items center
    margin-bottom 60px
    @media screen and (max-width 1200px)
      margin-bottom 40px
  &-info
    display flex
    align-items center
    @media screen and (max-width 1200px)
      margin-bottom 30px
    .avatar
      width 120px
      height 120px
      border-radius 100%
      margin-right 24px
    &-user
      .username
        max-width 439px
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        color rgba(255, 255, 255, 1)
        font-size 32px
        line-height 1
        margin-bottom 16px
      .desc
        color rgba(255, 255, 255, 0.4)
        font-size 24px
        line-height 1
        padding-left 66px
        background-repeat no-repeat
        background-size 50px auto
        background-image url(https://qncweb.ktvsky.com/20231206/vadd/342350516d9b0eef7c2a5989dfcec1c5.png)
        background-position left center
  &-entry
    display flex
    div
      display flex
      justify-content center
      align-items center
      width: 200px;
      height: 80px;
      border-radius: 100px;
      border: 2px solid rgba(255, 255, 255, 0.10);
      background: rgba(255, 255, 255, 0.08);
      color: rgba(255, 255, 255, 0.60);
      font-size 28px
    .active
      color rgba(219, 174, 106, 1)
      margin-right 32px
  &.vip
    .username
      color: #E3AB5D;
    .desc
      background-image url(https://qncweb.ktvsky.com/20231206/vadd/56dc0bc6477bf2c7a6c4fcdc8360804e.png)
  &-openvip
    background rgba(255, 255, 255, 0.06)
    backdrop-filter blur(50px)
    display flex
    border-radius 5px
    overflow hidden
    @media screen and (max-width 1200px)
      height 500px
    .packages
      width 100%
      flex 1
      background url('https://qncweb.ktvsky.com/20231208/other/b2d860aba32a9a1fdf2d277e579be3d1.png') no-repeat right center
      background-size 143px auto
      padding 0 70px 0 48px
      @media screen and (max-width 1200px)
        padding-left 30px
        padding-right 30px
      &-title
        display flex
        align-items center
        justify-content space-between
        flex-wrap wrap
        border-bottom 1px solid rgba(255, 255, 255, 0.1)
        padding 40px 0
        h3
          font-size: 30px;
          font-weight: 500;
          padding-left 67px
          background url('https://qncweb.ktvsky.com/20231208/other/d87306bc759edd6d14444eb2459f4716.png') no-repeat left center
          background-size 55px auto
          span
            background: linear-gradient(180deg, #FFDAB3 0%, #C69156 100%);
            background: linear-gradient(180deg, color(display-p3 0.9765 0.8588 0.7216) 0%, color(display-p3 0.7451 0.5765 0.3725) 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        img
          width 563px
          height 32px
      .vip-packages
        min-width 640px
        .ps
          display flex
          padding-top 65px
          transform: translateZ(0)
        &-item
          width: 200px;
          height: 260px;
          border-radius: 10px;
          background: linear-gradient(217deg, rgba(255, 237, 219, 0.12) 0%, rgba(255, 255, 255, 0.02) 100%), rgba(255, 255, 255, 0.04);
          backdrop-filter: blur(50px);
          position relative
          text-align center
          margin-right 20px
          flex-shrink: 0;
          &.active
            background: linear-gradient(322deg, #FFE093 5.61%, #FFEBB5 34.88%, #FFECB9 96.2%);
            .days
              background: linear-gradient(270deg, #625F66 0%, #222124 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            .day-price
              color: #933000;
            .price
              color rgba(172, 98, 0, 1)
              .origin
                color rgba(172, 98, 0, 0.5)
          .tips
            padding 0 10px
            height 40px
            line-height 40px
            font-size 22px
            color #fff
            position absolute
            left 0
            top -20px
            background linear-gradient(90deg, #FF3D6B 0%, #8C1AFF 100%)
            border-radius 10px 10px 10px 0
            &.orange
              background linear-gradient(90deg, #ff3d3d 0%, #ff881a 100%)
          .days
            color: #FFD7AE;
            font-size: 28px;
            font-weight: 300;
            margin-top 40px
          .day-price
            color: #FFD7AE
            font-size: 52px;
            margin 12px 0 9px
            span
              font-size: 24px;
          .price
            font-size: 24px;
            color rgba(255, 255, 255, 1)
            display flex
            justify-content center
            font-weight: 300;
            .origin
              color: rgba(255, 255, 255, 0.5)
              font-size: 24px;
              text-decoration line-through
              margin-left 8px
    .pay-info
      width 372px
      margin-top 35px
      padding-bottom 60px
      .left
        width 220px
        text-align center
        margin 0 auto
        ::v-deep .price
          color #FFD67D
          font-size 28px
          margin-bottom 20px
          min-height 80px
          span
            font-size 80px
            line-height 85px
        h3
          color rgba(255, 255, 255, 0.8)
          font-size 28px
          margin-bottom 6px
        p
          color rgba(255, 255, 255, 0.6)
          font-size 22px
      .code
        width 180px
        height 180px
        background #fff
        margin 20px auto 0
        overflow hidden
        img
          width 170px
          height 170px
          margin 5px
  &.mine-user-vip
    .ps
      justify-content center
  &.mv
    .user-vip-top
      border-radius: 10px;
      padding 32px
      margin 0 48px
      background: rgba(255, 255, 255, 0.05);
      .packages-title
        width 100%
        margin-top 32px
        padding-top 33px
        background url('https://qncweb.ktvsky.com/20231212/other/7d7396163a83a861d8da23594838a424.png') no-repeat left top
        background-size 100% auto
    .user-vip-openvip
      background none
      flex-wrap wrap
      justify-content center
      .vip-packages
        display flex
        justify-content center
      .ps
        padding-top 60px
      .packages
        background none
        padding-right 0
      .pay-info
        width auto
        display flex
        justify-content space-between
        align-items center
        ::v-deep .price
          min-height 40px
        .code
          margin 0 0 0 60px
    @media screen and (max-width 1200px) and (min-height 1200px)
      .user-vip-top
        margin 0 112px
        padding 40px 40px 32px 40px
        .avatar
          width 96px
          height 96px
          margin-right 20px
        .username
          font-size 26px
        .desc
          font-size 20px
          background-size 40px auto
          padding-left 52px
        .packages-title
          margin-top 10px
          // background-size auto 26px
      .user-vip-openvip
        height 608px
        flex-direction column
        align-items center
        flex-wrap nowrap!important
        zoom calc(180 / 200)
        .last
          margin-right 0
        .vip-packages-container
          padding-top 52px
          justify-content center
          .vip-packages-item
            width 182px
            height 234px
            margin-right 16px
            .days
              font-size 22px
            .day-price
              font-size 48px
              span
                font-size 22px
            .price
              font-size 20px
              .origin
                font-size 20px
            .days
              font-size 20px
        .packages
          padding-left 0
        .pay-info
          margin-top 79px
          .left
            .price
              font-size 22px
              span
                font-size 22px
            .num
              font-size 64px !important
            h3
              font-size 22px
            p
              font-size 18px
          .code
            margin 0 0 0 40px
  @media screen and (max-width 1200px)
    zoom 0.74
  @media screen and (max-width 1200px) and (min-height 1200px)
    &.mv
      zoom normal
</style>

