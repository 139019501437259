<template>
  <CommonModal ref="root">
    <div class="activity-modal-aiface">
      <div @click="handleCloseModal" class="activity-modal-aiface-close"></div>
      <!-- <template> -->
        <div class="activity-modal-aiface-content" v-if="isFirst">
          <template  v-for="(img, index) in imgs" :key="index">
            <div class="activity-modal-aiface-content-item" v-if="index === selected">
              <img class="activity-modal-aiface-content-item-img" :src="img" alt=""  />
              <div v-if="index !== 2" class="activity-modal-aiface-content-item-btn" @click="handleNext(index)">
                下一步 
                <span v-if="index === 0 && !hasNext1 && time > 0">{{ time }}{{ time ? 'S' : '' }}</span>
                <span v-if="index === 1 && !hasNext2 && time > 0">{{ time }}{{ time ? 'S' : '' }}</span>
              </div>
              <div v-else class="activity-modal-aiface-content-item-bottom">
                <div class="activity-modal-aiface-content-item-bottom-code">
                  <img :src="AIQrcode" alt="">
                  <!-- <span>微信扫码 立享优惠</span> -->
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="activity-modal-aiface-content1" v-else>
          <img class="activity-modal-aiface-content1-img" :src="images[0]" alt=""/>
          <div class="activity-modal-aiface-content1-bottom">
            <div class="activity-modal-aiface-content1-code">
              <img :src="AIQrcode" alt="">
              <!-- <span>微信扫码 立享优惠</span> -->
            </div>
          </div>
        </div>
      <!-- </template> -->
    </div>
  </CommonModal>
</template>
<script>
import { computed, ref, onMounted, onBeforeMount } from 'vue'
import store from '@/store'
import CommonModal from '@/components/modal/common/component.vue'
// import { format } from 'date-fns'
import store2 from 'store2'
import useQRCode from '@/composables/useQRCode'

import { sendLog } from '@/directives/v-log/log'
import { te } from 'date-fns/locale'

export default {
  name: 'ActivityMicModal',
  components: {
    CommonModal,
  },
  props: {
    closeEvent: {
      type: Function,
      default: () => {
      }
    },
  },
  setup(props) {
    const root = ref(null)
    const { getQRCodeURL } = useQRCode()
    let AIQrcode = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')

    const aiFaceQr = computed(() => store.state.aiFaceQr)
    const userType = computed(() => store.state.userInfo.userType)
    const getQrcode = async () => {
      console.log('aiFaceQr', aiFaceQr.value)
      const log = store2('aiface_first') ? 1890 : 1889
      AIQrcode.value = await getQRCodeURL(`${aiFaceQr.value}&log=${log}&ut=${userType.value}`)
      // AIQrcode.value = await getQRCodeURL(`https://m.ktvsky.com/vadd/facechange/mv/p/r?f=${fromType.value}&_src=600114&_mac=${_mac.value}`)
    }

    let isFirst = ref(true)
    // const appStartTime = computed(() => store.state.appStartTime)
    const imgs = [
      'https://qncweb.ktvsky.com/20240914/vadd/6007bad5effe081522afa7ab2a8f0a09.png',
      'https://qncweb.ktvsky.com/20240914/vadd/f2d3adec0f27b23cf83500fd3e33140f.png',
      'https://qncweb.ktvsky.com/20240914/vadd/5682a8214e49bd3b19a2c4c773f29b2b.png',
    ]

    const images = [
      'https://qncweb.ktvsky.com/20240914/vadd/b1499d751fee3b1716f4d2103ca263ff.png',
    ]

    let time = ref(3)
    let countDownTimer = null
    let hasNext1 = ref(false)
    let hasNext2 = ref(false)
    let selected = ref(0)

    const handleNext = (index) => {
      sendLog({
        event_type: '10000~50000',
        event_name: 20291 + index,
        event_data: {
          str1: '换脸MV',
          str2: `下一步-${index + 1}`,
          str3: '点击',
          str4: 'click',
        }
      })
      if (index === 0) {
        hasNext1.value = true
        selected.value = 1
        time.value = 3
        if (countDownTimer) {
          clearInterval(countDownTimer)
          countDownTimer = null
        }
        countDown()
      } else if (index === 1) {
        hasNext2.value = true
        selected.value = 2
        if (countDownTimer) {
          clearInterval(countDownTimer)
          countDownTimer = null
        }
      }
    }

    // 倒计时逻辑
    const countDown = () => {
      countDownTimer = setInterval(() => {
        time.value = time.value - 1
        if (time.value === 0) {
          clearInterval(countDownTimer)
          countDownTimer = null
          handleNext(selected.value)
        }
      }, 1000)
    }

    const handleCloseModal = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: isFirst.value ? 20293 : 20295,
        event_data: {
          str1: '换脸MV',
          str2: `${isFirst.value ? '首次点击-' : ''}关闭`,
          str3: '点击',
          str4: 'click',
        }
      })
      if (isFirst.value) {
        store2('aiface_first', true)
      }
      // const now = Date.now()
      props.closeEvent.call()
      // sendLog({
      //   event_type: 'show',
      //   event_name: 1723,
      //   event_data: {
      //     start_time: format(appStartTime.value, 'yyyy-MM-dd HH:mm:ss'),
      //     end_time: format(now, 'yyyy-MM-dd HH:mm:ss'),
      //     stay_time: Math.round((now - appStartTime.value) / 1000),
      //     key_words: '销售麦克风'
      //   }
      // })
      root.value.hide()
    }

    onBeforeMount(getQrcode)


    onMounted(() => {
      console.log('onMounted')
      if (store2('aiface_first')) {
        isFirst.value = false
      } else {
        countDown()
      }
    })

    return {
      root,
      imgs,
      images,
      isFirst,
      time,
      selected,
      AIQrcode,
      hasNext1,
      hasNext2,
      handleNext,
      handleCloseModal,
    }
  }
}
</script>
<style lang="stylus" scoped>
.activity-modal-aiface
  position relative
  width 1178px
  height 820px
  // background red
  // background url(https://qncweb.ktvsky.com/20230612/vadd/361730642dd6787e1c4438c6e19c4875.png) no-repeat
  // background-size 100% 100%
  // background-position center
  border-radius 20px
  color rgba(255, 255, 255, .8)
  display flex
  flex-direction column
  align-items center
  @media screen and (max-width 1200px)
    zoom 0.8
  &-close
    width 90px
    height 90px
    position absolute
    top 28px
    right 30px
    background url(https://qncweb.ktvsky.com/20240914/vadd/796f02f5e3877bde489445a65205d986.png) no-repeat
    background-size 100% 100%
    background-position center
    z-index 100
  &-content1
    width 1178px
    height 820px
    display flex
    flex-direction column
    align-items center
    position relative
    &-img
      width 100%
      height 100%
      position absolute
      top 0
      left 0
    &-bottom
      width 256px
      height 256px
      display flex
      align-items center
      justify-content center
      position absolute
      // bottom 83px
      // right 466px
      bottom 54px
      right 456px
      left auto !important
      &-code
        width 222px
        height 222px
        img
          width 100%
          height 100%
  &-content
    width 1178px
    height 820px
    display flex
    flex-direction column
    align-items center
    &-item
      width 1178px
      height 820px
      display flex
      flex-direction column
      align-items center
      position relative
      &-img
        width 1178px
        height 820px
        position absolute
        top 0
        left 0
      &-btn
        width 286px
        height 96px
        font-size 38px
        font-weight 700
        color rgba(255, 255, 255, 1)
        background rgba(170, 131, 73, 1)
        border-radius 16px
        display flex
        align-items center
        justify-content center
        position absolute
        bottom 75px
        right 279px
        span
          margin-left 10px
          font-size 38px
          font-weight 700
      &-bottom
        width 282px
        height 282px
        display flex
        align-items center
        justify-content center
        position absolute
        bottom 56px
        right 286px
        &-code
          width 252px
          height 252px
          img
            width 100%
            height 100%
</style>
