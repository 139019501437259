import LoginConfirmComponent from './index.vue'
import useLoginConfrim from './create';

const Plugin = (app, props = {}, slots = {}) => {
  const loginConfirm = useLoginConfrim(props, slots);
  app.config.globalProperties.$loginConfirm = loginConfirm;
  app.provide('$loginConfirm', loginConfirm)
};

LoginConfirmComponent.install = Plugin;

export default LoginConfirmComponent;
