<template>
  <div
    class="song-list"
    v-if="alreadyList.length"
  >
    <SongItem
      v-for="(songItem, index) in alreadyList"
      :key="index"
      :index="index"
      :songItem="songItem"
      :isLandscape="isLandscape"
      @order="handleOrder"
      @stickTop="handleStickTop"
      @delete="handleDelete"
      @singer-click="handleSingerClick"
    ></SongItem>
  </div>
  <div class="empty" v-else>
    <img src="https://qncweb.ktvsky.com/20231211/vadd/3bc6fd2689cda7c52578140c345c6272.png" alt="">
    <p>暂无歌曲，请点歌~</p>
  </div>
</template>
<script setup>
import { onMounted, ref, defineEmits, computed } from 'vue'
import SongItem from './item.vue'
import useAlready from '@/composables/useAlready'
import { sendLog } from '@/directives/v-log/log'
import { checkLandscapeOrPortrait } from '@/utils/device'
import { get } from 'lodash'
import { getAiMvUserIsexpire } from '@/service/user'
import Toast from '@/utils/toast'

const emit = defineEmits(['singer-click'])
let isLandscape = ref(false)
const { alreadyList, orderSong, stickSongToTop, deleteSong } = useAlready()
// const instance = getCurrentInstance()

const handleOrder = async (songItem, index, {
  isNotSingImmediately
}) => {
  if (songItem.isAIMV) {
    if (!songItem.unionid) {
      Toast({
        message: '您的换脸VIP已过期，暂无法在车机播放',
        position: 'center',
        className: 'toast-zoom-max'
      })
      return
    }
    const res = await getAiMvUserIsexpire({
      unionid: songItem.unionid
    })
    if (!get(res, 'data.hasVip', false)) {
      Toast({
        message: '您的换脸VIP已过期，暂无法在车机播放',
        position: 'center',
        className: 'toast-zoom-max'
      })
      return
    }
  }
  orderSong(songItem, ({ immediate }) => {
    if (immediate) {
      // instance.parent.exposed.close() 暂时这样弄
    }
  }, {
    isNotSingImmediately,
  })
  sendLog({
    event_type: '10000~50000',
    event_name: 10062,
    event_data: {
      str1: '已点',
      str2: '已唱',
      str3: '点歌',
      str4: 'click',
    },
  })
}

const handleStickTop = async (songItem, index) => {
  if (songItem.isAIMV) {
    if (!songItem.unionid) {
      Toast({
        message: '您的换脸VIP已过期，暂无法在车机播放',
        position: 'center',
        className: 'toast-zoom-max'
      })
      return
    }
    const res = await getAiMvUserIsexpire({
      unionid: songItem.unionid
    })
    if (!get(res, 'data.hasVip', false)) {
      Toast({
        message: '您的换脸VIP已过期，暂无法在车机播放',
        position: 'center',
        className: 'toast-zoom-max'
      })
      return
    }
  }
  stickSongToTop(songItem, index)
  sendLog({
    event_type: '10000~50000',
    event_name: 10063,
    event_data: {
      str1: '已点',
      str2: '已唱',
      str3: '置顶点歌',
      str4: 'click',
    },
  })
}

const handleDelete = (index) => {
  deleteSong(index)
  sendLog({
    event_type: '10000~50000',
    event_name: 10064,
    event_data: {
      str1: '已点',
      str2: '已唱',
      str3: '删除',
      str4: 'click',
    },
  })
}

const handleSingerClick = (v) => {
  emit('singer-click', v)
}

onMounted (() => {
  isLandscape.value = checkLandscapeOrPortrait() === 'landscape'
})
</script>
<style lang="stylus" scoped>
.song-list
  height 660px
  padding 0 48px
  overflow-x hidden
  overflow-y scroll
  &::-webkit-scrollbar
    width 8px
  &::-webkit-scrollbar-thumb
    width 8px
    border-radius 4px
    background #303030
  &::-webkit-scrollbar-track
    background #1e1f21
  &.has-banner
    // height 580px
    height 522px
.empty
    display flex
    flex-direction column
    justify-content center
    align-items center
    font-size 28px
    color rgba(255, 255, 255, 0.40)
    text-align center
    padding-top 184px
    @media screen and (max-width: 1200px)
      padding-top 238px
    img
      width 80px
      height 80px
      margin-bottom 40px
    p
      height 32px
      line-height 32px
</style>