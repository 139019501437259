//后续看情况确定是否需要加密存储信息
import store from "@/store";
import store2 from "store2";
import _ from "lodash";
import { parseISO, differenceInDays, format } from "date-fns";

const setAlreadyList = _.debounce((data) => {
  let time = new Date();
  let alreadyList = data || [...store.state.alreadyList];
  if (alreadyList.length > 100) {
    alreadyList = alreadyList.slice(0, 100);
  }
  store2("alreadyData", {
    alreadyList,
    time,
  });
}, 500);

const setOrderedList = _.debounce((data) => {
  let time = new Date();
  let orderedList = data || [...store.state.orderedList];
  if (orderedList.length > 100) {
    orderedList = orderedList.slice(0, 100);
  }
  store2("orderedData", {
    orderedList,
    time,
  });
}, 500);

function getAlreadyList() {
  if (!store2("alreadyData")) return [];
  const { alreadyList, time } = store2("alreadyData");
  const currDate = new Date();
  if (differenceInDays(currDate, parseISO(time)) > 30) {
    setAlreadyList([]);
    return [];
  }
  return alreadyList.filter((item) => item.music_name !== "");
}

function getOrderedList() {
  if (!store2("orderedData")) return [];
  const { orderedList, time } = store2("orderedData");
  const currDate = new Date();
  if (differenceInDays(currDate, parseISO(time)) > 30) {
    setOrderedList([]);
    return [];
  }
  return orderedList || [];
}

/**
 * 登录送会员活动tag（三次关闭后进入应用不主动打开送会员提示弹窗）
 * 3次关闭领取弹窗进入不显示周期（周期15天，系统内的多次操作算一次）
 */
const setLoginSendVipActivityTag = (v) => {
  let time = new Date();
  store2("lsvaTag", {
    tag: v > 2 ? 3 : v,
    time,
  });
};

const getLoginSendVipActivityTag = () => {
  if (!store2("lsvaTag")) return 0;
  const { tag, time } = store2("lsvaTag");
  const currDate = new Date();
  if (differenceInDays(currDate, parseISO(time)) > 13) {
    setLoginSendVipActivityTag(0);
    return 0;
  }
  return tag || 0;
};

/**
 * 开屏弹窗销售麦克风
 * 一天只弹一次，每天第一次打开弹出
 */
const setMicActivityTag = (t) => {
  store2("micActivityTag", {
    time: t,
  });
};

const getMicActivityShowTag = () => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  if (!store2("micActivityTag")) {
    setMicActivityTag(currDate);
    return true;
  }
  const { time } = store2("micActivityTag");
  if (currDate !== time) {
    setMicActivityTag(currDate);
    return true;
  }
  return false;
};

/**
 * 开屏弹窗销售VIP
 * 一天只弹一次，每天第二次打开弹出
 */
const setVipActivityTag = (t, times = 1) => {
  store2("vipModalActivityTag", {
    time: t,
    times: times,
  });
};

const getVipActivityShowTag = (userStatus) => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  const vipModalActivityTag = store2("vipModalActivityTag");

  if (!vipModalActivityTag) {
    setVipActivityTag(currDate, userStatus === "vip" ? 2 : 1);
    return 1; // 第一次进入app，提示1次
  }

  const { time, times } = vipModalActivityTag;
  if (currDate !== time) {
    setVipActivityTag(currDate);
    return 1; // 第一次进入app，提示1次
  }

  if (
    (userStatus === "notLoggedIn" || userStatus === "loggedInButNotVip") &&
    times === 1
  ) {
    setVipActivityTag(currDate, 2);
    return 2; // 第二次进入app，提示1次
  }

  return 0; // 不需要提示
};

// A/B Test 本地存储规则：从2023/7/7往后推算每天的版本 - ABBAABBAA...
const setABTestTag = (str) => {
  store2("abTestTag", str);
};

const getABTestTag = () => {
  const abTest = store2("abTestTag");
  // 已标记过的用户不再标记
  if (abTest) {
    return abTest;
  }
  const currDate = format(Date.now(), "yyyy-MM-dd");
  const ruleStartTime = "2023-07-08";
  if (currDate === ruleStartTime) {
    setABTestTag("A");
    return "A";
  }
  // 推算当天版本
  const verObj = {
    1: "B",
    2: "B",
    3: "A",
    0: "A",
  };
  const length = Math.abs(
    differenceInDays(parseISO(currDate), parseISO(ruleStartTime))
  );
  const currver = verObj[length % 4];
  setABTestTag(currver);
  console.log("how many day?", length, currver);

  return currver;
};

const setSearchCache = (data) => {
  let searchCache = data || [...store.state.search.searchCache];
  if (searchCache.length > 10) {
    searchCache = searchCache.slice(0, 10);
  }
  store2("searchCache", searchCache || []);
};
const getSearchCache = () => {
  if (!store2("searchCache")) return [];
  return store2("searchCache");
};

// 搜索词条 - 一键演唱歌曲
const setSearchSong = (data) => {
  store2("searchSong", data);
};

const getSearchSong = () => {
  if (!store2("searchSong")) return [];
  return store2("searchSong");
};

// 过期会员提醒 - 一天只弹一次
const setVipExpireTag = () => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  store2("vipExpireModalTag", {
    time: currDate,
  });
};

const getVipExpireTag = () => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  if (!store2("vipExpireModalTag")) {
    return true;
  }
  const { time } = store2("vipExpireModalTag");
  if (currDate !== time) {
    return true;
  }
  return false;
};

// 低价激活用户运营支付弹窗 - 一天只弹一次
const setOperationPopupPay = () => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  store2("OperationPopupPay", {
    time: currDate,
  });
};

const getOperationPopupPay = () => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  if (!store2("OperationPopupPay")) {
    return true;
  }
  const { time = "" } = store2("OperationPopupPay");
  if (currDate !== time) {
    return true;
  }
  return false;
};

// 记录当天是否有播放第二首的记录
const setOperationSecondSong = (currDate) => {
  store2("OperationSecondSong", {
    time: currDate,
  });
};

const getOperationSecondSong = () => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  if (!store2("OperationSecondSong")) {
    setOperationSecondSong(currDate);
    return true;
  }
  const { time = "" } = store2("OperationSecondSong");
  if (currDate !== time) {
    setOperationSecondSong(currDate);
    return true;
  }
  return false;
};

// 记录当天画质提示显示了几次
const setOperationQualityShow = (currDate, tag) => {
  store2("OperationQualityShow", {
    time: currDate,
    tag,
  });
};

const getOperationQualityShow = () => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  if (!store2("OperationQualityShow")) {
    setOperationQualityShow(currDate, 1);
    return true;
  }
  const { time = "", tag } = store2("OperationQualityShow");
  if (currDate !== time) {
    setOperationQualityShow(currDate, 1);
    return true;
  }
  if (tag < 2) {
    setOperationQualityShow(currDate, 2);
    return true;
  }
  return false;
};

const setClimaxTip = (currDate) => {
  store2("ClimaxTip", {
    time: currDate,
  });
};

const getClimaxTip = () => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  if (!store2("ClimaxTip")) {
    setClimaxTip(currDate);
    return true;
  }
  const { time = "" } = store2("ClimaxTip");
  if (currDate !== time) {
    setClimaxTip(currDate);
    return true;
  }
  return false;
};

const setClimaxTime = ({ startTime, elapsedTime, isPopupShown }) => {
  store2("ClimaxTime", {
    startTime,
    elapsedTime,
    isPopupShown,
    time: format(Date.now(), "yyyy-MM-dd"),
  });
};

const getClimaxTime = () => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  const {
    time = "",
    startTime = 0,
    elapsedTime = 0,
    isPopupShown = false,
  } = store2("ClimaxTime") || {};
  if (currDate !== time) {
    const config = {
      startTime: 0,
      elapsedTime: 0,
      isPopupShown: false,
    };
    setClimaxTime(config);
    return config;
  }
  if (!store2("ClimaxTime") || !isPopupShown) {
    return {
      startTime,
      elapsedTime,
      isPopupShown,
    };
  }
  return false;
};

const setAIShow = (tag) => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  store2("AIShow", {
    time: currDate,
    tag: tag,
  });
};

const getAIShow = () => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  if (!store2("AIShow")) {
    return true;
  }
  const { time = "", tag } = store2("AIShow");
  if (currDate !== time) {
    return true;
  }
  if (tag < 2) {
    return tag;
  }
  return false;
};

const setFreeSongNoTimeShow = (data) => {
  store2("FREE-SONG-NOTIME-SHOW", data);
};

const getFreeSongNoTimeShow = () => {
  return store2("FREE-SONG-NOTIME-SHOW");
};

const setRecommendSongTag = () => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  store2.session("recommendSongTag", {
    time: currDate,
  });
};

const getRecommendSongTag = () => {
  const currDate = format(Date.now(), "yyyy-MM-dd");
  if (!store2.session("recommendSongTag")) return false;
  const { time } = store2.session("recommendSongTag");
  if (currDate !== time) return false;
  return true;
};

// const setReduceRecommendModalTag = () => {
//   const currDate = format(Date.now(), "yyyy-MM-dd");
//   store2("reduceRecommendModalTag", {
//     time: currDate,
//   });
// };

// const getReduceRecommendModalTag = () => {
//   const currDate = format(Date.now(), "yyyy-MM-dd");
//   if (!store2("reduceRecommendModalTag")) return false;
//   const { time } = store2("reduceRecommendModalTag");
//   if (currDate !== time) return false;
//   return true;
// };

/**
 * home setting
 * @param {*} data
 * number 1 - 保持
 * number 2 - 减少
 * number 3 - 从不
 */
const setReduceRecommendSetting = (data) => {
  store2("REDUCE-RECOMMEND-SETTING", data);
};

const getReduceRecommendSetting = () => {
  return store2("REDUCE-RECOMMEND-SETTING") || 1;
};

/**
 * mv setting
 * @param {*} data
 * number 1 - 保持
 * number 2 - 减少
 * number 3 - 从不
 */
const setReduceMvRecommendSetting = (data) => {
  store2("REDUCE-RECOMMEND-MV-SETTING", data);
};

const getReduceMvRecommendSetting = () => {
  return store2("REDUCE-RECOMMEND-MV-SETTING") || 1;
};

/***
 * 首页推荐三次关闭时无操作标识
 */
const setCloseRecommendTag = (data) => {
  store2("CLOSERECOMMENDTAG", data);
};

const getCloseRecommendTag = () => {
  return store2("CLOSERECOMMENDTAG") || 0;
};

/***
 * mv智能推荐三次关闭时无操作标识
 */
const setCloseMvRecommendTag = (data) => {
  store2("CLOSEREMVCOMMENDTAG", data);
};

const getCloseMvRecommendTag = () => {
  return store2("CLOSEREMVCOMMENDTAG") || 0;
};

export {
  setAlreadyList,
  setOrderedList,
  getAlreadyList,
  getOrderedList,
  setLoginSendVipActivityTag,
  getLoginSendVipActivityTag,
  setMicActivityTag,
  getMicActivityShowTag,
  setVipActivityTag,
  getVipActivityShowTag,
  getABTestTag,
  setSearchCache,
  getSearchCache,
  setSearchSong,
  getSearchSong,
  setVipExpireTag,
  getVipExpireTag,
  setOperationPopupPay,
  getOperationPopupPay,
  getOperationSecondSong,
  setOperationQualityShow,
  getOperationQualityShow,
  getClimaxTip,
  setClimaxTime,
  getClimaxTime,
  setAIShow,
  getAIShow,
  setFreeSongNoTimeShow,
  getFreeSongNoTimeShow,
  setRecommendSongTag,
  getRecommendSongTag,
  // setReduceRecommendModalTag,
  // getReduceRecommendModalTag,
  setReduceRecommendSetting,
  getReduceRecommendSetting,
  setReduceMvRecommendSetting,
  getReduceMvRecommendSetting,
  setCloseRecommendTag,
  getCloseRecommendTag,
  setCloseMvRecommendTag,
  getCloseMvRecommendTag,
};
