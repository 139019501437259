<template>
  <ControlPopup ref="root">
    <!-- 调整为顶栏模块显示了 -->
    <!-- <template #control-popup-button>
      <OrderSongControlButton v-if="$route.meta.showOrderedPopupBtn && mvIsHide"></OrderSongControlButton>
    </template> -->
    <template #control-popup-plugin>
      <div class="order-song-control-panel">
        <div class="header">
          <div class="tab">
            <div
              class="tab-item"
              v-for="(tab, index) in tabList"
              :key="index"
              :class="{ actived: curTab.name === tab.name }"
              @click="handleChangeTab(tab)"
            >
              {{ tab.text }}
            </div>
          </div>
          <div class="close-box">
            <img
                src="https://qncweb.ktvsky.com/20231208/vadd/c66c6ee46a87137d8cde1ec7fa8c9718.png"
                @click="handleClose"
                alt=""
            />
          </div>
        </div>
        <div
          v-if="shouldShowBanner"
          class="banner"
          @click="handleClickBanner"
          v-log="{
            event_type: 'show',
            event_name: 30213,
            event_data: {
              str1: '已点/已唱',
              str2: 'ai换脸横条',
              str3: '展示',
              str4: 'show',
              str5: isLogin ? '已登录' : '未登录',
            }
          }"
        ><span class="close" @click.stop="showBanner = false"></span></div>
        <OrderSongList
          v-show="curTab.name === 'ordered'"
          @singer-click="handleClickSinger"
          :class="showBanner && 'has-banner'"
        ></OrderSongList>
        <AlreadySongList
          v-show="curTab.name === 'already'"
          @singer-click="handleClickSinger"
          :class="showBanner && 'has-banner'"
        ></AlreadySongList>
      </div>
    </template>
  </ControlPopup>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import eventBus from '@/utils/event-bus'
import ControlPopup from '@/components/control-popup/index.vue'
import useVip from '@/composables/useVip'
import AlreadySongList from '@/components/song-list/already/index.vue'
import OrderSongList from '@/components/song-list/order/index.vue'
import { sendLog } from '@/directives/v-log/log'
import useAIModal from '@/components/modal/global/ai-modal/create'

const store = useStore()
const router = useRouter()
const $AIModal = useAIModal();
const { showVipQrcode } = useVip()

// const mvIsHide = computed(() => store.state.mvIsHide)

const orderedListNum = computed(() => store.state.orderedList.length)
const alreadyListNum = computed(() => store.state.alreadyList.length)
const isLogin = computed(() => !!store.state.userInfo.unionid)
const tabList = computed(() => {
  return [
    {
      name: 'ordered',
      text: `已点(${orderedListNum.value > 99 ? '99+' : orderedListNum.value})`,
    },
    {
      name: 'already',
      text: `已唱(${alreadyListNum.value > 99 ? '99+' : alreadyListNum.value})`,
    },
  ]
})

const shouldShowBanner = computed(() => {
  // return showBanner.value &&
  //   ((orderedListNum.value && curTab.value.name === 'ordered') ||
  //   (alreadyListNum.value && curTab.value.name === 'already'));
  return showBanner.value;
});

let curTab = ref(tabList.value[0])
let showBanner = ref(true)

const handleChangeTab = (tab) => {
  curTab.value = tab
  showBanner.value = true
  if (tab.text === '已唱') {
    sendLog({
      event_type: '10000~50000',
      event_name: 10061,
      event_data: {
        str1: '已点',
        str2: '已唱',
        str3: '进入已唱',
        str4: 'click',
      },
    })
  }
  if (tab.name === 'already') {
    sendLog({
      event_type: '10000~50000',
      event_name: 30017,
      event_data: {
        str1: '通用',
        str2: '已点/已唱弹窗',
        str3: '进入已唱',
        str4: 'click',
      },
    })
  }
  commonLog()
}

const root = ref(null)

const commonLog = () => {
  if (!shouldShowBanner.value) return
  sendLog({
    event_type: 'show',
    event_name: curTab.value.name === 'already' ? 30189 : 30187,
    event_data: {
      str1: '通用',
      str2: '已点/已唱弹窗',
      str3: `${curTab.value.name === 'already' ? '已唱' : '已点'}运营位展示`,
      str4: 'show',
    },
  })
}

const handleShow = () => {
  root.value.show()
  commonLog()
}

const handleClose = () => {
  root.value.close()
  showBanner.value = true
}

const handleClickSinger = ({ singer, singerhead, singerid }) => {
  router.push({
    name: 'songList',
    query: {
      name: singer,
      image: singerhead,
      singerid: singerid,
    },
  })
  root.value.close()
}

const handleClickBanner = () => {
  sendLog({
    event_type: 'click',
    event_name: '30214',
    event_data: {
      str1: '已点/已唱',
      str2: 'ai换脸横条',
      str3: '点击',
      str4: 'click',
      str5: isLogin.value ? '已登录' : '未登录',
    },
  })
  $AIModal.show({
    fromType: 'tesla_playlist'
  })
  // showVipQrcode({
  //   log: log,
  //   isLogin: isLogin.value,
  //   fr: Number(vipLogFrom.get(log))
  // });
}

onMounted(() => {
  eventBus.on('show-order-song-control-popup', handleShow)
  eventBus.on('close-order-song-control-popup', handleClose)
})

onBeforeUnmount(() => {
  eventBus.off('show-order-song-control-popup', handleShow)
  eventBus.off('close-order-song-control-popup', handleClose)
})
</script>
<style lang="stylus" scoped>
.order-song-control-panel
  padding 30px
  width 1000px
  height 800px
  background #1E1F21
  // box-shadow 0px 20px 80px 0px rgba(0, 0, 0, 0.5)
  border-radius 14px
  padding 0 !important
  @media screen and (max-width 1200px)
    zoom 0.8
  .header
    width 100%
    height 130px
    //border-bottom 2px solid rgba(255, 255, 255, 0.10)
    position relative
    // margin-bottom 16px
    display flex
    align-items center
    justify-content center
    .close-box
      width 70px
      height 70px
      border-radius 20px
      background rgba(255, 255, 255, 0.1)
      position absolute
      top 30px
      right 30px
      display flex
      align-items center
      justify-content center
    img
      width 40px
      height 40px
    .tab
      background rgba(0, 0, 0, 0.2)
      width 418px
      height 86px
      display flex
      align-items center
      justify-content space-between
      border-radius 28px
      &-item
        display flex
        justify-content center
        align-items center
        width 196px
        height 70px
        font-size 26px
        color rgba(255, 255, 255, 0.60)
        &.actived
          color #684106
          background #F1C88B
          border-radius 20px
  .banner
    background url('https://qncweb.ktvsky.com/20240429/other/0bd753d8b8894a24d248b000e170bafd.png') no-repeat
    width 904px
    height 114px
    background-size 904px 114px
    margin 0px auto
    position relative
    span
      position absolute
      width 50px
      height 50px
      right 10px
      top 50px
</style>
