<template>
  <div
    class="song-list"
    v-if="aiFaceSong.length"
  >
    <SongItem
      v-for="(songItem, index) in aiFaceSong"
      :key="index"
      :index="index"
      :songItem="songItem"
      :isLandscape="isLandscape"
      @order="handleOrder"
      @stickTop="handleStickTop"
      @delete="handleDelete"
    ></SongItem>
  </div>
  <div class="empty" v-else>
    <img src="https://qncweb.ktvsky.com/20231211/vadd/3bc6fd2689cda7c52578140c345c6272.png" alt="">
    <p>暂无歌曲，快去制作AI歌曲吧～</p>
  </div>
</template>
<script setup>
import { getCurrentInstance, onMounted, ref, defineEmits, computed } from 'vue'
import SongItem from './item.vue'
import store from '@/store'
import useAIOrder from '@/composables/useAIOrder'
import { sendLog } from '@/directives/v-log/log'
import get from 'lodash/get'
import { checkLandscapeOrPortrait } from '@/utils/device'
import eventBus from '@/utils/event-bus'

const emit = defineEmits(['singer-click'])
let isLandscape = ref(false)

const { orderAISong } = useAIOrder()
const aiFaceSong = computed(() => store.state.aiFaceSong)
const instance = getCurrentInstance()

const handleOrder = (songItem, index) => {
  console.log('handleOrder', songItem, index)
  orderAISong(songItem, index)
  // eventBus.emit('handle-video-operlayer')
  if (get(instance, 'parent.exposed.close', '') && typeof instance.parent.exposed.close === 'function') instance.parent.exposed.close()
}

const handleStickTop = (index) => {
  // stickSongToTop(index)
  // sendLog({
  //   event_type: '10000~50000',
  //   event_name: 10059,
  //   event_data: {
  //     str1: '已点',
  //     str2: '已点',
  //     str3: '置顶歌曲',
  //     str4: 'click',
  //   },
  // })
}

const handleDelete = (index) => {
  // deleteSong(index)
  // sendLog({
  //   event_type: '10000~50000',
  //   event_name: 10060,
  //   event_data: {
  //     str1: '已点',
  //     str2: '已点',
  //     str3: '删除歌曲',
  //     str4: 'click',
  //   },
  // })
}

onMounted (() => {
  isLandscape.value = checkLandscapeOrPortrait() === 'landscape'
})

</script>
<style lang="stylus" scoped>
.song-list
  height 660px
  padding 0 48px
  overflow-x hidden
  overflow-y scroll
  &::-webkit-scrollbar
    width 8px
  &::-webkit-scrollbar-thumb
    width 8px
    border-radius 4px
    background #303030
  &::-webkit-scrollbar-track
    background #1e1f21
  &.has-banner
    height 580px
.empty
  display flex
  flex-direction column
  justify-content center
  align-items center
  font-size 28px
  color rgba(255, 255, 255, 0.40)
  text-align center
  padding-top 184px
  @media screen and (max-width: 1200px)
    padding-top 238px
  img
    width 80px
    height 80px
    margin-bottom 40px
  p
    height 32px
    line-height 32px
</style>