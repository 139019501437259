import store from '@/store'
import store2 from 'store2'
import { stringify } from 'query-string';
import { getClientWidth, getClientHeight, checkLandscapeOrPortrait } from '@/utils/device'
import { format } from 'date-fns'
import formatStr from '@/constants/index'
import config from '@/config/index'
import getBaseUrl from '@/utils/request/base-url'

function report(reqUrl) {
  try {
    let img = new Image(1, 1);
    img.src = reqUrl;
    img.onload = () => {
      img = null;
    };
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
}

export function sendHLSReport({ sid, scale, error_msg, error_type}) {
  if (!sid) return;
  const params = {
    uid: store.state.userInfo.unionid || '-',
    sid,
    scale,
    error_msg,
    error_type,
    event_name: 'hls-report',
    event_type: 'custom',
  }
  const host = process.env.NODE_ENV === 'production'
    ? '//hlsreport.ktvsky.com'
    : '';
  const sendUrl = `${host}?${stringify(params)}`;
  report(sendUrl);
}
 
export function sendLog({event_type, event_name, event_data}) {
  if (!event_type || !event_name) return;
  const now = Date.now()
  const commonParams = {
    _src: 600114,
    _m: store.state.systemInfo.model,
    _h: checkLandscapeOrPortrait() === 'landscape' ? 1 : 2,
    _r1: `${getClientWidth()},${getClientHeight()}`,
    _mac: store.state.macAddress,
    _user_id: store.state.userInfo.unionid,
    _tm: format(now, formatStr),
    _ts: now,
    _boot_id: store2('_boot_id'),
    _session: store2('_boot_id'),
    _vn: `v${config.vn}_${store.state.abTest.abTestVersion || store2('abTestTag')}`
  }
  const params = {
    ...commonParams,
    event_name,
    ...event_data
  }
  const host = getBaseUrl({
    pro: '//log3.ktvsky.com',
    pre: '//log3pre.ktvsky.com',
    test: ''
  })
  const sendUrl = `${host}/log/event?${stringify(params)}`;
  report(sendUrl);
}

export function sendSongLog(event_data) {
  const commonParams = {
    _src: 600114,
    _m: store.state.systemInfo.model,
    _h: checkLandscapeOrPortrait() === 'landscape' ? 1 : 2,
    _vn: `v${config.vn}_${store.state.abTest.abTestVersion || store2('abTestTag')}`,
    mac: store.state.macAddress,
    // end_type: '', // 1:正常播放结束，2：切歌
    // song_id: '',
    // song_name: '',
    // singer: '',
    // start_time: '', // 格式："2020-07-27 14:02:20"
    // end_time: '',
    // play_time: '', // 歌曲播放真实的总时长（去除暂停），单位秒(看打分结果页怎么拿时长)
    unionid: store.state.userInfo.unionid,
  }
  const params = {
    ...commonParams,
    ...event_data
  }
  const host = getBaseUrl({
    pro: '//log3.ktvsky.com',
    pre: '//log3pre.ktvsky.com',
    test: ''
  })
  const sendUrl = `${host}/song/playex?${stringify(params)}`;
  report(sendUrl);
}